import { api } from './api';

export const fcmTokenAPI = api.injectEndpoints({
  endpoints: (build) => ({
    registerFCM: build.mutation({
      query: ({ token, device, fingerprint }) => ({
        url: '/fcmTokens/register',
        method: 'POST',
        body: {
          token,
          device,
          fingerprint
        }
      })
    })
  })
});

export const { useRegisterFCMMutation } = fcmTokenAPI;
