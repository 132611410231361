import { Mention, MentionsInput } from 'react-mentions';
import { useRef } from 'react';
import { Box, ListItemButton, ListItemText } from '@mui/material';

/**
 * @typedef {import('@mui/material').SxProps<Theme>} CustomStyleProps
 * @param {Record<'id'|'display', string|number>[]} data
 * @param {string} placeholder
 * @param {boolean} rounded
 * @param {boolean} forceSuggestionsAboveCursor
 * @param {JSX.Element} endAdornment
 * @param {React.Ref} inputRef
 * @param {React.ChangeEventHandler} onChange
 * @param {React.KeyboardEventHandler} onKeyDown
 * @param {string} value
 * @param {boolean} singleLine
 * @param {number} rows
 * @param {boolean} disabled
 * @param {CustomStyleProps} sx
 * @param {CustomStyleProps} inputStyles
 * @returns {JSX.Element}
 * @constructor
 */
const MentionInput = ({
  data,
  placeholder,
  rounded,
  forceSuggestionsAboveCursor,
  endAdornment,
  inputRef,
  onChange,
  value,
  singleLine = false,
  rows,
  onKeyDown,
  disabled,
  sx,
  inputStyles = {}
}) => {
  const endAdornmentRef = useRef(null);

  const renderUserSuggestion = (
    entry,
    search,
    highlightedDisplay,
    index,
    focused
  ) => {
    return (
      <ListItemButton dense selected={focused}>
        <ListItemText>{entry.display}</ListItemText>
      </ListItemButton>
    );
  };

  const endAdornmentWidth = endAdornmentRef?.current?.clientWidth;

  return (
    <Box
      width="100%"
      position="relative"
      display="flex"
      alignItems="center"
      borderRadius={rounded ? 16 : 4}
      flexBasis={rows ? 28 * rows : undefined}
      sx={sx}
    >
      <MentionsInput
        disabled={disabled}
        value={value}
        onChange={onChange}
        className={`mention-input ${rounded ? 'mention-input--rounded' : ''}`}
        forceSuggestionsAboveCursor={forceSuggestionsAboveCursor}
        placeholder={placeholder}
        style={{
          input: {
            paddingRight: endAdornmentWidth,
            ...inputStyles
          },
          highlighter: { marginRight: endAdornmentWidth }
        }}
        inputRef={inputRef}
        onKeyDown={onKeyDown}
        singleLine={singleLine}
        allowSuggestionsAboveCursor
      >
        <Mention
          trigger="@"
          data={data}
          renderSuggestion={renderUserSuggestion}
          appendSpaceOnAdd
        />
      </MentionsInput>
      {endAdornment && (
        <Box position="absolute" right={14} ref={endAdornmentRef}>
          {endAdornment}
        </Box>
      )}
    </Box>
  );
};

export default MentionInput;
