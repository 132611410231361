import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { useGetSlideGroupsQuery } from 'services/slides';
import { debounce } from 'lodash';
const filter = createFilterOptions();

const AutocompleteSlideGroup = ({
  isCreateable = false,
  isMulti = false,
  onChange,
  value,
  label = 'SlideGroup',
  placeholder = 'SlideGroup',
  sx = {}
}) => {
  const [slideGroups, setSlideGroups] = useState([]);

  const [slideGroupQuery, setSlideGroupQuery] = useState({
    limit: 100,
    page: 1,
    search: ''
  });

  const { data: fetchedSlideGroups } = useGetSlideGroupsQuery(slideGroupQuery, {
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (fetchedSlideGroups) {
      setSlideGroups(
        fetchedSlideGroups.map((slideGroup) => ({
          Name: slideGroup,
          _id: slideGroup
        }))
      );
    }
  }, [fetchedSlideGroups]);

  const debouncedSearch = debounce((search) => {
    setSlideGroupQuery({
      ...slideGroupQuery,
      search
    });
  }, 200);

  return (
    <Autocomplete
      multiple={isMulti}
      disableCloseOnSelect={isMulti}
      id="slideGroups-outlined"
      sx={sx}
      onBlur={() => {
        setSlideGroupQuery({
          ...slideGroupQuery,
          search: ''
        });
      }}
      options={
        slideGroups
          ? slideGroups.map((slideGroup) => ({
              Name: slideGroup.Name,
              _id: slideGroup._id
            }))
          : []
      }
      freeSolo={isCreateable}
      onChange={(e, value) => {
        const convertSlideGroups = value.map((slideGroup) => {
          if (typeof slideGroup === 'string') {
            return {
              Name: slideGroup,
              _id: ''
            };
          }
          return slideGroup;
        });
        // remove duplicate slideGroups
        const uniqueSlideGroups = convertSlideGroups.filter(
          (slideGroup, index, self) =>
            index === self.findIndex((t) => t.Name === slideGroup.Name)
        );
        // remove empty slideGroups
        const filteredSlideGroups = uniqueSlideGroups.filter(
          (slideGroup) => slideGroup.Name.trim() !== ''
        );
        onChange && onChange(filteredSlideGroups);
      }}
      value={value || []}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const existingList = value ? [...value, ...options] : options;
        const isExisting = existingList.some((option) => {
          if (params.inputValue) {
            return option.Name === params.inputValue;
          }
          return false;
        });
        if (params.inputValue !== '' && isCreateable && !isExisting) {
          filtered.push({
            _id: '',
            Name: params.inputValue,
            label: `Add "${params.inputValue}"`
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.Name;
      }}
      isOptionEqualToValue={(option, value) => option.Name === value.Name}
      renderOption={(props, option) => (
        <li {...props}>{option.label || option.Name}</li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          variant="outlined"
          placeholder={placeholder}
          label={label}
          value={slideGroupQuery.search}
          onChange={(e) => {
            e.persist();
            debouncedSearch(e.target.value);
          }}
          size="small"
        />
      )}
    />
  );
};

export { AutocompleteSlideGroup };
