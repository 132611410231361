import React from 'react';
import { Container, useTheme } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from '@mui/system';
import BioMarkerTab from './BioMarkerTab';

const AnalysisGraphPopUpWithCompare = ({
  biomarkers,
  compBiomarkers,
  isFetching,
  compareAnnoStroke,
  slideId,
  group1Id,
  group2Id
} ) => {
  const theme = useTheme();

  let sameCaps = [];
  if (
    typeof compBiomarkers != 'undefined' &&
    typeof biomarkers != 'undefined'
  ) {
    if (compBiomarkers.cap === biomarkers.cap) sameCaps = compBiomarkers;
  }
  return (
    <Container maxWidth="100%">
      {typeof compBiomarkers != 'undefined' &&
      typeof biomarkers != 'undefined' &&
      typeof sameCaps != 'undefined' ? (
        <TabContext value={'0'}>
          <TabPanel value="0" key="0">
            <BioMarkerTab
              biomarker={biomarkers}
              compBiomarker={compBiomarkers}
              compAnnoColor={compareAnnoStroke}
              slideId={slideId}
              group1Id={group1Id}
              group2Id={group2Id}
              openedFor="comp"
            />
          </TabPanel>
        </TabContext>
      ) : (
        <Box
          sx={{ textAlign: 'center', color: theme.palette.text.textGray1 }}
          mt={1}
        >
          {isFetching ? 'Loading analysis data..' : 'No analysis data found'}
        </Box>
      )}
    </Container>
  );
};

export default AnalysisGraphPopUpWithCompare;
