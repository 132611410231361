import CommentList from './CommentList';
import { Box, Stack } from '@mui/material';
import CommentBox from './CommentBox';
import { CursorPaginationContextProvider } from 'contexts/CursorPaginationContext';

const AnnotationComment = ({ annotationId }) => {
  return (
    <CursorPaginationContextProvider>
      <Stack
        sx={{
          overflowY: 'auto',
          height: '100%'
        }}
        spacing={1}
        p={1}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            width: '100%',
            position: 'relative',
            height: '100%',
            overflow: 'hidden'
          }}
        >
          <CommentBox annotationId={annotationId} />
          <CommentList annotationId={annotationId} />
        </Box>
      </Stack>
    </CursorPaginationContextProvider>
  );
};

export default AnnotationComment;
