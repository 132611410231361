import { CircularProgress, Paper } from '@mui/material';
import React from 'react';

const LoadingOverlay = () => {
  return (
    <Paper
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: 'primary.main',
        backgroundColor: '#464646a6',
        position: 'fixed',
        zIndex: '2000',
        height: '100%',
        width: '100%'
      }}
    >
      <CircularProgress color="inherit" />
    </Paper>
  );
};
export default LoadingOverlay;
