/**
 * @description the recogito widget displaying Annotation title on Shape Popover,
 * for reference: https://recogito.github.io/guides/editor-widgets/
 * @param annotation
 * @returns {HTMLDivElement}
 * @constructor
 */
import BarChartIcon from '@mui/icons-material/BarChart';
import ReactDOM from 'react-dom';

export const AnalysisGraphBtnWidget = ({ annotation, onClick }) => {
  const divElement = document.createElement('div');
  const deleteButton = document.querySelector(
    'button.r6o-btn.delete-annotation'
  );
  const analysisButton = document.querySelector(
    'button.r6o-btn.analysis-style'
  );
  const analysisGraphBtn = document.querySelector(
    'button.r6o-btn.analysis-graph'
  );

  // covers add and edit annotation flows
  // as we have to display analysis button only if delete button exists
  // that is the edit annotation mode
  // only add if analysis graph button is not already added
  if (deleteButton && analysisButton && !analysisGraphBtn) {
    const analysisGraphBtn = document.createElement('button');
    analysisGraphBtn.classList.add('r6o-btn');
    analysisGraphBtn.classList.add('analysis-graph');
    analysisGraphBtn.classList.add('analysis-style');
    analysisGraphBtn.setAttribute('title', 'Analysis Graph');
    analysisGraphBtn.onclick = onClick;
    analysisButton.insertAdjacentElement('afterend', analysisGraphBtn);
    ReactDOM.render(<BarChartIcon fontSize="small" />, analysisGraphBtn);
  }

  // return empty element always
  // as we are appending analysis button next to the delete button
  return divElement;
};
