import * as Yup from 'yup';

export const UserSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  // phone: Yup.string().required('Phone number is required'),
  // address: Yup.string().required('Address is required'),
  // country: Yup.string().required('Country is required'),
  // state: Yup.string().required('State is required'),
  // city: Yup.string().required('City is required'),
  role: Yup.string().required('Role is required'),
  // zipCode: Yup.string().required('Zip code is required'),
  // avatarUrl: Yup.mixed<any>().nullable(),
  // not required
  // status: Yup.string(),
  isActive: Yup.boolean()
});
