/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import {
  useGetFileByIdMutation,
  useLazyGetAllSlideAnnotationsQuery
} from '../services/slides';
import { useAuth } from './useAuth';

const useAnnotationSocket = (slideId, dispatch, anno) => {
  const {
    user: { _id: uid }
  } = useAuth();
  const [trigger] = useLazyGetAllSlideAnnotationsQuery();
  const [getFileById] = useGetFileByIdMutation();

  const updateGroup = async (id) => {
    const { data } = await getFileById(id).unwrap();
    dispatch({
      type: 'UPDATE_GROUP',
      payload: data.groups
    });
  };

  // useEffect(() => {
  //   socket.on(
  //     'annotation-upserted',
  //     ({ _refreshGroup, _uid, _isSingleUpdated = false, ...annotation }) => {
  //       if (uid === _uid || !slideId) return;
  //       if (annotation.slide?._id === slideId || annotation.slide === slideId) {
  //         if (_refreshGroup) updateGroup(slideId);
  //         setTimeout(() => {
  //           // when _isSingleUpdated is true, only update the single annotation, not affect other annotations of the same group
  //           if (_isSingleUpdated) {
  //             dispatch({
  //               type: 'UPDATE_ANNOTATION',
  //               payload: annotation
  //             });
  //           } else {
  //             // when _isSingleUpdated is false, there may be impact on all annotations of the same group
  //             dispatch({
  //               type: 'ADD_ANNOTATIONS',
  //               payload: [annotation]
  //             });
  //           }
  //           void trigger(slideId);
  //         }, 100);
  //       }
  //     }
  //   );

  //   socket.on('annotation-deleted', (annotation) => {
  //     if (uid === annotation._uid) return;
  //     if (annotation.slide?._id === slideId) {
  //       dispatch({
  //         type: 'DELETE_ANNOTATION',
  //         payload: annotation._id
  //       });
  //       anno?.removeAnnotation(annotation._id);
  //     }
  //   });
  // }, [anno, slideId, dispatch]);

  const emitAnnotationUpserted = useCallback(
    (annotation, refreshMe = false) => {
      // socket.emit('annotation-upserted', {
      //   ...annotation,
      //   id: annotation._id,
      //   _uid: refreshMe ? undefined : uid
      // });
    },
    [uid]
  );

  const emitAnnotationDeleted = useCallback(
    (annotation) => {
      // socket.emit('annotation-deleted', { ...annotation, _uid: uid });
    },
    [uid]
  );

  return { emitAnnotationUpserted, emitAnnotationDeleted };
};

export default useAnnotationSocket;
