import React, { useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { store } from './stores';
import App from './App';
import theme from './theme';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'simplebar-react/dist/simplebar.min.css';
import { Button } from '@mui/material';
import { WebSocketContextProvider } from 'contexts/WebSocketContext';
import { LocalizationProvider } from 'v2/locales';

const container = document.getElementById('root');
const root = createRoot(container as Element);
const RootElement = () => {
  const snackbarRef = useRef<any>(null);
  return (
    <Provider store={store}>
      <LocalizationProvider>
        <WebSocketContextProvider>
          <SnackbarProvider
            ref={snackbarRef}
            maxSnack={2}
            action={(snackbarId) => (
              <Button
                onClick={() => snackbarRef.current.closeSnackbar(snackbarId)}
                sx={{ color: '#ffff' }}
              >
                Dismiss
              </Button>
            )}
          >
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ThemeProvider>
          </SnackbarProvider>
        </WebSocketContextProvider>
      </LocalizationProvider>
    </Provider>
  );
};
root.render(<RootElement />);
