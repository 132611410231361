import { useCallback, useEffect, useState } from 'react';
import UserTableToolbar from './UserTableToolbar';
import {
  EUserRole,
  EUserStatus,
  IUser,
  IUserTableFilterValue,
  IUserTableFilters
} from 'types/user';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  emptyRows,
  getComparator,
  useTable
} from 'v2/components/table';
import { useBoolean } from 'v2/hooks/use-boolean';
import { isEqual } from 'lodash';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Scrollbar from 'v2/components/scrollbar';
import UserTableFiltersResult from './UserTableFiltersResult';
import UserTableRow from './UserTableRow';
import { useDeleteUserMutation } from 'services/user';
import { enqueueSnackbar } from 'notistack';
import { ConfirmDialog } from 'v2/components/custom-dialog';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { fTimestamp } from 'v2/utils/format-time';
import { IOrganization } from 'types/organization';

const defaultFilters: IUserTableFilters = {
  name: '',
  role: [],
  status: undefined,
  organizations: []
};

interface Props {
  users: IUser[];
  onInviteSuccess?: VoidFunction;
  onRefresh?: VoidFunction;
  organizations?: IOrganization[];
  isShowOrganization?: boolean;
}

const TABLE_HEAD = [
  { id: 'name', label: 'Name' },
  { id: 'organization', label: 'Organization', align: 'center' },
  { id: 'role', label: 'Role', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: '', width: 88 }
];

const UserManagement = ({
  users,
  onInviteSuccess,
  onRefresh,
  organizations,
  isShowOrganization = true
}: Props) => {
  const {
    user: { role }
  } = useAuth();

  // end fetch data

  // actions
  const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();
  // end actions
  const navigate = useNavigate();
  const table = useTable();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(users);

  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilterUser({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value
      }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    async (id: number) => {
      const response = await deleteUser(id).unwrap();
      if (response.success) {
        enqueueSnackbar('Delete the user successfully', {
          variant: 'success'
        });
        const deleteRow = tableData.filter((row) => row?._id !== id);
        setTableData(deleteRow);

        table.onUpdatePageDeleteRow(dataInPage.length);
        onRefresh && onRefresh();
      }
    },
    [deleteUser, tableData, table, dataInPage.length, onRefresh]
  );

  const handleDeleteRows = useCallback(async () => {
    const promises = table.selected.map((id) =>
      deleteUser(Number(id)).unwrap()
    );
    const responses = await Promise.all(promises);
    const deleteIds: number[] = [];
    responses.forEach((response) => {
      if (response.success) {
        deleteIds.push(response.data._id);
        enqueueSnackbar(
          `Delete the user ${response.data.firstName} ${response.data.lastName} successfully`,
          {
            variant: 'success'
          }
        );
      }
    });
    const deleteRows = tableData.filter((row) => !deleteIds.includes(row._id));
    setTableData(deleteRows);
    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length
    });
    onRefresh && onRefresh();
  }, [
    table,
    tableData,
    dataInPage.length,
    dataFiltered.length,
    onRefresh,
    deleteUser
  ]);

  const handleViewRow = useCallback(
    (id: number) => {
      navigate(`/dashboard/users/${id}`);
    },
    [navigate]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  useEffect(() => {
    setTableData(users);
  }, [users]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <UserTableToolbar
        shows={
          isShowOrganization
            ? ['name', 'role', 'status', 'organizations']
            : ['name', 'role', 'status']
        }
        filters={filters}
        onFilters={handleFilters}
        organizations={organizations || []}
        onInviteSuccess={onInviteSuccess}
      />

      {canReset && (
        <UserTableFiltersResult
          filters={filters}
          onFilters={handleFilters}
          //
          onResetFilters={handleResetFilters}
          //
          results={dataFiltered.length}
          organizations={organizations || []}
          sx={{ p: 2.5, pt: 0 }}
        />
      )}

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <TableSelectedAction
          dense={table.dense}
          numSelected={table.selected.length}
          rowCount={tableData.length}
          onSelectAllRows={(checked) =>
            table.onSelectAllRows(
              checked,
              tableData.map((row) => row._id.toString())
            )
          }
          action={
            <Tooltip title="Delete">
              <IconButton
                disabled={isDeleting}
                color="primary"
                onClick={confirm.onTrue}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          }
        />

        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={
                isShowOrganization
                  ? TABLE_HEAD
                  : TABLE_HEAD.filter((head) => head.id !== 'organization')
              }
              rowCount={tableData.length}
              numSelected={table.selected.length}
              onSort={table.onSort}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row._id.toString())
                )
              }
            />

            <TableBody>
              {dataFiltered
                .slice(
                  table.page * table.rowsPerPage,
                  table.page * table.rowsPerPage + table.rowsPerPage
                )
                .map((row) => (
                  <UserTableRow
                    key={row._id}
                    row={row}
                    selected={table.selected.includes(row._id.toString())}
                    onSelectRow={() => table.onSelectRow(row._id.toString())}
                    onDeleteRow={() => handleDeleteRow(row._id)}
                    isDeleting={isDeleting}
                    onViewRow={() => handleViewRow(row._id)}
                    isSuperAdmin={role === EUserRole.SUPER_ADMIN}
                    onUserQuickEditSuccess={onRefresh || (() => {})}
                    isShowOrganization={isShowOrganization}
                  />
                ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(
                  table.page,
                  table.rowsPerPage,
                  tableData.length
                )}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={dataFiltered.length}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        //
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete{' '}
            <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </Box>
  );
};

export function applyFilterUser({
  inputData,
  comparator,
  filters,
  dateError = true
}: {
  inputData: IUser[];
  comparator: (a: any, b: any) => number;
  filters: IUserTableFilters;
  dateError?: boolean;
}) {
  const { name, status, role, organizations, start_date, end_date } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (user) =>
        user.firstName.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
        user.lastName.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
        user.email.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
        user.organization.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status) {
    inputData = inputData.filter((user) =>
      status === EUserStatus.ACTIVE ? user.isActive : !user.isActive
    );
  }

  if (role?.length) {
    inputData = inputData.filter((user) => role?.includes(user.role));
  }

  if (organizations?.length) {
    inputData = inputData.filter((user) =>
      organizations?.includes(user.organization._id)
    );
  }

  if (!dateError) {
    if (start_date && end_date) {
      inputData = inputData.filter(
        (invoice) =>
          fTimestamp(invoice.createdAt) >= fTimestamp(start_date) &&
          fTimestamp(invoice.createdAt) <= fTimestamp(end_date)
      );
    }
  }

  return inputData;
}

export default UserManagement;
