import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const ErrorDialog = ({ errorMessage, onClose = () => {}, onOpen = () => {} }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (errorMessage) setOpen(true);
  }, [errorMessage])

  const close = () => {
    setOpen(false);
    onClose();
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth keepMounted>
      <DialogContent sx={{ textAlign: 'center' }}>
        <HighlightOffIcon color="error" sx={{ fontSize: '60px' }} />
        <Typography variant="h5">{errorMessage}</Typography>
        <Button
          variant="contained"
          color="gray"
          disableElevation
          onClick={close}
          sx={{ mt: 2 }}
        >
          Retry
        </Button>
      </DialogContent>
    </Dialog>
  );
};
export default ErrorDialog;
