import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import ChatEmojiDialog from './ChatEmojiDialog';
import useToggleState from 'hooks/useToggleState';
import { useSnackbar } from 'hooks/useSnackbar';
import MentionInput from 'components/MentionInput';
import { grey } from '@mui/material/colors';
import { insertEmojiToMentionText } from 'utils/mentionHelper';
import useTaggableUsers from 'hooks/useTaggableUsers';
import { useWS } from 'contexts/WebSocketContext';

function GroupChatBox({ groupId, groupMembers = [] }) {
  const { enqueueSnackbar } = useSnackbar();
  const taggableUsers = useTaggableUsers({ users: groupMembers });
  const [emojiAnchor, openEmojiDialog, closeEmojiDialog] = useToggleState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState('');
  const inputRef = useRef();
  const attachmentLabelRef = useRef();
  const { createMessage } = useWS();

  useEffect(() => {
    setText('');
  }, [groupId]);

  function handleOpenEmojiDialog(event) {
    openEmojiDialog?.(event.target);
  }

  function handleChange(e) {
    setText(e.target.value);
  }

  async function handleSubmit() {
    if (!text.trim().length || isLoading) return;
    try {
      setText('');
      // TODO WS add loading state
      createMessage({ groupId, content: text.trim() });
    } catch (e) {
      enqueueSnackbar(e.message || 'Something went wrong!', {
        variant: 'error'
      });
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      void handleSubmit();
    }
  }

  function handleClickAttachIcon() {
    attachmentLabelRef.current?.click();
  }

  const handleSelectEmoji = useCallback(
    (emoji) => {
      const caretPosition = inputRef.current?.selectionStart || 0;
      setText((prevState) => {
        return insertEmojiToMentionText(prevState, emoji.native, caretPosition);
      });
      setTimeout(() => {
        inputRef.current.selectionStart = caretPosition + 2;
      }, 50);
    },
    [inputRef]
  );

  return (
    <>
      <Box height={56} />
      <Box position="absolute" bottom={0} left={0} right={0}>
        <Stack
          height={56}
          ml={2}
          mr={3}
          direction="row"
          bottom={0}
          alignItems="center"
          spacing={1}
        >
          <Box width="calc(100% - 40px)">
            <MentionInput
              placeholder="Write a message..."
              rounded
              singleLine
              forceSuggestionsAboveCursor
              endAdornment={
                <InputAdornment position="end">
                  <InputLabel
                    htmlFor={`group-${groupId}-message-attachment`}
                    ref={attachmentLabelRef}
                  />
                  <IconButton onClick={handleClickAttachIcon}>
                    <AttachFileIcon />
                  </IconButton>
                  <IconButton edge="end" onClick={handleOpenEmojiDialog}>
                    <InsertEmoticonIcon />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ backgroundColor: grey[100] }}
              inputRef={inputRef}
              value={text}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              data={taggableUsers}
            />
          </Box>
          <IconButton
            color="primary"
            onClick={handleSubmit}
            disabled={!text.trim()}
          >
            <SendIcon />
          </IconButton>
        </Stack>
      </Box>
      <Box hidden>
        <Input
          type="file"
          name="attachment"
          id={`group-${groupId}-message-attachment`}
          inputProps={{ accept: 'image/png, image/jpeg' }}
        />
      </Box>
      <ChatEmojiDialog
        anchorEl={emojiAnchor}
        onClose={closeEmojiDialog}
        onSelect={handleSelectEmoji}
      />
    </>
  );
}

export default GroupChatBox;
