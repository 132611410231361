import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  },
  palette: {
    purple: {
      main: '#572373'
    },
    deepPurple: {
      main: '#572373'
    },
    fuschia: {
      main: '#99872'
    },
    brightPinkAlternatives: {
      main: '#9D0080'
    },
    deepPurple2: {
      main: '#530080'
    },
    hotPink: {
      main: '#AC0561'
    },
    gray: {
      main: '#EFEFEF'
    },
    purple2: {
      main: '#750074'
    },
    textPurple: {
      main: '#6D0077'
    },
    blue: {
      main: '#002680'
    },
    primary: {
      main: '#572373',
      lighter: '#de3cda'
    },
    secondary: {
      main: '#0E0B9E'
    },
    error: {
      main: red.A400
    },
    buttonGray: {
      main: '#BDBDBD'
    },
    buttonLightGray: {
      main: '#F2F2F2'
    },
    text: {
      textGray1: '#797979',
      textGray2: '#B5B5B5',
      textGray3: '#C8C8C8',
      textGray4: '#565656',
      textGray5: '#6F6F6F'
    }
  }
});

export const MAIN_DRAWER_WIDTH = 476;

export default theme;
// 701F6E - Purple
// 572373 - Deep Purple
// 99872 - Fuschia
// 9D0080 - Bright PinkAlternatives
// 530080 - Deep Purple
// AC0561 - Hot Pink
// EFEFEF - Gray
// 750074 - Purple
// 6D0077 - Text Purple
// 002680 -  Blue
