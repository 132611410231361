import React from 'react';
import { Container, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import BeeSwarmGraph from './BeeSwarmGraph';

const BioMarkerTab = ( {
  biomarker,
  compBiomarker,
  openedFor,
  compAnnoColor,
  slideId,
  group1Id,
  group2Id,
} ) => {
  const theme = useTheme();
  const [value, setValue] = useState('0');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [ heatMapHtmlContent, setHeatmapHtmlContent ] = useState( '' );
  const [ scatterPlotHtmlContent, setScatterPlotHtmlContent ] = useState( '' );
  const [ pcaHtmlContent, setPcaHtmlContent ] = useState( '' );
  const [ loading, setLoading ] = useState( true );
  const [ error, setError ] = useState( null );
  const heatmapKey = 'HeatMap';
  const pcaPlotKey = "PCA Plot";
  const scatterPlotKey = "Scatter Plot";

  let dataSetValue = [];
  let compDataSetValue = [];
  let allBiomarkerKey = [];
  let keys = [];
  let typeOnekeys = [
    heatmapKey,
    scatterPlotKey,
    pcaPlotKey,
    'Coverage [%]',
    '# Peptides',
    '# PSMs',
    '# Unique Peptides',
    '# AAs',
    'MW [kDa]',
    // 'calc. pI',
    'Score Sequest HT: A2 Sequest HT',
  ];
  let typeTwokeys = ['Value'];
  let fileType = 'typeOne';
  if (biomarker.biomarkerData?.length > 0) {
    keys = Object.keys(biomarker.biomarkerData[0]);
    if ( keys.includes( 'Accession' ) ) {
      keys = typeOnekeys.filter( ( key ) => key !== heatmapKey && key !== scatterPlotKey );
      if(slideId && group1Id && group2Id) {
        keys.unshift( heatmapKey );
        keys.unshift( scatterPlotKey );
      }
      fileType = 'typeOne';
    } else {
      keys = typeTwokeys;
      fileType = 'typeTwo';
    }

    allBiomarkerKey[biomarker.cap] = keys;

    keys.forEach((key) => {
      dataSetValue[key] = [];
      compDataSetValue[key] = [];
    });
    biomarker.biomarkerData.forEach((bData) => {
      keys.forEach((key) => {
        let tempObject = {};
        if (fileType === 'typeOne') {
          tempObject = {
            Accession: bData.Accession,
            Description: bData.Description
          };
        } else if (fileType === 'typeTwo') {
          tempObject = {
            Description: bData.Antibody
          };
        }
        tempObject[key] = bData[key];
        dataSetValue[key].push(tempObject);
      });
    });
    if (openedFor === 'comp' && compBiomarker && compBiomarker.biomarkerData) {
      compBiomarker.biomarkerData.forEach((bData) => {
        keys.forEach((key) => {
          let tempObject = {};
          if (fileType === 'typeOne') {
            tempObject = {
              Accession: bData.Accession,
              Description: bData.Description
            };
          } else if (fileType === 'typeTwo') {
            tempObject = {
              Description: bData.Antibody
            };
          }
          tempObject[key] = bData[key];
          compDataSetValue[key].push(tempObject);
        });
      });
    }
    // const dataSetKey = Object.keys(dataSetValue);
  }
  /**
 * useEffect hook to fetch heatmap and scatter plot HTML content based on slideId, group1Id, and group2Id.
 * 
 * This hook triggers fetch operations to retrieve the heatmap and scatter plot HTML content
 * whenever the slideId, group1Id, or group2Id dependencies change. The fetched HTML content
 * is then set in the state using setHeatmapHtmlContent and setScatterPlotHtmlContent respectively.
 * If an error occurs during the fetch operations, the error message is set in the state using setError.
 * The loading state is managed using setLoading.
 * 
 * @param {string} slideId - The ID of the slide.
 * @param {string} group1Id - The ID of the first group.
 * @param {string} group2Id - The ID of the second group.
 */
  useEffect( () => {
    const fetchHeatmapHtml = async () => {
      try {
        const response = await fetch(
          `${ process.env.REACT_APP_BACKEND_API }api/v1/comparisons/heatmap?slideId=${ slideId }&group1Id=${ group1Id }&group2Id=${ group2Id }`
        );
        if ( !response.ok ) {
          throw new Error( 'Failed to fetch HTML content' );
        }
        const htmlText = await response.text();
        setHeatmapHtmlContent( htmlText );
        setLoading( false );
      } catch ( err ) {
        setError( err.message );
        setLoading( false );
      }
    };

    const fetchScatterPlotHtml = async () => {
      try {
        const response = await fetch(
          `${ process.env.REACT_APP_BACKEND_API }api/v1/comparisons/scatterPlot?slideId=${ slideId }&group1Id=${ group1Id }&group2Id=${ group2Id }&columnName=Coverage%2520%5B%25%5D`
        );
        if ( !response.ok ) {
          throw new Error( 'Failed to fetch HTML content' );
        }
        const htmlText = await response.text();
        setScatterPlotHtmlContent( htmlText );
        setLoading( false );
      } catch ( err ) {
        setError( err.message );
        setLoading( false );
      }
    };

    if ( slideId && group1Id && group2Id ) {
      fetchScatterPlotHtml();
      fetchHeatmapHtml();
    }
  }, [ slideId, group1Id, group2Id ] );
  
  /**
   * useEffect hook to fetch PCA plot HTML content based on slideId and group1Id.
   * 
   * This hook triggers a fetch operation to retrieve the PCA plot HTML content
   * whenever the slideId or group1Id dependencies change. The fetched HTML content
   * is then set in the state using setPcaHtmlContent. If an error occurs during the
   * fetch operation, the error message is set in the state using setError.
   * 
   * @param {string} slideId - The ID of the slide.
   * @param {string} group1Id - The ID of the first group.
   */
  useEffect( () => {
    if ( slideId && group1Id ) {
      const fetchHtml = async () => {
        try {
          const response = await fetch( `${ process.env.REACT_APP_BACKEND_API }api/v1/visualizations/pcaPlot?slideId=${ slideId }&groupId=${ group1Id }` );
          if ( !response.ok ) {
            throw new Error( 'Failed to fetch HTML content' );
          }
          const htmlText = await response.text();
          setPcaHtmlContent( htmlText );
          setLoading( false );

        } catch ( err ) {
          setError( err.message );
          setLoading( false );
        }
      };

      fetchHtml();
    }
  }, [ slideId, group1Id ] );

  return (
    <Container maxWidth="100%" style={{ overflowX: 'scroll !important' }}>
      {biomarker ? (
        <TabContext value={value}>
          <TabList onChange={handleChange}>
            {keys.map((key, index) => (
              <Tab label={key} value={index.toString()} key={index} />
            ))}
          </TabList>
          {keys.map((key, index) => (
            <TabPanel value={index.toString()} key={index}>
              {key !== heatmapKey &&
                key !== pcaPlotKey &&
                key !== scatterPlotKey && (
                <BeeSwarmGraph
                  biomarkerData={dataSetValue[ key ]}
                  biomarkerName={key}
                  compBiomarkerData={compDataSetValue[ key ]}
                  compAnnoColor={compAnnoColor}
                />
              )}

              {key === pcaPlotKey && ( slideId && group1Id ) && (
                <TabPanel value={index.toString()} key={index}>
                  {loading ? (
                    <p>Loading HTML content...</p>
                  ) : error ? (
                    <p>Error loading content: {error}</p>
                  ) : (
                    <iframe
                      srcDoc={pcaHtmlContent}
                      style={{ width: '110%', height: '700px', border: 'none' }}
                      title="PCA Plot Graph"
                    />
                  )}
                </TabPanel>
              )}
              {key === heatmapKey && ( slideId && group1Id && group2Id ) && (
                <TabPanel value={index.toString()} key={index}>
                  {loading ? (
                    <p>Loading HTML content...</p>
                  ) : error ? (
                    <p>Error loading content: {error}</p>
                  ) : (
                    <iframe
                      srcDoc={heatMapHtmlContent}
                      style={{ width: '110%', height: '700px', border: 'none' }}
                      title="HeatMap Graph"
                    />
                  )}
                </TabPanel>
              )}
              {key === scatterPlotKey && ( slideId && group1Id && group2Id ) && (
                <TabPanel value={index.toString()} key={index}>
                  {loading ? (
                    <p>Loading HTML content...</p>
                  ) : error ? (
                    <p>Error loading content: {error}</p>
                  ) : (
                    <iframe
                      srcDoc={scatterPlotHtmlContent}
                      style={{ width: '110%', height: '700px', border: 'none' }}
                      title="HeatMap Graph"
                    />
                  )}
                </TabPanel>
              )}
            </TabPanel>
          ))}
        </TabContext>
      ) : (
        <Box
          sx={{ textAlign: 'center', color: theme.palette.text.textGray1 }}
          mt={1}
        >
          {biomarker ? 'Loading analysis data..' : 'No analysis data found'}
        </Box>
      )}
    </Container>
  );
};

export default BioMarkerTab;
