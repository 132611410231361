import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'v2/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from 'v2/components/hook-form';

import { EUserRole, EUserStatus, IUser } from 'types/user';
import { UserSchema } from 'v2/shared/schema/user';
import { USER_ROLES_OPTIONS, USER_STATUS_OPTIONS } from 'v2/utils/user';
import { useAuth } from 'hooks/useAuth';
import { useCreateUserMutation, useUpdateUserMutation } from 'services/user';
import { handleBackendError } from 'v2/utils/handle-backend-error';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  currentUser?: IUser;
  onCallback?: VoidFunction;
};

export default function UserQuickEditForm({
  currentUser,
  open,
  onClose,
  onCallback
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();

  const [updateUser, { isLoading: isUpdatingUser }] = useUpdateUserMutation();
  const [createUser, { isLoading: isCreateUser }] = useCreateUserMutation();

  const isAdmin = useMemo(() => {
    return (
      user?.role === EUserRole.ADMIN || user?.role === EUserRole.SUPER_ADMIN
    );
  }, [user]);

  const defaultValues = useMemo(
    () => ({
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      // city: currentUser?.city || '',
      role: currentUser?.role || EUserRole.USER,
      email: currentUser?.email || '',
      // state: currentUser?.state || '',
      status: currentUser?.isActive ? EUserStatus.ACTIVE : EUserStatus.INACTIVE
      // address: currentUser?.address || '',
      // country: currentUser?.country || '',
      // zipCode: currentUser?.zipCode || '',
      // avatarUrl: currentUser?.avatarUrl || null,
      // phone: currentUser?.phone || '',
      // isActive: currentUser?.isActive || false
      // isVerified: currentUser?.isVerified || false
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(UserSchema),
    defaultValues
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { status, ...rest } = data;
    try {
      if (currentUser?._id) {
        await updateUser({
          id: currentUser._id,
          ...rest,
          is_active: status === EUserStatus.ACTIVE
        });
      } else {
        await createUser({
          ...rest,
          is_active: status === EUserStatus.ACTIVE
        });
      }
      reset();
      onClose();
      onCallback && onCallback();
      enqueueSnackbar('User updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(
        handleBackendError(error).message || 'Something went wrong',
        {
          variant: 'error'
        }
      );
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 }
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Quick Update</DialogTitle>

        <DialogContent>
          <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
            Account is waiting for confirmation
          </Alert>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)'
            }}
          >
            {isAdmin && (
              <>
                <RHFSelect name="status" label="Status">
                  {USER_STATUS_OPTIONS.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFSelect name="role" label="Role">
                  {USER_ROLES_OPTIONS.map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </>
            )}
            <RHFTextField name="firstName" label="First Name" />
            <RHFTextField name="lastName" label="Last Name" />
            <RHFTextField name="email" label="Email Address" />
            {/* <RHFTextField name="phone" label="Phone Number" /> */}

            {/* <RHFAutocomplete
              name="country"
              label="Country"
              options={countries.map((country) => country.label)}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => {
                const { code, label, phone } = countries.filter(
                  (country) => country.label === option
                )[0];

                if (!label) {
                  return null;
                }

                return (
                  <li {...props} key={label}>
                    <Iconify
                      key={label}
                      icon={`circle-flags:${code.toLowerCase()}`}
                      width={28}
                      sx={{ mr: 1 }}
                    />
                    {label} ({code}) +{phone}
                  </li>
                );
              }}
            /> */}

            {/* <RHFTextField name="state" label="State/Region" /> */}
            {/* <RHFTextField name="city" label="City" /> */}
            {/* <RHFTextField name="address" label="Address" /> */}
            {/* <RHFTextField name="zipCode" label="Zip/Code" /> */}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting || isUpdatingUser || isCreateUser}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
