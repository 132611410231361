import _ from 'lodash';
import { EOrganizationStatus } from 'types/organization';

export const getOrganizationStatusColor = (isActive: boolean) => {
  return isActive ? 'success' : 'error';
};

export const ORGANIZATION_STATUS_OPTIONS = Object.values(
  EOrganizationStatus
).map((status) => ({
  value: status,
  label: _.capitalize(status)
}));
