import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box, CircularProgress } from '@mui/material';
import { AnalysisContext } from 'hooks/useAnalysisContext';
import { AnalysisFilterForm } from './AnalysisFilterForm';
import { AnalysisResult } from './AnalysisResult';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';
import { useGetAnalysisMutation } from 'services/analysis';

const Analysis = () => {
  const [state, setState] = useState({
    filter: '',
    projects: [],
    slides: [],
    slideGroups: [],
    annotationTitles: [],
    annotationShapes: [],
    annotationColors: [],
    tags: [],
    patientIds: [],
    blockIds: [],
    treatmentGroups: [],
    recuts: [],
    biomarkers: [] // { type: '', operator: '', value: '' }
  });

  const [analysis, setAnalysis] = useState({
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  });

  const [
    getAnalysis,
    { isLoading: isAnalysisFetching, data: fetchedAnalysis }
  ] = useGetAnalysisMutation();

  const saveState = (newState) => {
    setState({
      ...state,
      ...newState
    });
  };

  useEffect(() => {
    if (fetchedAnalysis) {
      setAnalysis({
        ...analysis,
        data: fetchedAnalysis
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedAnalysis]);

  const handleSearch = () => {
    const data = {
      filter: state.filter,
      projects: state.projects ? state.projects.map((p) => p.name) : [],
      slides: state.slides ? state.slides.map((s) => s.name) : [],
      slideGroups: state.slideGroups
        ? state.slideGroups.map((s) => s.Name)
        : [],
      annotationTitles: state.annotationTitles
        ? state.annotationTitles.map((a) => a.title)
        : [],
      annotationShapes: state.annotationShapes,
      annotationColors: state.annotationColors
        ? state.annotationColors.map((a) => a.color)
        : [],
      tags: state.tags ? state.tags.map((t) => t.name) : [],
      patientIds: state.patientIds ? state.patientIds.map((p) => p.value) : [],
      blockIds: state.blockIds ? state.blockIds.map((b) => b.value) : [],
      treatmentGroups: state.treatmentGroups
        ? state.treatmentGroups.map((t) => t.value)
        : [],
      recuts: state.recuts ? state.recuts.map((r) => r.value) : [],
      biomarkers: state.biomarkers
    };
    getAnalysis(data);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%'
      }}
    >
      <AnalysisContext.Provider
        value={{
          state,
          setState,
          analysis,
          isLoading: isAnalysisFetching,
          setAnalysis,
          saveState
        }}
      >
        <Grid display="flex" flexDirection="row" p={2}>
          <Typography variant="h4" gutterBottom component="div">
            Analysis
          </Typography>
        </Grid>
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          gap={4}
          alignContent={'flex-start'}
          justifyContent={'flex-start'}
          sx={{ p: 2 }}
        >
          <AnalysisFilterForm />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <LoadingButton
              sx={{ px: 4 }}
              loading={isAnalysisFetching}
              onClick={handleSearch}
              variant="contained"
              loadingIndicator={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress sx={{ marginRight: 1 }} size={16} />
                  Searching...
                </Box>
              }
              startIcon={<SearchIcon />}
            >
              Search
            </LoadingButton>
          </Box>
        </Box>
        <AnalysisResult />
      </AnalysisContext.Provider>
    </Box>
  );
};

export default Analysis;
