import { Box, Popover } from '@mui/material';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

function ChatEmojiDialog({ anchorEl, onClose, onSelect }) {
  const open = Boolean(anchorEl);

  return (
    <Popover
      id="chat-emoji-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Box width={336} height={435} borderRadius={4} overflow={'hidden'}>
        <Picker data={data} onEmojiSelect={onSelect} theme="light" />
      </Box>
    </Popover>
  );
}

export default ChatEmojiDialog;
