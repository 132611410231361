import React, { useEffect, useReducer } from 'react';
import { Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavigationPane from './NavigationPane/NavigationPane';
import { selectCurrentFolderView } from '../../stores/slidebox/slideboxSlice';
import { useSlideBox } from '../../hooks/slideBox/useSlideBox';
import {
  SlideboxContext,
  defaultSlideBoxContextState,
  slideBoxContextReducer
} from '../../hooks/slideBox/useSlideBoxContext';
import { CreateSlidebox } from './CreateSlidebox';
import { SlideboxDialog } from './SlideboxDialog';

const SlideboxOutlet = () => {
  const [state, dispatch] = useReducer(
    slideBoxContextReducer,
    defaultSlideBoxContextState
  );

  const { _id: currentViewId, parent } = useSelector(selectCurrentFolderView);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { handleSetCurrentView } = useSlideBox();

  useEffect(() => {
    handleSetCurrentView(currentViewId, parent);
  }, []);

  return (
    <SlideboxContext.Provider value={[state, dispatch]}>
      <Grid
        container
        sx={{
          flexGrow: '1',
          overflow: 'hidden',
          marginTop: '0px',
          maxHeight: 'calc(100vh - 60px)',
          marginLeft: '0px'
        }}
      >
        {!isMobile && (
          <Grid
            item
            md={3}
            xl={2}
            sx={{
              backgroundColor: '#ffff',
              paddingTop: '30px!important',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}
            pr={2}
            pb={0}
          >
            <Box>
              <CreateSlidebox />
            </Box>
            {/* File Explorer Navigation */}
            <NavigationPane />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          md={9}
          xl={10}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 16px',
            height: '100%',
            overflowY: 'auto'
          }}
        >
          <Outlet />
        </Grid>
      </Grid>
      <SlideboxDialog />
    </SlideboxContext.Provider>
  );
};

export default SlideboxOutlet;
