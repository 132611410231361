import _ from 'lodash';
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from '@mui/material';
import React, { useCallback } from 'react';
import {
  IOrganizationTableFilterValue,
  IOrganizationTableFilters
} from 'types/organization';
import { useBoolean } from 'v2/hooks/use-boolean';
import { ORGANIZATION_STATUS_OPTIONS } from 'v2/utils/organization';
import OrganizationCreateForm from './OrganizationCreateForm';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

type Props = {
  filters: IOrganizationTableFilters;
  onFilters: (name: string, value: IOrganizationTableFilterValue) => void;
  dateError?: boolean;
  //
  onCreateSuccess?: VoidFunction;
};

const OrganizationTableToolbar = ({
  filters,
  onFilters,
  onCreateSuccess
}: Props) => {
  const openCreate = useBoolean();

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterStatus = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'status',
        typeof event.target.value === 'string'
          ? event.target.value.split(',')[0]
          : event.target.value[0]
      );
    },
    [onFilters]
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row'
        }}
      >
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 }
          }}
        >
          <InputLabel>Status</InputLabel>
          <Select
            value={filters.status ? [filters.status] : []}
            onChange={handleFilterStatus}
            input={<OutlinedInput label="Status" />}
            renderValue={(selected) =>
              selected.map((value) => _.capitalize(value)).join(', ')
            }
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 }
              }
            }}
          >
            {ORGANIZATION_STATUS_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ width: 1 }}
        >
          <TextField
            fullWidth
            value={filters.name}
            onChange={handleFilterName}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              )
            }}
          />
          <Button
            variant="contained"
            sx={{
              minWidth: 220,
              height: 56
            }}
            color="primary"
            onClick={openCreate.onTrue}
          >
            <AddIcon /> Create Organization
          </Button>
        </Stack>
      </Stack>
      <OrganizationCreateForm
        open={openCreate.value}
        onClose={() => openCreate.onFalse()}
        onCallback={() => {
          onCreateSuccess?.();
        }}
      />
    </>
  );
};

export default OrganizationTableToolbar;
