import React from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutocompleteAnnotationShape = ({
  isCreateable = false,
  isMulti = false,
  onChange,
  value,
  label = 'Annotation Shape',
  placeholder = 'Annotation Shape',
  sx = {}
}) => {
  return (
    <Autocomplete
      multiple={isMulti}
      freeSolo={isCreateable}
      id="annotation-shape-checkboxes"
      options={['rectangle', 'ellipse', 'freehand']}
      disableCloseOnSelect={isMulti}
      size="small"
      sx={sx}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      getOptionLabel={(option) =>
        option.charAt(0).toUpperCase() + option.slice(1)
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.charAt(0).toUpperCase() + option.slice(1)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          {...params}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export { AutocompleteAnnotationShape };
