import { Selection } from '@recogito/annotorious/src/tools/Tool';
import { toSVGTarget } from '@recogito/annotorious/src/selectors/EmbeddedSVG';
import { SVG_NAMESPACE } from '@recogito/annotorious/src/util/SVG';
import { drawRectangle, setRectangleSize } from './Rectangle';
import RectangleMask from './RectangleMask';

/**
 * A 'rubberband' selection tool for creating a rectangle by
 * clicking and dragging.
 */
export default class RubberbandRectangle {
  constructor(anchorX, anchorY, g, env, color) {
    this.anchor = [anchorX, anchorY];

    this.env = env;

    this.group = document.createElementNS(SVG_NAMESPACE, 'g');

    this.rectangle = drawRectangle(anchorX, anchorY, 2, 2, color);
    this.rectangle.setAttribute('class', 'a9s-selection');

    this.mask = new RectangleMask(env.image, this.rectangle);

    // We make the selection transparent to
    // pointer events because it would interfere with the
    // rendered annotations' mouseleave/enter events
    this.group.style.pointerEvents = 'none';

    // Additionally, selection remains hidden until
    // the user actually moves the mouse
    this.group.style.display = 'none';

    this.group.appendChild(this.mask.element);
    this.group.appendChild(this.rectangle);

    g.appendChild(this.group);
  }

  get element() {
    return this.rectangle;
  }

  dragTo = (oppositeX, oppositeY) => {
    // Make visible
    this.group.style.display = null;

    const w = oppositeX - this.anchor[0];
    const h = oppositeY - this.anchor[1];

    const x = w > 0 ? this.anchor[0] : oppositeX;
    const y = h > 0 ? this.anchor[1] : oppositeY;
    const width = Math.abs(w);
    const height = Math.abs(h);

    setRectangleSize(this.rectangle, x, y, width, height);
    this.mask.redraw();
  };

  getBoundingClientRect = () => this.rectangle.getBoundingClientRect();

  toSelection = () => {
    return new Selection(toSVGTarget(this.group, this.env.image));
  };

  destroy = () => {
    this.group.parentNode.removeChild(this.group);

    this.mask = null;
    this.rectangle = null;
    this.group = null;
  };
}
