import { useCallback, useState } from 'react';

const useToggleState = (intState) => {
  const [flag, setFlag] = useState(intState);

  const switchOn = useCallback((customFlag = true) => setFlag(customFlag), []);

  const switchOff = useCallback(() => setFlag(false), []);

  return [flag, switchOn, switchOff];
};

export default useToggleState;
