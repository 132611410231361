import { isEmpty } from 'lodash';

const mentionRegex = /(@\[[^\]]+]\(\w+\))/gi;
const mentionDisplayNameRegex = /@\[([^\]]+)]\(\w+\)/gi;

export const getMentionText = (content = '') => {
  const userNameMap = new Map(content.matchAll(mentionDisplayNameRegex));
  const textParts = content.split(mentionRegex);
  return textParts.map((text, index) => {
    const userName = userNameMap.get(text);
    return userName ? (
      <span key={index} className="mention-input__text-highlighter">
        {userName}
      </span>
    ) : (
      text
    );
  });
};

export const insertEmojiToMentionText = (
  content = '',
  emoji = '',
  selectionPosition = 0
) => {
  const mentionMatches = content.matchAll(mentionDisplayNameRegex);
  if (!isEmpty(mentionMatches)) {
    return (
      content.substring(0, selectionPosition) +
      emoji +
      content.substring(selectionPosition)
    );
  }
  // for counting the hidden characters in raw mention text
  let leftExtraCharCount = 0;
  for (const match of mentionMatches) {
    const startIndex = match.index - leftExtraCharCount;
    const endIndex = startIndex + match[1].length;
    // break when mention text is to the right of the cursor
    if (selectionPosition <= startIndex) {
      break;
    }
    // replace mention with emoji if cursor is inside the mention text
    if (selectionPosition < endIndex) {
      return (
        content.substring(0, match.index) +
        emoji +
        content.substring(match.index + match[0].length)
      );
    }
    leftExtraCharCount += match[0].length - match[1].length;
  }
  // insert the cursor between the raw mention text
  let cursorPosition = selectionPosition + leftExtraCharCount;
  return (
    content.substring(0, cursorPosition) +
    emoji +
    content.substring(cursorPosition)
  );
};
