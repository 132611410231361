import { createContext, useContext, useState } from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';

/**
 * @type {React.Context<{collection: Object[], setCollection: React.Dispatch, insertItem: Function, updateItem: Function, deleteItem: Function}>}
 */
const CursorPaginationContext = createContext({});

/**
 * @description Due to the limitation of RTK Query, to manage cursor-paginated list with infinite loading (eg: comment list),
 * the data must be aggregated from time to time and shared across component group
 * @param {ReactNode} children
 * @return {JSX.Element}
 * @constructor
 */
const CursorPaginationContextProvider = ({ children }) => {
  const [collection, setCollection] = useState([]);

  const insertItem = (newItem) => {
    setCollection((prevState) => [newItem, ...prevState]);
  };

  const updateItem = (item, id = '_id') => {
    setCollection((prevState) =>
      map(prevState, (value) => (value[id] === item[id] ? item : value))
    );
  };

  const deleteItem = (item, id = '_id') => {
    setCollection((prevState) =>
      filter(prevState, (val) => val[id] !== item[id])
    );
  };

  return (
    <CursorPaginationContext.Provider
      value={{ collection, setCollection, insertItem, updateItem, deleteItem }}
    >
      {children}
    </CursorPaginationContext.Provider>
  );
};

CursorPaginationContext.displayName = 'CursorPaginationContext';

const useCursorPaginationContext = () => useContext(CursorPaginationContext);

export { CursorPaginationContextProvider, useCursorPaginationContext };
