import moment from 'moment';
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Breadcrumbs,
  Grid,
  Button,
  Tabs,
  Tab
} from '@mui/material';
import {
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
  Publish as PublishIcon,
  AddOutlined as AddOutlinedIcon
} from '@mui/icons-material';
import MenuButton from '../../../../components/MenuButton';
import { getSize } from '../../../../utils/fileUtils';
import {
  FILE_TYPES,
  FILE_ICON,
  MANAGED_BY_ME_FOLDER_NAME,
  ROLES
} from '../../../../constants';
import { useMemo, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';
import { useSlideBox } from 'hooks/slideBox/useSlideBox';
import { CreateSlidebox } from 'features/Slidebox/CreateSlidebox';

const FileAndFolderListView = ({
  setOpenDeleteDialog,
  folderType,
  folderId,
  rows,
  handleDoubleClick,
  breadcrumbs,
  handleBreadcrumbsClick,
  setOpenMoveFolder
}) => {
  const { dispatch, setViewComponent } = useSlideboxContext();

  const {
    user: { role }
  } = useAuth();

  const { handleSetCurrentView } = useSlideBox();

  const handleDelete = (item) => {
    dispatch({ type: 'SET_STATE', key: 'selectedNodeId', value: item._id });
    dispatch({ type: 'SET_STATE', key: 'selectedNodeType', value: item.type });
    setOpenDeleteDialog(true);
  };

  const enableUpload = useMemo(
    () =>
      !!folderId &&
      (folderType === FILE_TYPES.FOLDER || folderType === FILE_TYPES.CASE),
    [folderId, folderType]
  );

  const menuVerticalItems = [
    {
      label: <>{<DeleteIcon />}&nbsp;Delete</>,
      onClick: handleDelete
    }
  ];
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let id = null;
    if (newValue === 1) {
      id = MANAGED_BY_ME_FOLDER_NAME;
    }
    handleSetCurrentView(id);
  };

  return (
    <Box sx={{ paddingBottom: '60px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          variant="fullWidth"
        >
          <Tab label="SlideBox" />
          {(role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN) && (
            <Tab label="Managed By Me" />
          )}
        </Tabs>
      </Box>
      <div role="presentation">
        <Breadcrumbs maxItems={2} aria-label="breadcrumb">
          {breadcrumbs.map((folder, index) => (
            <Typography
              sx={{
                fontWeight: index === breadcrumbs.length - 1 ? '600' : '',
                fontSize: '18px',
                cursor: 'pointer'
              }}
              key={folder._id}
              onClick={() =>
                index === breadcrumbs.length - 1
                  ? null
                  : handleBreadcrumbsClick(index)
              }
            >
              {folder.name}
            </Typography>
          ))}
        </Breadcrumbs>
      </div>
      <List dense={true}>
        {!rows.length && (
          <Box sx={{ textAlign: 'center' }}>
            This {folderId ? folderType : 'Slidebox'} {folderType} empty
          </Box>
        )}
        {!!rows.length &&
          rows.map((row, index) => {
            const IconComponent =
              (FILE_ICON[row.type] && FILE_ICON[row.type]) || null;

            const secondary = `${getSize(row.size)} | ${moment(
              row.lastModifiedDate
            ).format('MMM DD, YYYY')}`;

            return (
              <div key={index}>
                <ListItem
                  sx={{ cursor: 'pointer' }}
                  secondaryAction={
                    <MenuButton
                      buttonIcon={<MoreVertIcon />}
                      menuItems={menuVerticalItems}
                      value={row}
                    />
                  }
                >
                  <ListItemAvatar>
                    {!!IconComponent && <IconComponent />}
                  </ListItemAvatar>
                  <ListItemText
                    onClick={() => handleDoubleClick(row)}
                    // primary={row.name}
                    primary={row.name}
                    secondary={secondary}
                  />
                </ListItem>
              </div>
            );
          })}
      </List>
      <Divider />

      <Grid
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height: '56px',
          backgroundColor: '#ffff'
        }}
        container
      >
        <Grid item xs={6} sx={{ textAlign: 'center' }}>
          <CreateSlidebox>
            {({ onClick, disabled }) => (
              <Button size="small" onClick={onClick} disabled={disabled}>
                <Box>
                  <AddOutlinedIcon />
                  <Typography sx={{ fontSize: '12px' }}>New</Typography>
                </Box>
              </Button>
            )}
          </CreateSlidebox>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'center' }}>
          <Button
            size="small"
            disabled={!enableUpload}
            onClick={() => {
              setViewComponent('file_upload');
            }}
          >
            <Box>
              <PublishIcon />
              <Typography sx={{ fontSize: '12px' }}>Upload</Typography>
            </Box>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FileAndFolderListView;
