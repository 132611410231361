import { Box, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const FileProcessingDialog = ( { open, handleOpen, text = "PROCESSING FILE"}) => {
  const onCloseHandler = (reason) => {
  }
    return (
      <Dialog
        open={open}
        keepMounted
        onClose={(reason) => onCloseHandler(reason)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{ text }</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              paddingBottom: '10px',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </Box>
          <DialogContentText id="alert-dialog-description">
            Please wait a moment...
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
}

export default FileProcessingDialog;