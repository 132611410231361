/**
 * @description the recogito widget displaying Annotation title on Shape Popover,
 * for reference: https://recogito.github.io/guides/editor-widgets/
 * @param annotation
 * @returns {HTMLDivElement}
 * @constructor
 */
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ReactDOM from 'react-dom';

export const AnalysisGraphCompBtnWidget = ({ annotation, onClick }) => {
  const divElement = document.createElement('div');
  const deleteButton = document.querySelector(
    'button.r6o-btn.delete-annotation'
  );
  const analysisButton = document.querySelector(
    'button.r6o-btn.analysis-style'
  );
  const analysisGraphBtn = document.querySelector(
    'button.r6o-btn.analysis-graph'
  );
  const analysisGraphCompBtn = document.querySelector(
    'button.r6o-btn.analysis-graph-comp'
  );

  // covers add and edit annotation flows
  // as we have to display analysis button only if
  // delete button, analysis button and  analysis graph button exists
  // that is the edit annitaion mode
  // only add if analysis graph comparsion button is not already added
  if (
    deleteButton &&
    analysisButton &&
    analysisGraphBtn &&
    !analysisGraphCompBtn
  ) {
    const analysisGraphCompBtn = document.createElement('button');
    analysisGraphCompBtn.classList.add('r6o-btn');
    analysisGraphCompBtn.classList.add('analysis-graph-comp');
    analysisGraphCompBtn.classList.add('analysis-style');
    analysisGraphCompBtn.setAttribute('title', 'Analysis Comparison');
    analysisGraphCompBtn.onclick = onClick;
    analysisGraphBtn.insertAdjacentElement('afterend', analysisGraphCompBtn);
    ReactDOM.render(
      <CompareArrowsIcon fontSize="small" />,
      analysisGraphCompBtn
    );
  }

  // return empty element always
  // as we are appending analysis button next to the delete button
  return divElement;
};
