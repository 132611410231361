import { Button } from '@mui/material';
import { useSnackbar as useSnackbarNoti} from 'notistack'


export const useSnackbar = () => {
  const useHook = useSnackbarNoti()
  const action = (snackbarId) => (
    <>
      <Button
        sx={{color: 'white'}}
        variant="text"
        onClick={() => {
          useHook.closeSnackbar(snackbarId)
        }}
      >
        Dismiss
      </Button>
    </>
  )
  const defaultConfig = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    }
  }
  const enqueueSnackbar = (message, config) => {
    useHook.enqueueSnackbar(message, { action, ...defaultConfig, ...config })
  }
  const closeSnackbar = (id) => {
    useHook.closeSnackbar(id)
  }
  return { enqueueSnackbar, closeSnackbar }
}
