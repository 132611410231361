import { useAuth } from 'hooks/useAuth';
import { createContext, useContext, useEffect, useState } from 'react';
import { useFetchOrganizationsMutation } from 'services/organizations';
import { useGetCollaboratorsQuery, useGetMembersQuery } from 'services/user';
import { useGetPendingInvitationsQuery } from 'services/userRequest';
import { IOrganization } from 'types/organization';
import { IUser } from 'types/user';
import { ROLES } from '../../constants';

interface ContextValue {
  tab: number;
  users: IUser[];
  isLoadingMembers: boolean;
  organizations: IOrganization[];
  isLoadingOrganizations: boolean;
  pendingInvitations: IUser[];
  isLoadingPendingInvitations: boolean;
  collaborators: IUser[];
  isLoadingCollaborators: boolean;
}

const defaultValues: ContextValue = {
  tab: 0,
  users: [],
  isLoadingMembers: false,
  organizations: [],
  isLoadingOrganizations: false,
  pendingInvitations: [],
  isLoadingPendingInvitations: false,
  collaborators: [],
  isLoadingCollaborators: false
};

const DashboardContext = createContext<{
  tab: number;
  users: IUser[];
  isLoadingMembers: boolean;
  organizations: IOrganization[];
  isLoadingOrganizations: boolean;
  pendingInvitations: IUser[];
  isLoadingPendingInvitations: boolean;
  collaborators: IUser[];
  isLoadingCollaborators: boolean;
  onInviteSuccess: VoidFunction;
  onRefresh: VoidFunction;
  refetchOrganizations: VoidFunction;
  handleTabChange: (index: number) => void;
}>({
  ...defaultValues,
  onInviteSuccess: () => {},
  onRefresh: () => {},
  refetchOrganizations: () => {},
  handleTabChange: () => {}
});

const DashboardContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuth();
  const { _id, role } = user;

  const [tab, setTab] = useState<number>(0);
  const [users, setUsers] = useState<IUser[]>([]);
  const [pendingInvitations, setPendingInvitations] = useState<IUser[]>([]);
  const [collaborators, setCollaborators] = useState<IUser[]>([]);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);

  /* Pending Invitations */
  const {
    data: resPendingInvitations,
    refetch: refetchPendingInvitations,
    isLoading: isLoadingPendingInvitations
  } = useGetPendingInvitationsQuery(
    { createdBy: _id, type: 'invite' },
    { refetchOnMountOrArgChange: false }
  );

  useEffect(() => {
    setPendingInvitations(resPendingInvitations || []);
  }, [resPendingInvitations]);
  /* End */

  /* Users */
  const {
    data: dataUsers,
    refetch: refetchMembers,
    isLoading: isLoadingMembers
  } = useGetMembersQuery({}, { refetchOnMountOrArgChange: false });

  useEffect(() => {
    setUsers(dataUsers || []);
  }, [dataUsers]);

  const {
    data: dataCollaborators,
    refetch: refetchCollaborators,
    isLoading: isLoadingCollaborators
  } = useGetCollaboratorsQuery({}, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    setCollaborators(dataCollaborators || []);
  }, [dataCollaborators]);

  const [fetchOrganizations, { isLoading: isLoadingOrganizations }] =
    useFetchOrganizationsMutation();

  const refetchOrganizations = async () => {
    if (role !== ROLES.SUPER_ADMIN) return;
    const data: any = await fetchOrganizations({});
    if (data) setOrganizations(data.data || []);
  };

  useEffect(() => {
    if (role === ROLES.SUPER_ADMIN) refetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  // ------------------  ------------------

  const handleTabChange = (newValue: number) => {
    if (newValue === 0) {
      refetchMembers();
    } else if (newValue === 1) {
      refetchPendingInvitations();
    } else if (newValue === 2) {
      refetchCollaborators();
    } else if (newValue === 3 && role === ROLES.SUPER_ADMIN) {
      refetchOrganizations();
    }
    setTab(newValue);
  };

  const onInviteSuccess = () => {
    refetchPendingInvitations();
    refetchCollaborators();
    setTab(1);
  };

  const onRefresh = () => {
    refetchMembers();
    refetchPendingInvitations();
    refetchCollaborators();
    if (role === ROLES.SUPER_ADMIN) refetchOrganizations();
  };

  return (
    <DashboardContext.Provider
      value={{
        tab,
        users,
        isLoadingMembers,
        organizations,
        isLoadingOrganizations,
        pendingInvitations,
        isLoadingPendingInvitations,
        collaborators,
        isLoadingCollaborators,
        onInviteSuccess,
        onRefresh,
        refetchOrganizations,
        handleTabChange
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

DashboardContext.displayName = 'DashboardContext';

const useDashboardContext = () => useContext(DashboardContext);

export { useDashboardContext, DashboardContextProvider };
