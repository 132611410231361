import React from 'react';
import StringNetwork from './proteinView';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { uuidv4 } from '@firebase/util';
import { Container, TableCell, TableRow, useTheme, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Box } from '@mui/system';
import CustomTable from '../../../components/Table';
import FileProcessingDialog from '../FileProcessingDialog';
import { useEffect, useMemo, useState } from 'react';

const AnalysisPopUp = ( { biomarkers, isFetching } ) => {
  const theme = useTheme();
  const [ proteinSelected, setProteinSelected ] = useState( biomarkers?.[ 0 ]?.parsedName.split( ' ' )[ 0 ] );
  const [ isFetchingStringDB, setIsFetchingStringDB ] = useState( false );
  const [ openAlertEmptyName, setOpenAlertEmptyName ] = useState( false );

  const headCells = useMemo( () => {
    if ( !biomarkers || biomarkers.length === 0 ) return [];
    return Object.keys( biomarkers.biomarkerData[ 0 ] ).map( ( key ) => ( {
      id: key,
      label: key,
      sortable: true,
    } ) );
  }, [ biomarkers ] );
  // Seleccionar la primera proteína cuando los biomarkers cambian
  useEffect( () => {
    if ( Array.isArray( biomarkers ) && biomarkers.length > 0 && biomarkers[ 0 ]?.parsedName ) {
      const [ firstProtein ] = biomarkers[ 0 ].parsedName.split( ' ' );
      setProteinSelected( firstProtein );
    }
  }, [ biomarkers, setProteinSelected ] );

  const handleClickRow = ( biomarker ) => {
    if ( biomarker?.parsedName ) {
      setIsFetchingStringDB( true ); // Display the loading spinner
      const [ firstProtein, second ] = biomarker.parsedName.split( ' ' );
      setProteinSelected( firstProtein + '%0d' + second );
      // setProteinSelected( biomarker.parsedName.split( ' ' ).join( '%0d' ) );
    } else {
      setOpenAlertEmptyName( true );
    }
    setTimeout( () => {
      setIsFetchingStringDB( false );
    }, 5000 ); //Timeout to remove the loading spinner
  };


  return (
    <Container maxWidth="100%">
      <AlertDialog open={ openAlertEmptyName} setOpen={setOpenAlertEmptyName} title="Empty Name" text="This protein has no parsed name" />
      {typeof biomarkers != 'undefined' ? (
        <TabContext value={'0'}>
          <TabPanel value="0" key="0" className='analysis-graph-container'>
            {/* {isFetchingStringDB && <div>Just waiting that the request ends </div> } */}
            {isFetchingStringDB && <FileProcessingDialog open={isFetchingStringDB} text="FETCHING PROTEIN DATA" />}
            {/* <TabPanel value="0" key="0"> */}
            {proteinSelected && <StringNetwork identifier={proteinSelected} setIsFetching={setIsFetchingStringDB} />}
            <CustomTable
              rows={biomarkers.biomarkerData}
              headCells={headCells}
              isLoading={isFetching}
              pagingMarginRight="48px"
              // style={{ width: proteinSelected ? '50%' : '100%', height: 'auto' }}
            >
              {( head, items ) =>
                items.map( ( biomarker ) => (
                  <TableRow hover={true} key={uuidv4()} onClick={() => handleClickRow( biomarker )}>
                    {Object.keys( biomarker ).map( ( attr ) => (
                      <TableCell align="left" key={uuidv4()}>
                        <Box
                          sx={{
                            textAlign: 'left',
                            [ theme.breakpoints.down( 'sm' ) ]: {
                              maxWidth: '60px'
                            }
                          }}
                        >
                          {biomarker[ attr ]}
                        </Box>
                      </TableCell>
                    ) )}
                  </TableRow>
                ) )
              }
            </CustomTable>
          </TabPanel>
        </TabContext>
      ) : (
        <Box
          sx={{ textAlign: 'center', color: theme.palette.text.textGray1 }}
          mt={1}
        >
          {isFetching ? 'Loading analysis data..' : 'No analysis data found'}
        </Box>
      )}
    </Container>
  );
};

export default AnalysisPopUp;


function AlertDialog( { open, setOpen, text, title } ) {
  const handleClose = () => {
    setOpen( false );
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus> OK </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}