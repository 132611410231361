import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { debounce } from 'lodash';
import { useGetAnnotationTitlesQuery } from 'services/annotation';
const filter = createFilterOptions();

export const AutocompleteAnnotationTitle = ({
  isCreateable = false,
  isMulti = false,
  onChange,
  value,
  label = 'Annotation',
  placeholder = 'Annotation',
  sx = {}
}) => {
  const [annotations, setAnnotations] = useState([]);

  const [annotationQuery, setAnnotationQuery] = useState({
    limit: 100,
    page: 1,
    search: ''
  });

  const { data: fetchedAnnotations } = useGetAnnotationTitlesQuery(
    annotationQuery,
    {
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    if (fetchedAnnotations) {
      setAnnotations(
        fetchedAnnotations.map((annotation) => ({
          title: annotation,
          _id: annotation
        }))
      );
    }
  }, [fetchedAnnotations]);

  const debouncedSearch = debounce((search) => {
    setAnnotationQuery({
      ...annotationQuery,
      search
    });
  }, 200);

  return (
    <Autocomplete
      multiple={isMulti}
      disableCloseOnSelect={isMulti}
      id="annotations-outlined"
      sx={sx}
      onBlur={() => {
        setAnnotationQuery({
          ...annotationQuery,
          search: ''
        });
      }}
      options={
        annotations
          ? annotations.map((annotation) => ({
              title: annotation.title,
              _id: annotation._id
            }))
          : []
      }
      freeSolo={isCreateable}
      onChange={(e, value) => {
        const convertAnnotations = value.map((annotation) => {
          if (typeof annotation === 'string') {
            return {
              title: annotation,
              _id: ''
            };
          }
          return annotation;
        });
        // remove duplicate annotations
        const uniqueAnnotations = convertAnnotations.filter(
          (annotation, index, self) =>
            index === self.findIndex((t) => t.title === annotation.title)
        );
        // remove empty annotations
        const filteredAnnotations = uniqueAnnotations.filter(
          (annotation) => annotation.title.trim() !== ''
        );
        onChange && onChange(filteredAnnotations);
      }}
      value={value || []}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const existingList = value ? [...value, ...options] : options;
        const isExisting = existingList.some((option) => {
          if (params.inputValue) {
            return option.title === params.inputValue;
          }
          return false;
        });
        if (params.inputValue !== '' && isCreateable && !isExisting) {
          filtered.push({
            _id: '',
            title: params.inputValue,
            label: `Add "${params.inputValue}"`
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      renderOption={(props, option) => (
        <li {...props}>{option.label || option.title}</li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          variant="outlined"
          placeholder={placeholder}
          label={label}
          value={annotationQuery.search}
          onChange={(e) => {
            e.persist();
            debouncedSearch(e.target.value);
          }}
          size="small"
        />
      )}
    />
  );
};
