import moment from "moment";

export const getPublicImage = (path) => `/assets/images/${path}`;

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export const formatDate = (input, format) => {
  const date = moment.utc(input)
  return moment(date).local().format(format)
}

export const generateTileSourcesFromUploadedTile = ({
  slideWidth,
  slideHeight,
  tileWidth,
  tileHeight,
  zoomLevel,
  imageUrl
}) => {
  return {
    width: slideWidth,
    height: slideHeight,
    tileOverlap: 0,
    maxLevel: 12,
    tileWidth,
    tileHeight,
    getTileUrl: (level, x, y) => {
      return `${imageUrl}/${level}/${x}_${y}.webp`
    }
  }
}

export const generateTileSourcesFromXML = ({ imageUrl, xmlData, ...rest }) => {
  const { xmlns, Format, Overlap, TileSize } = xmlData.Image._attributes
  const { Height, Width } = xmlData.Image.Size._attributes
  return {
    Image: {
      Url: imageUrl,
      xmlns,
      Format,
      Overlap,
      TileSize,
      Size: {
        Height,
        Width
      }
    },
    queryParams:`?random=${Date.now()}`,
    ...rest
  }
}