import { Box, Button, LinearProgress, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  useCreateAnnotationCommentMutation,
  useUpdateAnnotationCommentMutation
} from 'services/annotation';
import { useSnackbar } from 'hooks/useSnackbar';
import MentionInput from 'components/MentionInput';
import useTaggableUsers from 'hooks/useTaggableUsers';
import { useCursorPaginationContext } from 'contexts/CursorPaginationContext';

/**
 * @param {string} annotationId
 * @param {{_id: string, content: string}|undefined} comment
 * @param {{(): unknown}|undefined} onSuccess
 * @returns {JSX.Element}
 * @constructor
 */
const CommentBox = ({ annotationId, comment, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const taggableUsers = useTaggableUsers({ enableFetch: true });
  const { updateItem, insertItem } = useCursorPaginationContext();

  const [text, setText] = useState('');
  const [sendComment, { isLoading: isCreating }] =
    useCreateAnnotationCommentMutation();
  const [updateComment, { isLoading: isUpdating }] =
    useUpdateAnnotationCommentMutation();

  useEffect(() => {
    if (comment) setText(comment.content);
  }, [comment]);

  const handleSubmit = async () => {
    if (isEmpty(text.trim())) return;
    if ((isCreating && !comment) || (comment && isUpdating)) return;
    try {
      const result = comment
        ? await updateComment({
            annotationId,
            commentId: comment._id,
            content: text.trim()
          })
        : await sendComment({
            id: annotationId,
            content: text.trim()
          });
      if (result.data) {
        setText('');
        onSuccess?.();
        if (comment) {
          updateItem?.(result.data);
        } else {
          insertItem?.(result.data);
        }
      }
    } catch (e) {
      console.warn(e);
      enqueueSnackbar(e.message || 'Something went wrong!', {
        variant: 'error'
      });
    }
  };

  return (
    <Stack spacing={1}>
      <Box>{(isCreating || isUpdating) && <LinearProgress />}</Box>
      <Stack alignItems="flex-start" spacing={1}>
        <MentionInput
          rows={2}
          sx={{ fontSize: '0.85rem' }}
          inputStyles={{ fontSize: '0.85rem' }}
          placeholder="Write a comment..."
          value={text}
          disabled={isCreating || isUpdating}
          onKeyDown={(e) => e.stopPropagation()}
          onKeyUp={(e) => e.stopPropagation()}
          onChange={(e) => setText(e.target.value)}
          data={taggableUsers}
        />
        <Button
          variant="contained"
          size="small"
          disabled={isEmpty(text.trim()) || isCreating || isUpdating}
          onClick={handleSubmit}
        >
          {comment ? 'Update' : 'Send'}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CommentBox;
