import _ from 'lodash';

import { EUserRole, EUserStatus } from 'types/user';

export const getUserStatusColor = (isActive: boolean) => {
  return isActive ? 'success' : 'error';
};

export const getUserRoleColor = (role?: EUserRole) => {
  switch (role) {
    case EUserRole.SUPER_ADMIN:
      return 'success';
    case EUserRole.ADMIN:
      return 'info';
    case EUserRole.COLLABORATOR:
      return 'primary';
    case EUserRole.USER:
      return 'warning';
    default:
      return 'default';
  }
};

export const USER_ROLES_OPTIONS = Object.values(EUserRole).map((role) => ({
  value: role,
  label: _.capitalize(role)
}));

export const USER_STATUS_OPTIONS = Object.values(EUserStatus).map((status) => ({
  value: status,
  label: _.capitalize(status)
}));
