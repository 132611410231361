/**
 * @description the recogito multiple tags widget,
 * @param slideId - string
 * @param annotation - object
 * @param id - string
 * @param name - string
 * @param label - string
 * @param placeholder - string
 * @param autofocus - boolean
 * @param disabled - boolean
 * @param required - boolean
 * @param options - array [{value: string, label: string}]
 * @returns {HTMLDivElement}
 * @constructor
 */

import { convertArray } from 'utils/convertArray';

export const AnnoAutocompleteWidget = ({
  slideId,
  annotation,
  id,
  name,
  placeholder,
  label,
  autofocus,
  disabled,
  required,
  hasCheckbox,
  options = []
}) => {
  if (!options || options.length === 0) {
    options = localStorage.getItem(`widget-options-${name}-${slideId}`)
      ? JSON.parse(localStorage.getItem(`widget-options-${name}-${slideId}`))
      : [];
  }
  const tagValues = annotation?.underlying?.[name] || '';

  const tagIds = tagValues && tagValues.map((tag) => tag.id);

  //element
  const wrapper = document.createElement('div');
  const inputElement = document.createElement('input');
  const dropdownInput = document.createElement('div');
  const inputFilter = document.createElement('input');
  const dropdown = document.createElement('div');
  const dropdownContent = document.createElement('ul');
  const clearFilterButton = document.createElement('button');

  if (label) {
    const labelElement = document.createElement('label');
    labelElement.setAttribute('for', `annotation-${name}-input`);
    labelElement.innerHTML = label;
    wrapper.appendChild(labelElement);
  }

  // empty dropdown
  const dropdownItemEmpty = document.createElement('li');
  dropdownItemEmpty.classList.add('dropdown-item');
  dropdownItemEmpty.classList.add(`dropdown-item-${name}`);
  dropdownItemEmpty.style.display = 'none';
  const labelEmpty = document.createElement('label');
  labelEmpty.innerHTML = 'No options';
  dropdownItemEmpty.appendChild(labelEmpty);

  // filter
  inputFilter.classList.add(`dropdown-input-${name}-filter`);
  inputFilter.setAttribute('type', 'text');
  inputFilter.setAttribute('placeholder', `Enter ${label || name}...`);

  inputFilter.addEventListener('input', (event) => {
    const filter = event.target.value.toUpperCase();
    const dropdownItems = dropdownContent.querySelectorAll(
      `.dropdown-item-${name}`
    );
    if (dropdownItems.length > 0) {
      let hasItem = false;
      dropdownItems.forEach((item) => {
        const label = item.querySelector('label');
        const txtValue = label.textContent || label.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          item.style.display = '';
          hasItem = true;
        } else {
          item.style.display = 'none';
        }
      });
      if (!hasItem) {
        dropdownItemEmpty.style.display = '';
      } else {
        dropdownItemEmpty.style.display = 'none';
      }
    }

    if (filter) {
      clearFilterButton.style.display = '';
    } else {
      clearFilterButton.style.display = 'none';
    }
  });

  //clear filter button
  clearFilterButton.classList.add('clear-filter-button');
  clearFilterButton.innerHTML = '&times;';
  clearFilterButton.style.display = 'none';
  clearFilterButton.addEventListener('click', () => {
    inputFilter.value = '';
    const dropdownItems = dropdownContent.querySelectorAll(
      `.dropdown-item-${name}`
    );
    if (dropdownItems.length > 0) {
      dropdownItems.forEach((item) => {
        item.style.display = '';
      });
    }
    clearFilterButton.style.display = 'none';
  });

  // function

  const onSelectTag = (event) => {
    if (disabled) return;
    const value = event.target.value;
    const currentTags = convertArray(inputElement.value);
    if (event.target.checked) {
      currentTags.push(value);
    } else {
      const index = currentTags.indexOf(value.toString());
      if (index > -1) currentTags.splice(index, 1);
    }
    const uniqueTags = [...new Set(currentTags)].filter((tag) => tag !== '');
    inputElement.value = uniqueTags.join(',');
    // send event to inputElement
    const changeEvent = new Event('input', {
      bubbles: true
    });
    inputElement.dispatchEvent(changeEvent);
  };

  const createSelected = (tag) => {
    if (tag !== '') {
      const tagWrapper = document.createElement('div');
      tagWrapper.classList.add('tag-wrapper');
      const span = document.createElement('span');
      span.classList.add('tag');
      let label = tag;
      const labelElement =
        dropdownContent &&
        dropdownContent.querySelector(`input[value="${tag}"]`);
      if (labelElement) {
        label = labelElement.dataset.label;
      }
      span.innerHTML = label;
      const close = document.createElement('span');
      close.classList.add('close');
      close.innerHTML = '&times;';
      close.addEventListener('click', () => {
        if (disabled) return;
        onSelectTag({
          target: {
            value: tag,
            checked: false
          }
        });
        // uncheck the tag
        const checkbox =
          dropdownContent &&
          dropdownContent.querySelector(`input[value="${tag}"]`);
        if (checkbox) checkbox.checked = false;

        // remove class active
        const dropdownItem =
          dropdownContent &&
          dropdownContent.querySelector(`li[data-value="${tag}"]`);
        if (dropdownItem) {
          dropdownItem.classList.remove('active');
        }
      });
      tagWrapper.appendChild(span);
      tagWrapper.appendChild(close);
      dropdownInput.appendChild(tagWrapper);
    }
  };

  const onInputChanged = (event) => {
    localStorage.setItem(id, event.currentTarget.value);
    const newTags = convertArray(event.currentTarget.value);
    const uniqueTags = [...new Set(newTags)].filter((tag) => tag !== '');
    dropdownInput.innerHTML = '';
    uniqueTags.forEach((tag) => {
      createSelected(tag);
    });
    dropdownInput.appendChild(inputFilter);
    dropdownInput.appendChild(clearFilterButton);
  };

  // end function
  wrapper.classList.add('widget-wrapper');
  wrapper.classList.add('dropdown-widget');
  wrapper.classList.add(`anno-${name}-widget`);

  inputElement.classList.add(`annotation-${name}-input`);
  inputElement.classList.add('widget-input');
  inputElement.setAttribute('type', 'text');
  inputElement.setAttribute('placeholder', placeholder || name);
  autofocus && inputElement.setAttribute('autofocus', '');
  required && inputElement.setAttribute('required', '');
  inputElement.style.display = 'none';
  inputElement.value = tagIds || '';

  inputElement.addEventListener('input', (event) => {
    onInputChanged(event);
  });

  dropdown.classList.add('dropdown');
  dropdown.classList.add(`dropdown-${name}`);
  dropdownContent.classList.add('dropdown-content');
  dropdownContent.appendChild(dropdownItemEmpty);

  if (options && options.length > 0) {
    dropdownItemEmpty.style.display = 'none';
    for (let i = 0; i < options.length; i++) {
      const tag = options[i];
      const dropdownItem = document.createElement('li');
      dropdownItem.classList.add('dropdown-item');
      if (!hasCheckbox) dropdownItem.classList.add('no-checkbox');
      dropdownItem.classList.add(`dropdown-item-${name}`);
      dropdownItem.setAttribute('data-value', tag.value);
      // add class active if the tag is in the list of tags
      if (tagIds && tagIds.includes(tag.value)) {
        dropdownItem.classList.add('active');
      }

      // create a checkbox

      const checkboxContainer = document.createElement('label');
      checkboxContainer.classList.add('checkbox-container');
      if (!hasCheckbox) checkboxContainer.style.display = 'none';
      const checkboxCheckMark = document.createElement('span');
      checkboxCheckMark.classList.add('checkmark');

      const checkbox = document.createElement('input');
      checkbox.setAttribute('type', 'checkbox');
      checkbox.setAttribute('id', `${name}-${tag.value}`);
      checkbox.setAttribute('name', `${name}-${tag.value}`);
      checkbox.setAttribute('value', tag.value);
      checkbox.setAttribute('data-label', tag.label);

      // check if the tag is in the list of tags
      if (tagIds && tagIds.includes(tag.value)) {
        checkbox.setAttribute('checked', true);
      }

      // create a label for the checkbox, and also add in HTML
      const label = document.createElement('label');
      label.setAttribute('for', `${name}-${tag.value}`);
      label.innerHTML = tag.label;

      // add the checkbox and label to the li
      checkboxContainer.appendChild(checkbox);
      checkboxContainer.appendChild(checkboxCheckMark);
      dropdownItem.appendChild(checkboxContainer);
      dropdownItem.appendChild(label);

      // add the li to the ul
      dropdownContent.appendChild(dropdownItem);

      // detect click on li
      dropdownItem.addEventListener('click', (event) => {
        if (!event.target.matches('input')) {
          event.preventDefault();
          event.stopPropagation();
          const checkbox = event.currentTarget.querySelector('input');
          checkbox.checked = !checkbox.checked;
        }
        onSelectTag({
          target: {
            value: checkbox.value,
            checked: checkbox.checked
          }
        });
        if (checkbox.checked) {
          dropdownItem.classList.add('active');
        } else {
          dropdownItem.classList.remove('active');
        }
      });
    }
  } else {
    dropdownItemEmpty.style.display = '';
  }

  // dropdown values
  dropdownInput.classList.add('dropdown-input-wrapper');
  tagIds &&
    tagIds.forEach((tag) => {
      createSelected(tag);
    });

  dropdownInput.appendChild(inputFilter);
  dropdownInput.appendChild(clearFilterButton);

  wrapper.appendChild(dropdownInput);

  // checkout focus dropdown
  inputFilter.addEventListener('focusin', (event) => {
    dropdown.classList.add('show');
  });

  // check click outside dropdown
  document.addEventListener('click', (event) => {
    if (
      !event.target.matches(`.dropdown-input-${name}-filter`) &&
      !event.target.closest(`.dropdown-${name}`)
    ) {
      dropdown.classList.remove('show');
    }
  });

  if (disabled) {
    dropdownInput.classList.add('disabled');
    inputElement.disabled = true;
    inputFilter.disabled = true;
  }

  dropdown.appendChild(dropdownContent);
  wrapper.appendChild(inputElement);
  wrapper.appendChild(dropdown);

  return wrapper;
};
