/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';
import { useSnackbar } from './useSnackbar';
import { useSlideBox } from '../hooks/slideBox/useSlideBox';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { navigateNotification } from '../utils/notificationHelper';
import { useWS, webSocketType } from 'contexts/WebSocketContext';

export const NOTIFICATION_EVENT = {
  RECEIVE_NOTIFICATION: 'receive_notification',
  REGISTER_USER: 'register_user'
};

const useNotificationSocket = (dispatch, data, refetch) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { handleSetCurrentView } = useSlideBox();
  const navigate = useNavigate();
  const { lastEvent } = useWS();
  const { user } = useAuth();

  useEffect(() => {
    if (!lastEvent) return;
    if (lastEvent.type !== webSocketType.NOTIFICATION) return;
    if (lastEvent.data?.notifyToEmailAddress !== user.email) return;
    const message = lastEvent.data;
    if (message) {
      message &&
        dispatch({
          type: 'ADD_NOTIFICATION',
          value: message
        });
      handleShowToastMessage(message);
      refetch();
      if (data?.data?.length) {
        const initNotifications = {
          notifications: data.data,
          unRead: data?.data.filter((v) => !v.isRead).length
        };
        dispatch({
          type: 'SET_NOTIFICATION',
          key: 'notifications',
          value: initNotifications
        });
      }
    }
  }, [dispatch, lastEvent]);

  const handleShowToastMessage = (message) => {
    let { _id, action, description } = message;
    if (description > 300) {
      description = description.substr(0, 300) + '...';
    }
    const actions = [];
    const link = action;
    if (link) {
      const handleOpenLink = async () => {
        await navigateNotification(link, navigate, handleSetCurrentView);
      };
      actions.push(
        <Button
          key="open"
          sx={{ textTransform: 'capitalize' }}
          onClick={handleOpenLink}
          color="warning"
        >
          Open
        </Button>
      );
      actions.push(
        <IconButton key="dismiss" onClick={() => closeSnackbar(_id)}>
          <CloseIcon />
        </IconButton>
      );
    }
    enqueueSnackbar(description, {
      key: _id,
      variant: 'success',
      action: actions
    });
  };
};

export default useNotificationSocket;
