import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
  TablePagination
} from '@mui/material';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import { useEffect, useLayoutEffect, useMemo, useReducer, useRef } from 'react';
import { getComparator, stableSort } from '../../utils/appHelpers';
import { useSelector } from 'react-redux';
import {
  slideBoxPage,
  slideBoxRowsPerPage
} from '../../stores/slidebox/slideboxSlice';

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead
      sx={{ position: 'sticky', top: 0, backgroundColor: '#F8F8F8', zIndex: 1 }}
    >
      <TableRow>
        {Array.isArray(headCells) && headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  selectedNodeId: PropTypes.string,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const intialState = {
  page: 0,
  rowsPerPage: 10,
  order: 'asc',
  orderBy: 'fullName',
  rowHeight: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE': {
      return { ...state, [action.key]: action.value };
    }
    default:
      return state;
  }
};

const CustomTable = ({
  rows,
  headCells,
  children = null,
  style,
  isLoading = true,
  emptyData = 'There is no data',
  currentPage = 0,
  onPageChange = (page) => {},
  onRowsPerPageChange = (rowsPerPage) => {},
  pagingMarginRight = '0px'
}) => {
  const theme = useTheme();

  const tableRef = useRef(null);

  const [state, dispatch] = useReducer(reducer, intialState);

  useLayoutEffect(() => {
    if (tableRef) {
      const rowHeight = tableRef.current.offsetHeight / state.rowsPerPage;
      dispatch({ type: 'SET_STATE', key: 'rowHeight', value: rowHeight });
    }
  }, [tableRef]);

  const getSlideboxPage = useSelector(slideBoxPage);
  const getSlideboxRowsPerPage = useSelector(slideBoxRowsPerPage);

  useEffect(() => {
    dispatch({ type: 'SET_STATE', key: 'page', value: getSlideboxPage });
    dispatch({
      type: 'SET_STATE',
      key: 'rowsPerPage',
      value: getSlideboxRowsPerPage
    });
  }, [getSlideboxPage, getSlideboxRowsPerPage]);

  // Computed Value
  const totalPages = useMemo(() => {
    return Math.ceil(rows.length / state.rowsPerPage);
  }, [rows, state.rowsPerPage]);

  const sortedItems = useMemo(() => {
    const { order, orderBy, page, rowsPerPage } = state;
    return stableSort(rows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [state, rows]);

  // Methods
  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    dispatch({
      type: 'SET_STATE',
      key: 'order',
      value: isAsc ? 'desc' : 'asc'
    });
    dispatch({ type: 'SET_STATE', key: 'orderBy', value: property });
  };

  const handlePaginationChange = (event, value) => {
    dispatch({ type: 'SET_STATE', key: 'page', value });
    onPageChange(value);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    dispatch({
      type: 'SET_STATE',
      key: 'rowsPerPage',
      value: rowsPerPage
    });
    onRowsPerPageChange(rowsPerPage);
    dispatch({ type: 'SET_STATE', key: 'page', value: 0 });
    onPageChange(0);
  };

  return (
    <TableContainer sx={{ height: '100%', ...style }}>
      <Table
        aria-labelledby="tableTitle"
        size={'medium'}
        sx={{
          overflow: 'auto',
          ' td': {
            fontWeight: '600',
            lineHeight: '1.5',
            color: theme.palette.text.textGray4,
            cursor: 'pointer',
            fontSize: '16px',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
              fontSize: '14px'
            }
          },
          ' .MuiTableCell-root': {
            padding: '8px'
          },
          ' th': {
            fontWeight: '600',
            lineHeight: '1.5',
            color: theme.palette.text.textGray5,
            cursor: 'pointer',
            fontSize: '16px',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
              fontSize: '14px'
            }
          }
        }}
      >
        <EnhancedTableHead
          headCells={headCells}
          order={state.order}
          orderBy={state.orderBy}
          // onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
        />
        <TableBody ref={tableRef}>
          {children && !isLoading && children(headCells, sortedItems)}

          {!children &&
            !isLoading &&
            sortedItems.map((item, index) => {
              // const isItemSelected = isSelected(row._id)
              return (
                <TableRow
                  onClick={() => {}}
                  hover
                  onDoubleClick={() => {}}
                  onKeyPress={() => {}}
                  role="checkbox"
                  tabIndex={-1}
                  key={item._id}
                >
                  {headCells.map((head, cIndex) => (
                    <TableCell
                      align="center"
                      key={`item_${item._id}_${head.id}`}
                    >
                      {item[head.id] || ''}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {isLoading && (
        <Box>
          <LinearProgress color="primary" />
        </Box>
      )}
      {!isLoading && !rows.length && (
        <Box
          sx={{ textAlign: 'center', color: theme.palette.text.textGray1 }}
          mt={1}
        >
          {emptyData}
        </Box>
      )}
      {!isLoading && !!totalPages && (
        <Box
          sx={{
            float: 'right',
            marginTop: '4px',
            right: '10px',
            position: 'relative',
            marginRight: pagingMarginRight
          }}
        >
          <TablePagination
            component="div"
            count={rows.length}
            page={state.page}
            onPageChange={handlePaginationChange}
            rowsPerPage={state.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[ 5, 10, 25, 50, 100 ]}
          />
        </Box>
      )}
    </TableContainer>
  );
};

export default CustomTable;
