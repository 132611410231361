import React from 'react';
import Slider from '@mui/material/Slider';

export const ZoomSlider = ({ zoomLevel, handleZoomChange, max, min }) => {
    const valueLabelFormat = (val) => {
        return `${(val * 100).toFixed(0)}%`; // Custom label format
      };
    return (
        <Slider
            sx={{
                width: "100px",
                zIndex: 1300,
                marginLeft: "16px"
            }}
            aria-label="zoom slider"
            value={zoomLevel}
            min={min}
            max={max}
            step={0.1}
            valueLabelDisplay="on"
            onChange={(event, newValue) => handleZoomChange(newValue)}
            valueLabelFormat={valueLabelFormat}
        />
    );
};