import { DATE_FILTER_VALUES } from '../constants';

export const filterByDate = (dateValue, filterValue) => {
  const value = new Date(dateValue).getTime();
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let clonedToday = new Date(today);
  switch (filterValue) {
    case DATE_FILTER_VALUES.Today:
      return value >= today.getTime();

    case DATE_FILTER_VALUES.Yesterday:
      let yesterday = clonedToday.setDate(clonedToday.getDate() - 1);
      return value >= yesterday && value < today.getTime();

    case DATE_FILTER_VALUES.PastWeek:
      let pastWeek = clonedToday.setDate(clonedToday.getDate() - 7);
      return value >= pastWeek && value <= today.getTime();

    case DATE_FILTER_VALUES.PastMonth:
      let pastMonth = clonedToday.setDate(clonedToday.getDate() - 30);
      return value >= pastMonth && value <= today.getTime();

    default:
      return false;
  }
};

export const filterRecords = (
  searchData = [],
  appliedFilters = { fileType: '', ownerEmail: '', updatedAt: '' }
) => {
  let results = searchData;
  if (appliedFilters.fileType) {
    results = results.filter((x) => x.type === appliedFilters.fileType);
  }
  if (appliedFilters.ownerEmail) {
    results = results.filter(
      (x) => x.createdBy.email === appliedFilters.ownerEmail
    );
  }
  if (appliedFilters.updatedAt) {
    results = results.filter((x) =>
      filterByDate(x.updatedAt, appliedFilters.updatedAt)
    );
  }
  return results;
};
