import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import Layout from '../components/Layout';
import Alert from '../components/Alert';
import { initFCM } from './fcm';
import { useCallback, useEffect, useState } from 'react';
import useToggleState from '../hooks/useToggleState';
import { useRegisterFCMMutation } from '../services/fcmToken';
import { ClientJS } from 'clientjs';

export function PrivateOutlet() {
  const auth = useAuth();
  const location = useLocation();
  const [fcmRequest, showFCMRequest, hideFCMRequest] = useToggleState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { getFCMToken } = initFCM();
  const [registerToken, { isUninitialized, isSuccess }] =
    useRegisterFCMMutation();
  const [isNotificationBlocked, setIsNotificationBlocked] = useState(
    localStorage.getItem('blockedNotifications') === 'true'
  );
  const [showNotice, setShowNotice] = useState(!isNotificationBlocked);

  const handleFCMToken = useCallback(async () => {
    if (!isUninitialized || isSuccess) return;
    if (Notification.permission === 'denied') {
      setShowWarning(true);
      return;
    }
    try {
      const token = await getFCMToken();
      const client = new ClientJS();
      const device = {
        browser: client.getBrowser(),
        os: client.getOS(),
        deviceName: client.getDevice(),
        type: client.getDeviceType()
      };
      await registerToken({
        token,
        fingerprint: client.getFingerprint(),
        device
      });
    } catch (e) {
      console.warn('[fcm]', e);
    }
  }, [getFCMToken, isSuccess, isUninitialized, registerToken]);

  useEffect(() => {
    const checkNotificationPermission = async () => {
      if (auth.user) {
        if (Notification.permission === 'default') {
          setShowNotice(false);
          showFCMRequest();
        } else if (Notification.permission === 'granted') {
          void handleFCMToken();
        } else if (Notification.permission === 'denied') {
          localStorage.setItem('blockedNotifications', 'true');
          setIsNotificationBlocked(true);
          setShowNotice(true);
          showFCMRequest();
        } else {
          const permission = await Notification.requestPermission();
          if (permission === 'granted') {
            void handleFCMToken();
          } else if (permission === 'denied') {
            localStorage.setItem('blockedNotifications', 'true');
            setIsNotificationBlocked(true);
            setShowNotice(true);
          }
        }
      }
    };
    const delayCheckPermission = setTimeout(checkNotificationPermission, 6000);
    return () => clearTimeout(delayCheckPermission); // Clean up
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  const Notice = () => {
    if (showNotice) {
      return 'You are seeing this because you have not yet enabled notifications from Digital Pathology.';
    }
    return 'Do you want to receive notifications from Digital Pathology?';
  };
  return auth.user ? (
    <Layout>
      <Outlet />
      <Alert
        title="Get informed"
        content={Notice()}
        open={fcmRequest}
        onDismiss={() => {
          hideFCMRequest();
        }}
        onConfirm={() => {
          hideFCMRequest();
          setShowNotice(true);
          void handleFCMToken();
        }}
      />
      {showWarning && (
        <Alert
          title="Notifications blocked"
          content="You have blocked notifications from Digital Pathology. You can enable them in your browser settings."
          open={true}
          cancelBtn="Close"
          onDismiss={() => {
            setShowWarning(false);
          }}
        />
      )}
    </Layout>
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}
