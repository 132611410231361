import { AutocompleteElement, useFormContext } from 'react-hook-form-mui';
import { useRef } from 'react';
import uniq from 'lodash/uniq';
import { TextField } from '@mui/material';

const CaseIdentifiersInput = ({
  name = 'caseIdentifiers',
  placeholder,
  options
}) => {
  const inputRef = useRef(null);
  const { control, setValue, getValues } = useFormContext();

  const handleInputChange = (event) => {
    let text = event.target.value;

    if (event.type === 'blur' && text.length) {
      if (text.endsWith(' ') || text.endsWith(',')) {
        text = text.substr(0, text.length - 1);
      }
      setValue(name, uniq([...getValues(name), text]));
      return;
    }

    if (event.type !== 'change') {
      return;
    }
  };

  return (
    <AutocompleteElement
      control={control}
      multiple
      name={name}
      options={options}
      matchId={false}
      autocompleteProps={{
        size: 'small',
        multiple: true,
        disableCloseOnSelect: true,
        filterSelectedOptions: false,
        getOptionLabel: (option) => {
          if (typeof option === 'string') return option;
          if (option?.inputValue) return option?.inputValue;
          return `Patient Id: ${option.patientId}`;
        },
        isOptionEqualToValue: (option, value) => option.id === value.id,
        renderInput: (params) => (
          <TextField
            {...params}
            placeholder={placeholder || 'Enter case identifiers...'}
          />
        )
      }}
      textFieldProps={{
        onChange: handleInputChange,
        inputRef,
        placeholder,
        onBlur: handleInputChange
      }}
    />
  );
};

export default CaseIdentifiersInput;
