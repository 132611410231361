import { api } from './api';

export const annotationApi = api.injectEndpoints({
  endpoints: (build) => ({
    createAnnotation: build.mutation({
      query: (body) => ({
        url: `/annotations`,
        method: 'POST',
        body
      }),
      transformResponse: (response) =>
        ({
          ...response,
          data: {
            ...response.data,
            id: response.data?._id,
            body: response.data?.body || []
          }
        } ?? null)
    }),
    editAnnotation: build.mutation({
      query: (payload) => {
        return {
          url: `/annotations/${payload.id}`,
          method: 'PATCH',
          body: payload
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Annotations', id: arg.id }
      ]
    }),
    deleteAnnotation: build.mutation({
      query: (id) => {
        return {
          url: `/annotations/${id}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Annotations', id: arg }
      ]
    }),
    getAllAnnotations: build.mutation({
      query: (id) => ({
        url: `/annotations?slideId=${id}`,
        method: 'GET'
      }),
      transformResponse: (response) => {
        const mapped = {};
        if (response) {
          const { data, success } = response;
          if (success) {
            mapped.data = data.map((anno) => ({
              ...anno,
              id: anno._id,
              body: anno.body || []
            }));
          }
        }
        return { ...response, ...mapped };
      },
      providesTags: (result, error, arg) => [{ type: 'Annotations', id: arg }]
    }),
    getAnnotationComments: build.query({
      query: ({ id, cursor = '', limit = 2 }) => ({
        url: `/annotations/${id}/comments?cursor=${cursor}&limit=${limit}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data ?? [],
      providesTags: (result, error, arg) => [
        { type: 'AnnotationComments', id: arg }
      ]
    }),
    createAnnotationComment: build.mutation({
      query: ({ id, content }) => ({
        url: `/annotations/${id}/comments`,
        method: 'POST',
        body: { content }
      }),
      transformResponse: (response) => response?.data ?? null
    }),
    updateAnnotationComment: build.mutation({
      query: ({ annotationId, commentId, content }) => ({
        url: `/annotations/${annotationId}/comments/${commentId}`,
        body: { content },
        method: 'PATCH'
      }),
      transformResponse: (response) => response?.data ?? null
    }),
    deleteAnnotationComment: build.mutation({
      query: ({ annotationId, commentId }) => ({
        url: `/annotations/${annotationId}/comments/${commentId}`,
        method: 'DELETE'
      })
    }),
    changeAnnotationGroup: build.mutation({
      query: ({ annotationId, groupColor }) => ({
        url: `/annotations/${annotationId}/group`,
        body: { groupColor },
        method: 'PATCH'
      }),
      transformResponse: (response) => response?.data ?? null
    }),
    getAnnotationTitles: build.query({
      query: (params) => ({ url: '/annotations/titles', params }),
      transformResponse: (response) => response.data
    }),
    getAnnotationColors: build.query({
      query: (params) => ({ url: '/annotations/colors', params }),
      transformResponse: (response) => response.data
    })
  })
});

export const {
  useCreateAnnotationMutation,
  useGetAllAnnotationsMutation,
  useEditAnnotationMutation,
  useDeleteAnnotationMutation,
  useGetAnnotationCommentsQuery,
  useCreateAnnotationCommentMutation,
  useUpdateAnnotationCommentMutation,
  useDeleteAnnotationCommentMutation,
  useChangeAnnotationGroupMutation,
  useGetAnnotationTitlesQuery,
  useGetAnnotationColorsQuery
} = annotationApi;

export const {
  endpoints: {
    createAnnotation,
    getAllAnnotations,
    editAnnotation,
    deleteAnnotation,
    getAnnotationComments
  }
} = annotationApi;
