import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { useGetTagsQuery } from 'services/tags';
import { debounce } from 'lodash';
const filter = createFilterOptions();

const AutocompleteTag = ({
  isCreateable = false,
  isMulti = false,
  onChange,
  value,
  placeholder = 'Enter project / study tags',
  label = 'Enter project / study tags',
  sx = {
    marginTop: '6px'
  }
}) => {
  const [tags, setTags] = useState([]);

  const [tagQuery, setTagQuery] = useState({
    limit: 100,
    page: 1,
    search: ''
  });

  const { data: fetchedTags } = useGetTagsQuery(tagQuery, {
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (fetchedTags) {
      setTags(fetchedTags);
    }
  }, [fetchedTags]);

  const debouncedSearch = debounce((search) => {
    setTagQuery({
      ...tagQuery,
      search
    });
  }, 200);

  return (
    <Autocomplete
      multiple={isMulti}
      disableCloseOnSelect={isMulti}
      id="tags-outlined"
      sx={sx}
      onBlur={() => {
        setTagQuery({
          ...tagQuery,
          search: ''
        });
      }}
      options={
        tags
          ? tags.map((tag) => ({
              name: tag.name,
              id: tag.id
            }))
          : []
      }
      freeSolo={isCreateable}
      onChange={(e, value) => {
        const convertTags = value.map((tag) => {
          if (typeof tag === 'string') {
            return {
              name: tag,
              _id: ''
            };
          }
          return tag;
        });
        // remove duplicate tags
        const uniqueTags = convertTags.filter(
          (tag, index, self) =>
            index === self.findIndex((t) => t.name === tag.name)
        );
        // remove empty tags
        const filteredTags = uniqueTags.filter((tag) => tag.name.trim() !== '');
        onChange && onChange(filteredTags);
      }}
      value={value || []}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const isExisting = [...value, ...options].some((option) => {
          if (params.inputValue) {
            return option.name === params.inputValue;
          }
          return false;
        });
        if (params.inputValue !== '' && isCreateable && !isExisting) {
          filtered.push({
            _id: '',
            name: params.inputValue,
            label: `Add "${params.inputValue}"`
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.name;
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderOption={(props, option) => (
        <li {...props}>{option.label || option.name}</li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          variant="outlined"
          placeholder={placeholder}
          label={label}
          onChange={(e) => {
            e.persist();
            debouncedSearch(e.target.value);
          }}
          size="small"
        />
      )}
    />
  );
};

export { AutocompleteTag };
