import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import UserManagement from '../UserManagement';
import { IOrganization } from 'types/organization';
import { useDashboardContext } from 'v2/contexts/DashboardContext';

interface Props {
  organization: IOrganization;
  onClose: () => void;
  onRefresh: () => void;
}

const OrganizationMemberDialog = ({ organization, onClose }: Props) => {
  const { users, onInviteSuccess, onRefresh } = useDashboardContext();

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 1200 }
      }}
    >
      <DialogTitle>{`Member of ${organization.name}`}</DialogTitle>
      <DialogContent>
        <UserManagement
          users={users.filter(
            (user) => user.organization._id === organization._id
          )}
          organizations={[organization]}
          onInviteSuccess={onInviteSuccess}
          onRefresh={onRefresh}
          isShowOrganization={false}
        />
      </DialogContent>
    </Dialog>
  );
};

export default OrganizationMemberDialog;
