import { forwardRef, useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  AppBar as MuiAppBar,
  Toolbar,
  MenuItem,
  Menu,
  Divider,
  Typography,
  useTheme,
  Box
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Logout from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch } from 'react-redux';
import { removeCredentials } from '../../stores/auth/authSlice';
import { removePaginationAndFilters } from '../../stores/slidebox/slideboxSlice';
import { ROLE_NAMES } from '../../constants';
import { resetState } from '../../stores/slidebox/slideboxSlice';
import NotificationMenu from './Notification/NotificationMenu';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../stores/auth/authSlice';
import UserGuide from './UserGuide';
import { useNavigate } from 'react-router-dom';

const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open, drawerWidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const AppBar = forwardRef(
  ({ open, drawerWidth, handleDrawerOpen, handleDrawerClose }, ref) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const dropdown = Boolean(anchorEl);
    const openProfileDropdown = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const logout = () => {
      dispatch(removePaginationAndFilters());
      dispatch(removeCredentials());
      dispatch(resetState());
      setAnchorEl(false);
    };

    const profile = () => {
      navigate('/profile');
    };

    return (
      <CustomAppBar
        ref={ref}
        drawerwidth={drawerWidth}
        position="fixed"
        open={open}
        sx={{ backgroundColor: '#F4F5FC', boxShadow: 'none' }}
        id="app-bar"
      >
        <IconButton
          onClick={() => handleDrawerOpen()}
          disableRipple={true}
          sx={{
            padding: '10px',
            top: '0',
            position: 'absolute',
            zIndex: 1
          }}
        >
          <MenuIcon
            sx={{
              fontSize: '50px',
              color: open ? theme.palette.hotPink.main : theme.palette.blue.main
            }}
          />
        </IconButton>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'relative',
            gap: 2
          }}
        >
          <UserGuide />
          <NotificationMenu />
          <IconButton onClick={openProfileDropdown}>
            <AccountCircleIcon
              sx={{
                fontSize: '50px',
                color: theme.palette.blue.main
              }}
            />
          </IconButton>
        </Toolbar>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={dropdown}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
              px: 2,
              pb: 2
            }}
          >
            <Typography variant="h6">{`${user.firstName} ${user.lastName}`}</Typography>
            <Typography variant="body2">{`Email: ${user.email}`}</Typography>
            <Typography
              variant="body2"
              paragraph={true}
              mb={0}
            >{`Organization: ${user.organization.name}`}</Typography>
            <Typography variant="body2" paragraph={true} mb={0}>{`Role: ${
              ROLE_NAMES[user.role]
            }`}</Typography>
          </Box>
          <Divider
            sx={{
              mb: 1
            }}
          />
          <MenuItem onClick={profile}>
            <ListItemIcon>
              <AccountBoxIcon fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
          <Divider />
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </CustomAppBar>
    );
  }
);
export default AppBar;
