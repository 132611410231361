import * as React from 'react';
import { createContext, useContext } from 'react';
import { MAIN_DRAWER_WIDTH } from '../theme';

const DrawerContext = createContext({});

const DrawerContextProvider = ({ children }) => {
  const drawerWidth = `${MAIN_DRAWER_WIDTH}px`;
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <DrawerContext.Provider
      value={{ open, handleDrawerOpen, handleDrawerClose, drawerWidth }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

const useDrawerContext = () => useContext(DrawerContext);

DrawerContext.displayName = 'DrawerContext';

export { DrawerContextProvider, useDrawerContext };
