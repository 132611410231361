import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import Alert from 'components/Alert';
import useToggleState from 'hooks/useToggleState';
import { useDeleteAnnotationCommentMutation } from 'services/annotation';
import { useSnackbar } from 'hooks/useSnackbar';
import { useCursorPaginationContext } from 'contexts/CursorPaginationContext';

const CommentDeleteButton = ({ commentId, annotationId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { deleteItem } = useCursorPaginationContext();

  const [deleteComment, { isLoading: isDeleting }] =
    useDeleteAnnotationCommentMutation();
  const [alertVisible, openDeleteAlert, closeDeleteAlert] =
    useToggleState(null);

  const handleDeleteComment = async () => {
    if (isDeleting || !commentId) return;
    closeDeleteAlert();
    try {
      await deleteComment({ annotationId, commentId });
      deleteItem?.({ _id: commentId });
    } catch (e) {
      console.warn(e);
      enqueueSnackbar(e.message || 'Something went wrong!', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <IconButton onClick={openDeleteAlert} size="small" color="buttonGray">
        <DeleteIcon fontSize="xs" />
      </IconButton>
      <Alert
        content="Are you sure to delete this comment?"
        onConfirm={handleDeleteComment}
        open={!!alertVisible}
        onDismiss={closeDeleteAlert}
        title="Delete comment"
      />
    </>
  );
};

export default CommentDeleteButton;
