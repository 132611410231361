import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
// Components

import { useUpdateSlideMutation } from 'services/slides';
// Redux
import {
  selectFoldersAndFiles,
  updatebyId
} from '../../../../../stores/slidebox/slideboxSlice';
import { FILE_TYPES } from 'constants';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useGetTagsQuery } from 'services/tags';
import { RenameSlideWarningDialog } from './RenameSlideWarningDialog';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EditSlideDialog = ({ data, projectSlides, onClose }) => {
  const [slideTags, setSlideTags] = useState([]);
  const [openRenamingModal, setOpenRenamingModal] = useState(false);
  const { refetch: getTags } = useGetTagsQuery(
    {
      entityModel: 'project',
      entityId: data.project._id || data.project
    },
    {
      refetchOnMountOrArgChange: false
    }
  );

  const { register, setValue, handleSubmit, watch, resetField } = useForm({
    defaultValues: {
      name: data.name,
      tags: data.tags || [],
      caseIdentifiers: data.caseIdentifiers || []
    }
  });

  useEffect(() => {
    const entityId =
      data.type === FILE_TYPES.FILE ? data.project._id || data.project : null;
    const entityModel = data.type === FILE_TYPES.FILE ? 'project' : null;
    if (entityId && entityModel) {
      getTags({ entityModel, entityId })
        .unwrap()
        .then((tagData) => {
          setSlideTags(tagData);
        })
        .catch((error) => {
          enqueueSnackbar(`${error} error fetching tags`, { variant: 'error' });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.project, data.type]);

  const handleClose = () => {
    onClose();
    resetField();
  };

  // hooks

  const [renameSlide, { isLoading: isRenamingSlide }] =
    useUpdateSlideMutation();

  const foldersAndFiles = useSelector(selectFoldersAndFiles);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateSuccess = (updatedData, foldersAndFiles) => {
    const findIndex = foldersAndFiles.findIndex(
      (f) => f._id === updatedData._id
    );
    if (findIndex !== -1) {
      const updated = JSON.parse(JSON.stringify(foldersAndFiles[findIndex]));
      updated.name = updatedData.name;
      updated.tags = updatedData.tags;
      updated.caseIdentifiers = updatedData.caseIdentifiers;
      dispatch(updatebyId(updated));
      enqueueSnackbar('Updated successfully.', {
        variant: 'success'
      });
      handleClose();
    }
  };

  const handleCloseRenamingModal = () => {
    setOpenRenamingModal(false);
  }

  const updateSlide = async (values) => {
    const newCaseIdentifiers =
      values.caseIdentifiers && values.caseIdentifiers.length > 0
        ? values.caseIdentifiers.map((item) => ({
          blockId: item.blockId,
          patientId: item.patientId,
          recuts: item.recuts || '',
          treatmentGroup: item.treatmentGroup
        }))
        : [];
    const newTags =
      values.tags && values.tags.length > 0
        ? values.tags.map((item) => ({ name:item.name }))
        : [];

    try {
      const projectSlideNames = projectSlides.filter((slide) => slide._id !== data._id).map((slide) => slide.name);

      if (projectSlideNames.includes(values.name)) {
        setOpenRenamingModal(true);
        return;
      }
      const { data: response } = await renameSlide({
        id: data._id,
        name: values.name,
        project: data.project,
        caseIdentifiers: newCaseIdentifiers,
        tags: newTags
      }).unwrap();
      handleUpdateSuccess(response, foldersAndFiles);
    } catch (err) {
      if (err?.data?.error?.message) {
        enqueueSnackbar(err.data.error.message, { variant: 'error' });
      } else {
        enqueueSnackbar('Slide update failed', { variant: 'error' });
      }
    }
  };

  return (
    <>
      <Dialog open={true} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '18px',
            paddingBottom: '0px'
          }}
        >
          Update Slide
        </DialogTitle>
        <form onSubmit={handleSubmit(updateSlide)}>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px'
            }}
          >
            <TextField
              required
              sx={{
                '& label.MuiInputLabel-shrink': {
                  color: '#4A4A4A',
                  fontWeight: '600'
                }
              }}
              size="small"
              label="Slide Name"
              fullWidth
              id="name"
              name="name"
              {...register('name')}
            />
            <Autocomplete
              multiple
              disableCloseOnSelect
              id="tags-outlined"
              size="small"
              options={slideTags}
              value={watch('tags')}
              onChange={(event, newValue) => {
                setValue && setValue('tags', newValue);
              }}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.name;
              }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              renderOption={(props, option) => <li {...props}>{option.name}</li>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    '& label.MuiInputLabel-shrink': {
                      color: '#4A4A4A',
                      fontWeight: '600'
                    }
                  }}
                  label="Slide Tags"
                />
              )}
            />
            <Autocomplete
              multiple
              id="case-outlined"
              size="small"
              disableCloseOnSelect
              options={data.parentCaseIdentifiers || []}
              onChange={(event, value) => {
                setValue && setValue('caseIdentifiers', value);
              }}
              value={watch('caseIdentifiers')}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return `Patient Id: ${option.patientId}`;
              }}
              isOptionEqualToValue={(option, value) =>
                option.patientId === value.patientId
              }
              renderOption={(props, option) => <li {...props}>{`Patient Id: ${option.patientId}`}</li>}
              // renderOption={(props, option, { selected }) => (
              //   <li {...props}>
              //     <Checkbox
              //       icon={icon}
              //       checkedIcon={checkedIcon}
              //       checked={selected}
              //     />
              //     Patient Id: {option.patientId}
              //   </li>
              // )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    '& label.MuiInputLabel-shrink': {
                      color: '#4A4A4A',
                      fontWeight: '600'
                    }
                  }}
                  label="Case Identifiers"
                />
              )}
            />
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <Button
              variant="contained"
              color="gray"
              component="label"
              disabled={isRenamingSlide}
              onClick={handleClose}
            >
              Cancel
            </Button>

            <LoadingButton
              loading={isRenamingSlide}
              disabled={
                !watch('name') || !watch('name').trim() || isRenamingSlide
              }
              variant="contained"
              disableElevation
              type="submit"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <RenameSlideWarningDialog open={openRenamingModal} onClose={handleCloseRenamingModal} />
    </>
  );
};
export { EditSlideDialog };
