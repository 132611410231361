import * as yup from 'yup';

const validationSchema = yup
    .object({
        password: yup
            .string()
            .min(8)
            .test({
                message: 'Password must contain at least one lowercase letter',
                test: (value) => value.search(/[a-z]/) >= 0
            })
            .test({
                message: 'Password must contain at least one uppercase letter',
                test: (value) => value.search(/[A-Z]/) >= 0
            })
            .test({
                message: 'Password must contain at least one number',
                test: (value) => value.search(/[0-9]/) >= 0
            })
            .test({
                message: 'Password must contain at least one special character',
                test: (value) =>
                    value.search(/[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]/) >= 0
            })
            .required()
            .label('Password'),
        repeatPassword: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
    })
    .required();

export { validationSchema };