import { Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";

const VerificationDialog = ({handleClose, open}) => {
return (
  <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
    <DialogTitle sx={{ textAlign: 'center' }}>
      <Typography color="primary" sx={{ fontWeight: '600', fontSize: '20px' }}>
        Verification Success
      </Typography>
      <Typography mt={2}>
        You have successfully verified account
        <br />
      </Typography>
    </DialogTitle>
    <DialogContent sx={{ textAlign: 'center' }}>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleClose}
        sx={{ mt: 2 }}
      >
        Start Login
      </Button>
    </DialogContent>
  </Dialog>
)
}
export default VerificationDialog;