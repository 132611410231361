import { SVG_NAMESPACE } from '@recogito/annotorious/src/util/SVG';

/** Shorthand to apply the given (x, y, width, height) to the SVG shape **/
const setXYWH = (shape, x, y, width, height) => {
  shape.setAttribute('x', x);
  shape.setAttribute('y', y);
  shape.setAttribute('width', width);
  shape.setAttribute('height', height);
};
/**
 * Draws an SVG rectangle, either from an annotation, or an
 * (x, y, width, height)-tuple.
 */
export const drawRectangle = (x, y, width, height, color) => {
  const g = document.createElementNS(SVG_NAMESPACE, 'g');
  const innerRect = document.createElementNS(SVG_NAMESPACE, 'rect');
  const outerRect = document.createElementNS(SVG_NAMESPACE, 'rect');

  innerRect.setAttribute('class', 'a9s-inner');
  if (color) {
    const color = localStorage.getItem('color')
    const paseColor = JSON.parse(color)
    innerRect.style.stroke = paseColor;
  }
  setXYWH(innerRect, x, y, width, height);

  outerRect.setAttribute('class', 'a9s-outer');
  setXYWH(outerRect, x, y, width, height);

  g.appendChild(outerRect);
  g.appendChild(innerRect);

  return g;
};

export const setRectangleSize = (g, x, y, width, height) => {
  const innerRect = g.querySelector('.a9s-inner');
  const outerRect = g.querySelector('.a9s-outer');

  setXYWH(innerRect, x, y, width, height);
  setXYWH(outerRect, x, y, width, height);
};

export const getRectangleSize = (g) => {
  const outerRect = g.querySelector('.a9s-outer');

  const x = parseFloat(outerRect.getAttribute('x'));
  const y = parseFloat(outerRect.getAttribute('y'));
  const width = parseFloat(outerRect.getAttribute('width'));
  const height = parseFloat(outerRect.getAttribute('height'));

  return { x, y, width, height };
};
