import { DialogProject } from 'features/Project/DialogProject';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';
import React from 'react';
import { EditSlideDialog } from './FileExplorer/components/ActionMenuComponents/EditSlideDialog';

const SlideboxDialog = () => {
  const { dialogData, projectSlides, setDialogData } = useSlideboxContext();

  const handleClose = () => {
    setDialogData('', null);
  };
  if (dialogData && dialogData.type === 'project')
    return <DialogProject data={dialogData.data} onClose={handleClose} />;

  if (dialogData && dialogData.type === 'slide')
    return <EditSlideDialog data={dialogData.data} projectSlides={projectSlides} onClose={handleClose} />;

  return null;
};

export { SlideboxDialog };
