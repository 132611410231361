import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showLoading: false
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setState: (state, {payload}) => {
      const {key, value} = payload;
      state[key] = value;
    },
  }
});

export const { setState } = slice.actions;

export default slice.reducer;

export const selectShowLoading = (state) => state.app.showLoading;
