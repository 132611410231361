export const AnnoFromWidget = () => {
  const divElement = document.createElement('div');
  const okButton = document.querySelector('.r6o-btn:last-child');

  window.setTimeout(() => {
    const inputsRequired = document.querySelectorAll('.widget-input[required]');

    const checkRequired = () => {
      let check = true;
      if (inputsRequired.length === 0) return check;
      inputsRequired.forEach((input) => {
        if (!input.value) {
          check = false;
        }
      });
      return check;
    };
    if (checkRequired()) {
      okButton.disabled = false;
    } else {
      okButton.disabled = true;
    }

    // check all change
    inputsRequired.forEach((input) => {
      input.addEventListener('input', () => {
        if (checkRequired()) {
          okButton.disabled = false;
        } else {
          okButton.disabled = true;
        }
      });
    });

    // auto focus
    const inputFocus = document.querySelector('.widget-input[autofocus]');

    if (inputFocus) {
      inputFocus.focus();
    }
  }, 10);

  return divElement;
};
