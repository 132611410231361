import Accordion from 'components/Accordion';
import { FormContainer, useFormState } from 'react-hook-form-mui';
import { Box, Button } from '@mui/material';
import CaseIdentifiersInput from 'components/CaseIdentifiersInput';
import { useContext, useEffect, useState } from 'react';
import { SlideViewerContext } from 'hooks/useSlideViewerContext';
import { useUpdateSlideMutation } from 'services/slides';
import { useGetCaseIdentifiersQuery } from 'services/caseIdentifier';
import { useSnackbar } from 'hooks/useSnackbar';
import { isEqual, uniqWith } from 'lodash';

const SlideCaseIdentifiers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useContext(SlideViewerContext);
  const [updateSlide, { isLoading }] = useUpdateSlideMutation();
  const [currentCaseIdentifiers, setCurrentCaseIdentifiers] = useState([]);
  const [slideCaseIdentifiers, setSlideCaseIdentifiers] = useState([]);
  const { refetch: getCaseIdentifiers } = useGetCaseIdentifiersQuery(
    {
      entityModel: 'project',
      entityId: state.slide?.project
    },
    {
      refetchOnMountOrArgChange: false
    }
  );
  const submitCaseIdentifiers = async (data) => {
    if (isLoading || !state.slide || !data.caseIdentifiers) return;
    try {
      const updatedSlide = await updateSlide({
        id: state.slide._id,
        caseIdentifiers: data.caseIdentifiers
      }).unwrap();

      dispatch({
        type: 'SET_STATE',
        key: 'slide',
        value: {
          ...state.slide,
          caseIdentifiers: updatedSlide.data.caseIdentifiers
        }
      });
      enqueueSnackbar('Case Identifiers saved successfully', {
        variant: 'success'
      });
    } catch (e) {
      console.warn(e);
      enqueueSnackbar(e.toString(), {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    const caseIdentifiers = state.slide?.caseIdentifiers || [];
    const uniqueCaseIdentifiers = uniqWith(caseIdentifiers, (a, b) =>
      isEqual(a, b)
    );
    setCurrentCaseIdentifiers(uniqueCaseIdentifiers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.slide?.caseIdentifiers]);

  useEffect(() => {
    if (state.slide?.project) {
      getCaseIdentifiers()
        .unwrap()
        .then((caseIdentifierData) => {
          setSlideCaseIdentifiers(caseIdentifierData);
        })
        .catch((error) => {
          enqueueSnackbar(`${error} error fetching caseIdentifiers`, {
            variant: 'error'
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.slide?.project]);

  return (
    <Accordion unmountOnExit heading="Case Identifiers">
      <Box mt={1} mb={1} p={2} spacing={2}>
        <FormContainer
          onSuccess={submitCaseIdentifiers}
          values={{ caseIdentifiers: currentCaseIdentifiers }}
        >
          <CaseIdentifiersInput
            name="caseIdentifiers"
            options={slideCaseIdentifiers}
            placeholder="Enter slide caseIdentifiers..."
          />
          <Box mt={2}>
            <CaseIdentifierSubmitButton />
          </Box>
        </FormContainer>
      </Box>
    </Accordion>
  );
};

const CaseIdentifierSubmitButton = () => {
  const { isValid, isSubmitting, isDirty } = useFormState();

  return (
    <Button
      type="submit"
      size="small"
      variant="contained"
      disabled={isSubmitting || !isDirty || !isValid}
    >
      Save
    </Button>
  );
};

export default SlideCaseIdentifiers;
