import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { useGetProjectNamesQuery } from 'services/projects';
import { debounce } from 'lodash';
const filter = createFilterOptions();

export const AutocompleteProjectName = ({
  isCreateable = false,
  isMulti = false,
  onChange,
  value,
  label = 'Project',
  placeholder = 'Project',
  sx = {}
}) => {
  const [projects, setProjects] = useState([]);

  const [projectQuery, setProjectQuery] = useState({
    limit: 100,
    page: 1,
    search: ''
  });

  const { data: fetchedProjects } = useGetProjectNamesQuery(projectQuery, {
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (fetchedProjects) {
      setProjects(
        fetchedProjects.map((project) => ({
          name: project,
          _id: project
        }))
      );
    }
  }, [fetchedProjects]);

  const debouncedSearch = debounce((search) => {
    setProjectQuery({
      ...projectQuery,
      search
    });
  }, 200);

  return (
    <Autocomplete
      multiple={isMulti}
      disableCloseOnSelect={isMulti}
      id="projects-outlined"
      sx={sx}
      onBlur={() => {
        setProjectQuery({
          ...projectQuery,
          search: ''
        });
      }}
      options={
        projects
          ? projects.map((project) => ({
              name: project.name,
              _id: project._id
            }))
          : []
      }
      freeSolo={isCreateable}
      onChange={(e, value) => {
        const convertProjects = value.map((project) => {
          if (typeof project === 'string') {
            return {
              name: project,
              _id: ''
            };
          }
          return project;
        });
        // remove duplicate projects
        const uniqueProjects = convertProjects.filter(
          (project, index, self) =>
            index === self.findIndex((t) => t.name === project.name)
        );
        // remove empty projects
        const filteredProjects = uniqueProjects.filter(
          (project) => project.name.trim() !== ''
        );
        onChange && onChange(filteredProjects);
      }}
      value={value || []}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const existingList = value ? [...value, ...options] : options;
        const isExisting = existingList.some((option) => {
          if (params.inputValue) {
            return option.name === params.inputValue;
          }
          return false;
        });
        if (params.inputValue !== '' && isCreateable && !isExisting) {
          filtered.push({
            _id: '',
            name: params.inputValue,
            label: `Add "${params.inputValue}"`
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.name;
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderOption={(props, option) => (
        <li {...props}>{option.label || option.name}</li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          variant="outlined"
          placeholder={placeholder}
          label={label}
          value={projectQuery.search}
          onChange={(e) => {
            e.persist();
            debouncedSearch(e.target.value);
          }}
          size="small"
        />
      )}
    />
  );
};
