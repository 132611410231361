import * as Plot from '@observablehq/plot';
import { useEffect, useRef, useContext } from 'react';

import { AnnotationContext } from './index';

export default function BeeSwarmGraph({
   biomarkerData,
   biomarkerName,
   compBiomarkerData,
   compAnnoColor
}) {
  const containerRef = useRef();
  const color = useContext(AnnotationContext);
  useEffect(() => {
    if (biomarkerData === undefined) return;
    const plot = Plot.plot({
      height: 500,
      x: { line: true },
      marks: [
        Plot.dotX(
          biomarkerData,
          Plot.dodgeY({
            x: biomarkerName,
            sort: biomarkerName,
            title: 'Description',
            fill: color
          })
        ),
        Plot.dotX(
          compBiomarkerData,
          Plot.dodgeY({
            x: biomarkerName,
            sort: biomarkerName,
            title: 'Description',
            fill: compAnnoColor
          })
        )
      ]
    });
    containerRef.current.append(plot);
    return () => plot.remove();
  }, [biomarkerData]);
  return <div ref={containerRef} />;
}
