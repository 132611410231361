import { useEffect } from 'react';

const STRING_DB_API = "https://string-db.org/api/json";
const STRING_ID_URL = `${ STRING_DB_API }/get_string_ids?identifiers=`;


const StringNetwork = ({ identifier, style, setIsFetching }) => { 
    
     const get_string_ids = async ( protein ) => {
        const response = await fetch( STRING_ID_URL + protein );
        if ( !response.ok ) {
            throw new Error( 'Failed to fetch string ids' );
        }
        const json = await response.json();
        return json;
    };
    
    useEffect( () => {
        const fetchData = async () => {
            try {
                const res = await get_string_ids( identifier );
                const { stringId } = res[0]
                if (window.getSTRING) {
                  window.getSTRING('https://string-db.org', {
                    // species: '9606',
                    identifiers: [stringId],
                    network_flavor: 'confidence'
                  });
                } else {
                  console.error('getSTRING is not defined');
                }
              setTimeout( () => {
                setIsFetching( false );
              }, 1000 );
              console.log("End fetching data");
            } catch ( err ) {
                console.error( 'Error fetching data:', err );
            }
        };
        fetchData();
    }, [ identifier ] );

  return <div id="stringEmbedded" style={{ height: 'auto', ...style }} className='string-network' />;
};

export default StringNetwork;