import { webSocketType } from 'contexts/WebSocketContext';

export const isAccessGroup = (lastEvent, userId, type = null) => {
  if (!lastEvent || !lastEvent.data || !lastEvent.type) return false;
  if (type && lastEvent?.type !== type) return false;
  const membersIds = lastEvent.data?.members?.map(
    (member) => member._id || member.id
  );
  return (
    membersIds?.includes(userId) ||
    lastEvent.data?.managedBy?._id === userId ||
    lastEvent.data?.managedBy?.id === userId
  );
};

export const isGPTResponse = (lastEvent, userId) => {
  if (!lastEvent || !lastEvent.data || !lastEvent.type) return false;
  return (
    lastEvent.type === webSocketType.CHATGPT_MESSAGE &&
    lastEvent.data?.userId === userId
  );
};
