import _ from 'lodash';
import { Box, Button, Chip, Stack, StackProps } from '@mui/material';
import {
  IOrganizationTableFilterValue,
  IOrganizationTableFilters
} from 'types/organization';
import BlockTableFilter from 'v2/components/block-table-filter/block-table-filter';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = StackProps & {
  filters: IOrganizationTableFilters;
  onFilters: (name: string, value: IOrganizationTableFilterValue) => void;
  //
  onResetFilters: VoidFunction;
  //
  results: number;
};

const OrganizationTableFiltersResult = ({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}: Props) => {
  const handleRemoveStatus = () => {
    onFilters('status', '');
  };

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          {' '}
          results found
        </Box>
      </Box>

      <Stack
        flexGrow={1}
        spacing={1}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
      >
        {filters.name && (
          <BlockTableFilter label="Name:">
            <Chip
              size="small"
              label={filters.name}
              onDelete={() => onFilters('name', '')}
            />
          </BlockTableFilter>
        )}

        {filters.status && (
          <BlockTableFilter label="Status:">
            <Chip
              label={_.capitalize(filters.status)}
              size="small"
              onDelete={() => handleRemoveStatus()}
            />
          </BlockTableFilter>
        )}

        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<DeleteIcon />}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  );
};

export default OrganizationTableFiltersResult;
