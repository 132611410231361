import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const InfoAlert = ({ title, content, open, onConfirm }) => {
  return (
    <Dialog
      open={!!open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-content"
    >
      {title && (
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
      )}

      <DialogContent>
        <DialogContentText id="alert-dialog-content">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} autoFocus color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InfoAlert