import { api } from './api';

export const biomarkerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBiomarkerByAnnotationId: build.mutation({
      query: (id) => ({
        url: `/biomarkers/${id}`,
        method: 'GET'
      }),
      transformResponse: (response) => response.data
    }),
    getBiomarkerAccessions: build.query({
      query: (params) => ({ url: '/biomarkers/accessions', params }),
      transformResponse: (response) => response.data
    }),
    getBiomarkerCoverages: build.query({
      query: (params) => ({ url: '/biomarkers/coverages', params })
    }),
    checkCapId: build.mutation({
      query: ({ capId }) => ({
        url: `/biomarkers/check-cap-id/${capId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response.data
    })
  })
});

export const {
  useGetBiomarkerByAnnotationIdMutation,
  useCheckCapIdMutation,
  useGetBiomarkerAccessionsQuery,
  useGetBiomarkerCoveragesQuery
} = biomarkerApi;
