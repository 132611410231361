import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { removeCredentials } from "../stores/auth/authSlice";
import { resetState } from "../stores/slidebox/slideboxSlice";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_API}api/v1`,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", token);
    }
    const organization = getState().auth.organization;
    if (organization) {
      headers.set("organization", organization._id);
    }
    return headers;
  },
});
const baseQueryWithAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  // Response interceptor for 401
  if (result.error && result.error.status === 401) {
    api.dispatch(removeCredentials())
    api.dispatch(resetState())
  }
  return result
}

const baseQueryWithRetry = retry(baseQueryWithAuth, { maxRetries: 0 });

export const api = createApi({
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true
})
