import { Popover, Typography } from '@mui/material'
import ToggleButtonMUI from "@mui/material/ToggleButton";
import { useState } from 'react'

const ToggleButton = ({ children, ariaLabel, ...otherProps }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  return (
    <>
      <ToggleButtonMUI
        {...otherProps}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </ToggleButtonMUI>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{ariaLabel}</Typography>
      </Popover>
    </>
  )
}

export default ToggleButton
