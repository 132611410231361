import { Box, CircularProgress, Container } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import { useGetUserQuery } from 'services/user';
import EmptyContent from 'v2/components/empty-content/empty-content';
import UserNewEditForm from '../Dashboard/UserDetail/UserNewEditForm';
import CustomBreadcrumbs from 'v2/components/custom-breadcrumbs/custom-breadcrumbs';

const Profile = () => {
  const { user } = useAuth();

  const { data, refetch, isLoading } = useGetUserQuery(user.id, {
    refetchOnMountOrArgChange: false
  });

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (!data) return <EmptyContent filled title="No Data" sx={{ py: 10 }} />;

  return (
    <Container
      sx={{
        py: 2
      }}
      maxWidth={false}
    >
      <CustomBreadcrumbs
        heading="Profile"
        links={[
          {
            name: 'Profile'
          },
          { name: `${data.firstName} ${data.lastName}` }
        ]}
        sx={{
          mb: { xs: 3, md: 5 }
        }}
      />
      <UserNewEditForm
        currentUser={data}
        onCallback={() => {
          refetch();
        }}
        onDeleted={() => {
          refetch();
        }}
      />
    </Container>
  );
};

export default Profile;
