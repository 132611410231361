import { Box, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import CustomTable from 'components/Table';
import React, { useContext } from 'react';
import { uuidv4 } from '@firebase/util';
import { AnalysisContext } from 'hooks/useAnalysisContext';

export const AnalysisResult = () => {
  const theme = useTheme();
  const { analysis, isLoading } = useContext(AnalysisContext);

  const biomarkers = analysis?.data || [];

  let headCells = [];
  if (biomarkers?.length > 0) {
    const keys = Object.keys(biomarkers[0]);
    keys.forEach((key) => {
      const headCell = {
        id: key,
        label: key,
        sortable: true
      };
      headCells.push(headCell);
    });
  }

  return (
    biomarkers && (
      <>
        <Typography
          sx={{
            marginLeft: 2,
            color: '#a341ff',
            fontWeight: '500'
          }}
          variant="h5"
          gutterBottom
          component="div"
        >
          Results
        </Typography>
        <Box
          sx={{
            p: 2,
            width: '100%',
            backgroundColor: theme.palette.background.paper
          }}
        >
          <CustomTable
            rows={biomarkers}
            headCells={headCells}
            isLoading={isLoading}
            pagingMarginRight="48px"
          >
            {(head, items) =>
              items.map((biomarker) => (
                <TableRow hover={true} key={uuidv4()}>
                  {Object.keys(biomarker).map((attr) => (
                    <TableCell align="left" key={uuidv4()}>
                      <Box
                        sx={{
                          textAlign: 'left',
                          [theme.breakpoints.down('sm')]: {
                            maxWidth: '60px'
                          }
                        }}
                      >
                        {biomarker[attr]}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            }
          </CustomTable>
        </Box>
      </>
    )
  );
};
