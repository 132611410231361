import Accordion from 'components/Accordion';
import { FormContainer, useFormState } from 'react-hook-form-mui';
import { Box, Button } from '@mui/material';
import TagsInput from 'components/TagsInput';
import { useContext, useEffect, useState } from 'react';
import { SlideViewerContext } from 'hooks/useSlideViewerContext';
import { useUpdateSlideMutation } from 'services/slides';
import { useGetTagsQuery } from 'services/tags';
import { useSnackbar } from 'hooks/useSnackbar';
import { isEqual, uniqWith } from 'lodash';

const SlideTags = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useContext(SlideViewerContext);
  const [updateSlide, { isLoading }] = useUpdateSlideMutation();
  const [currentTags, setCurrentTags] = useState([]);
  const [slideTags, setSlideTags] = useState([]);
  const { refetch: getTags } = useGetTagsQuery(
    {
      entityModel: 'project',
      entityId: state.slide?.project
    },
    {
      refetchOnMountOrArgChange: false
    }
  );
  const submitTags = async (data) => {
    if (isLoading || !state.slide || !data.tags) return;
    try {
      const updatedSlide = await updateSlide({
        id: state.slide._id,
        tags: data.tags
      }).unwrap();

      const newTags = updatedSlide.data?.tags
        ? updatedSlide.data.tags.map((_tag) => {
            const tag = slideTags.find(
              (tag) => tag.id === _tag.id
            );
            return tag ? tag : null;
          })
        : [];

      dispatch({
        type: 'SET_STATE',
        key: 'slide',
        value: {
          ...state.slide,
          tags: newTags.filter((tag) => tag !== null)
        }
      });
      enqueueSnackbar('Tags saved successfully', {
        variant: 'success'
      });
    } catch (e) {
      console.warn(e);
      enqueueSnackbar(e.toString(), {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    const tags = state.slide?.tags || [];
    const uniqueTags = uniqWith(tags, (a, b) => isEqual(a, b));
    setCurrentTags(uniqueTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.slide?.tags]);

  useEffect(() => {
    if (state.slide?.project) {
      getTags()
        .unwrap()
        .then((tagData) => {
          setSlideTags(tagData);
        })
        .catch((error) => {
          enqueueSnackbar(`${error} error fetching tags`, { variant: 'error' });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.slide?.project]);

  return (
    <Accordion unmountOnExit heading="Tags">
      <Box mt={1} mb={1} p={2} spacing={2}>
        <FormContainer onSuccess={submitTags} values={{ tags: currentTags }}>
          <TagsInput
            name="tags"
            options={slideTags}
            placeholder="Enter slide tags..."
          />
          <Box mt={2}>
            <TagSubmitButton />
          </Box>
        </FormContainer>
      </Box>
    </Accordion>
  );
};

const TagSubmitButton = () => {
  const { isValid, isSubmitting, isDirty } = useFormState();

  return (
    <Button
      type="submit"
      size="small"
      variant="contained"
      disabled={isSubmitting || !isDirty || !isValid}
    >
      Save
    </Button>
  );
};

export default SlideTags;
