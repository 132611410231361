import React from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { getPublicImage } from '../../utils';
import { useTheme } from '@emotion/react';

const Verify = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  if (!email) {
    return <Navigate to="/login" />;
  }

  return (
    <Grid
      item
      xs
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
      backgroundColor="#F4F5FC"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      }}
    >
      <Box
        sx={{
          background: '#ffff',
          py: '24px',
          px: '24px',
          borderRadius: '30px',
          boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
          zIndex: 1,
          width: '95%',
          maxWidth: '500px',
          [theme.breakpoints.up('md')]: {
            maxWidth: '400px'
          },
          [theme.breakpoints.up('lg')]: {
            maxWidth: '500px'
          },
          [theme.breakpoints.up('xl')]: {
            maxWidth: '882px'
          }
        }}
      >
        <Box
          sx={{
            pb: '28px',
            mb: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              cursor: 'pointer',
              [theme.breakpoints.up('xs')]: {
                width: '100px'
              },
              [theme.breakpoints.up('lg')]: {
                width: '120px'
              }
            }}
          >
            <img alt="logo" width="100%" src={getPublicImage('logo.png')} />
          </Box>

          <Box sx={{ color: 'blue.main', ml: '34px' }}>
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              Targeted
              <br />
              Bioscience
            </Typography>
          </Box>
        </Box>
        <Typography variant="h5" gutterBottom paddingBottom={'30px'} color={theme.palette.primary.main} sx={{fontWeight: 'bold'}}>
          Please verify your email
        </Typography>
        <Typography variant="subtitle1" gutterBottom paddingBottom={'30px'}>
          You're almost there! We sent an email to{' '}
          <Typography fontWeight={'bold'}>{email}</Typography>
        </Typography>
        <Typography variant="subtitle1" gutterBottom paddingBottom={'30px'}>
          Just click on the link in that email to complete your sign up. If you
          don't see it, you may need to check your spam folder.
        </Typography>
        <Paper
          sx={{
            width: '100%',
            mb: '25px',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            paddingBottom: '20px'
          }}
        >
          <Button
            sx={{
              alignSelf: 'center',
              marginLeft: '25px',
              height: '100%',
              width: '135px'
            }}
            variant="contained"
            component="label"
            disableElevation
            onClick={() => {
              navigate({
                pathname: '/login'
              });
            }}
          >
            Sign in
          </Button>
        </Paper>
      </Box>
    </Grid>
  );
};

export default Verify;
