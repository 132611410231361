import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { useGetSlideNamesQuery } from 'services/slides';
import { debounce } from 'lodash';
const filter = createFilterOptions();

export const AutocompleteSlideName = ({
  isCreateable = false,
  isMulti = false,
  onChange,
  value,
  label = 'Slide',
  placeholder = 'Slide',
  sx = {}
}) => {
  const [slides, setSlides] = useState([]);

  const [slideQuery, setSlideQuery] = useState({
    limit: 100,
    page: 1,
    search: ''
  });

  const { data: fetchedSlides } = useGetSlideNamesQuery(slideQuery, {
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (fetchedSlides) {
      setSlides(fetchedSlides.map((slide) => ({ name: slide, _id: slide })));
    }
  }, [fetchedSlides]);

  const debouncedSearch = debounce((search) => {
    setSlideQuery({
      ...slideQuery,
      search
    });
  }, 200);

  return (
    <Autocomplete
      multiple={isMulti}
      disableCloseOnSelect={isMulti}
      id="slides-outlined"
      sx={sx}
      onBlur={() => {
        setSlideQuery({
          ...slideQuery,
          search: ''
        });
      }}
      options={
        slides
          ? slides.map((slide) => ({
              name: slide.name,
              _id: slide._id
            }))
          : []
      }
      freeSolo={isCreateable}
      onChange={(e, value) => {
        const convertSlides = value.map((slide) => {
          if (typeof slide === 'string') {
            return {
              name: slide,
              _id: ''
            };
          }
          return slide;
        });
        // remove duplicate slides
        const uniqueSlides = convertSlides.filter(
          (slide, index, self) =>
            index === self.findIndex((t) => t.name === slide.name)
        );
        // remove empty slides
        const filteredSlides = uniqueSlides.filter(
          (slide) => slide.name.trim() !== ''
        );
        onChange && onChange(filteredSlides);
      }}
      value={value || []}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const existingList = value ? [...value, ...options] : options;
        const isExisting = existingList.some((option) => {
          if (params.inputValue) {
            return option.name === params.inputValue;
          }
          return false;
        });
        if (params.inputValue !== '' && isCreateable && !isExisting) {
          filtered.push({
            _id: '',
            name: params.inputValue,
            label: `Add "${params.inputValue}"`
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.name;
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderOption={(props, option) => (
        <li {...props}>{option.label || option.name}</li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          variant="outlined"
          placeholder={placeholder}
          label={label}
          value={slideQuery.search}
          onChange={(e) => {
            e.persist();
            debouncedSearch(e.target.value);
          }}
          size="small"
        />
      )}
    />
  );
};
