import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'v2/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from 'v2/components/hook-form';
import { EOrganizationStatus, IOrganization } from 'types/organization';
import {
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation
} from 'services/organizations';
import { ORGANIZATION_STATUS_OPTIONS } from 'v2/utils/organization';
import { MenuItem } from '@mui/material';
import { handleBackendError } from 'v2/utils/handle-backend-error';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onCallback?: VoidFunction;
  currentOrg?: IOrganization;
};

export default function OrganizationInviteForm({
  currentOrg,
  onCallback,
  open,
  onClose
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [createOrganization, { isLoading }] = useCreateOrganizationMutation();
  const [updateOrganization, { isLoading: isLoadingUpdate }] =
    useUpdateOrganizationMutation();

  const NewOrgSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Email must be a valid email address'),
    description: Yup.string(),
    address: Yup.string(),
    phone: Yup.string(),
    collaboratorLimit: Yup.number(),
    status: Yup.mixed<EOrganizationStatus>().oneOf(
      Object.values(EOrganizationStatus)
    )
  });

  const defaultValues = useMemo(
    () => ({
      name: currentOrg?.name || '',
      email: currentOrg?.email || '',
      address: currentOrg?.address || '',
      phone: currentOrg?.phone || '',
      description: currentOrg?.description || '',
      status: currentOrg?.isActive
        ? EOrganizationStatus.ACTIVE
        : EOrganizationStatus.INACTIVE,
      collaboratorLimit: currentOrg?.collaboratorLimit || 0
    }),
    [currentOrg]
  );

  const methods = useForm({
    resolver: yupResolver(NewOrgSchema),
    defaultValues
  });

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { status, ...rest } = data;
    try {
      if (currentOrg?._id) {
        await updateOrganization({
          ...rest,
          isActive: status === EOrganizationStatus.ACTIVE ? true : false,
          id: currentOrg._id
        }).unwrap();
        enqueueSnackbar('Updated organization successfully', {
          variant: 'success'
        });
      } else {
        await createOrganization({
          ...rest,
          isActive: status === EOrganizationStatus.ACTIVE ? true : false
        }).unwrap();
        enqueueSnackbar('Create organization successfully', {
          variant: 'success'
        });
      }
      onCallback && onCallback();
      onClose();
    } catch (error) {
      enqueueSnackbar(
        handleBackendError(error).message || 'Something went wrong',
        {
          variant: 'error'
        }
      );
      console.error(error);
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 }
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>
          {currentOrg?._id ? 'Edit Organization' : 'New Organization'}
        </DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            mt={1}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)'
            }}
          >
            <RHFTextField name="name" label="Full Name" />
            <RHFTextField name="email" label="Email Address" />
            <RHFTextField name="address" label="Address" />
            <RHFTextField name="phone" label="Phone Number" />
            <RHFTextField
              name="collaboratorLimit"
              label="Collaborator Limit"
              type="number"
            />
            <RHFSelect name="status" label="Status">
              {ORGANIZATION_STATUS_OPTIONS.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </RHFSelect>
          </Box>
          <Box
            sx={{
              mt: 3
            }}
          >
            <RHFTextField
              name="description"
              label="Description"
              multiline
              rows={4}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting || isLoading || isLoadingUpdate}
          >
            {currentOrg?._id ? 'Save' : 'Create'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
