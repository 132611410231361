import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const UserGuide = () => {
  const [openDialog, setOpenDialog] = useState(false);
  let userGuideLink = process.env.REACT_APP_USER_GUIDE || '';

  let fileType = 'PDF'; //'PDF' || 'Google Doc';

  // check type of file with link
  if (userGuideLink.match(/docs.google.com/g)) {
    fileType = 'GoogleDocs';
  } else if (userGuideLink.match(/pdf/g)) {
    fileType = 'PDF';
  } else {
    fileType = '';
  }

  const theme = useTheme();
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleOpenMenu = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  if (!userGuideLink) return null;

  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <InfoIcon
          sx={{
            fontSize: '42px',
            color: theme.palette.text.textGray1,
            cursor: 'pointer'
          }}
        />
      </IconButton>

      <Dialog
        open={openDialog}
        scroll="paper"
        maxWidth={fileType === 'GoogleDocs' ? 'lg' : 'md'}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            alignItems: 'flex-start'
          },
          '& .MuiAppBar-root': {
            backgroundColor: '#fff',
            color: '#000'
          }
        }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              User Guide
            </Typography>
            <Button
              autoFocus
              color="inherit"
              href={userGuideLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OpenInNewIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          {fileType === 'GoogleDocs' ? (
            <Box
              sx={{
                width: '100%',
                maxHeight: '600px'
              }}
            >
              <iframe
                title="user guide"
                width="860"
                height="600"
                src={userGuideLink}
              />
            </Box>
          ) : fileType === 'PDF' ? (
            <Document
              file={userGuideLink}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          ) : null}
        </DialogContent>
        {fileType === 'PDF' && (
          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <IconButton
              disabled={pageNumber <= 1}
              onClick={() => setPageNumber(pageNumber - 1)}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <Typography variant="body1" component="div">
              {pageNumber} of {numPages}
            </Typography>
            <IconButton
              disabled={pageNumber >= numPages}
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default UserGuide;
