import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';

import { useBoolean } from 'v2/hooks/use-boolean';

import Label from 'v2/components/label';
import { ConfirmDialog } from 'v2/components/custom-dialog';
import CustomPopover, { usePopover } from 'v2/components/custom-popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IUser } from 'types/user';
import { getUserRoleColor, getUserStatusColor } from 'v2/utils/user';
import { LoadingButton } from '@mui/lab';
import UserQuickEditForm from './UserQuickEditForm';

// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  onViewRow: VoidFunction;
  row: IUser;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  isSuperAdmin: boolean;
  isDeleting: boolean;
  onUserQuickEditSuccess: VoidFunction;
  isShowOrganization?: boolean;
};

export default function UserTableRow({
  row,
  selected,
  onViewRow,
  onSelectRow,
  onDeleteRow,
  isSuperAdmin,
  isDeleting,
  onUserQuickEditSuccess,
  isShowOrganization = true
}: Props) {
  const { firstName, lastName, email, isActive, organization, role } = row;

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={`${firstName} ${lastName}`} sx={{ mr: 2 }} />

          <ListItemText
            primary={
              <Typography
                sx={{ cursor: 'pointer', color: 'primary.main' }}
                noWrap
                variant="subtitle2"
              >
                {firstName} {lastName}
              </Typography>
            }
            secondary={email}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled'
            }}
          />
        </TableCell>
        {isShowOrganization && (
          <TableCell align="center">{organization.name}</TableCell>
        )}
        <TableCell align="center">
          <Label variant="soft" color={getUserRoleColor(role)}>
            {role}
          </Label>
        </TableCell>
        <TableCell align="center">
          <Label variant="soft" color={getUserStatusColor(isActive)}>
            {isActive ? 'Active' : 'Inactive'}
          </Label>
        </TableCell>
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton
              color={quickEdit.value ? 'inherit' : 'default'}
              onClick={quickEdit.onTrue}
            >
              <ModeEditIcon />
            </IconButton>
          </Tooltip>

          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={popover.onOpen}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <UserQuickEditForm
        currentUser={row}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
        onCallback={onUserQuickEditSuccess}
      />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onViewRow();
            popover.onClose();
          }}
        >
          <VisibilityIcon />
          Detail
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <DeleteOutlineIcon />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <LoadingButton
            loading={isDeleting}
            variant="contained"
            color="error"
            onClick={onDeleteRow}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
}
