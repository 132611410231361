import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import moment from 'moment';
import { useGroupsContext } from 'contexts/GroupsContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getMentionText } from 'utils/mentionHelper';

function GroupList({ expanded }) {
  const { groups, selectedGroup, setSelectedGroup } = useGroupsContext();

  const navigate = useNavigate();
  const { id } = useParams();

  const handleClick = (item) => () => {
    if (item._id === id) return;
    setSelectedGroup(item);
    navigate(`/groups/${item._id}`);
  };

  return (
    <List>
      {groups.map((item) => (
        <ListItem
          selected={item._id === selectedGroup?._id}
          key={item.id}
          disablePadding
        >
          <ListItemButton
            sx={expanded ? null : { paddingLeft: 0 }}
            onClick={handleClick(item)}
          >
            <ListItemAvatar>
              <Avatar alt={`Avatar ${item.id}`} src={item.cover} />
            </ListItemAvatar>
            <ListItemText
              hidden={!expanded}
              primary={item.name}
              // TODO WS handle last message
              // secondary={getMentionText(
              //   lastMessages[item.id] ?? item.lastMessage
              // )}
              secondaryTypographyProps={{
                maxWidth: 'calc(100% - 20px)',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            />
            <Typography variant="caption" hidden={!expanded}>
              {moment(item.createdAt).format('MMM DD')}
            </Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export default GroupList;
