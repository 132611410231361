import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';
import React from 'react';
import FileExplorer from './FileExplorer/FileExplorer';
import { FileUpload } from '.';

const SlideboxViewer = () => {
  const { viewComponent } = useSlideboxContext();
  if (viewComponent === 'file_explorer') return <FileExplorer />;
  if (viewComponent === 'file_upload') return <FileUpload />;
  return <></>;
};

export default SlideboxViewer;
