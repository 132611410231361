import _ from 'lodash';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';

import BlockTableFilter from 'v2/components/block-table-filter';

import { IUserTableFilters, IUserTableFilterValue } from 'types/user';
import DeleteIcon from '@mui/icons-material/Delete';
import { IOrganization } from 'types/organization';
import { shortDateLabel } from 'v2/components/custom-date-range-picker';
// ----------------------------------------------------------------------

type Props = StackProps & {
  filters: IUserTableFilters;
  onFilters: (name: string, value: IUserTableFilterValue) => void;
  //
  onResetFilters: VoidFunction;
  //
  results: number;
  organizations: IOrganization[];
  dateError?: boolean;
};

export default function UserTableFiltersResult({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  organizations,
  dateError,
  ...other
}: Props) {
  const shortLabel = shortDateLabel(
    filters?.start_date || null,
    filters?.end_date || null
  );

  const handleRemoveStatus = () => {
    onFilters('status', '');
  };

  const handleRemoveRole = (inputValue: string) => {
    const newValue = filters.role?.filter((item) => item !== inputValue);
    onFilters('role', newValue || []);
  };

  const handleRemoveOrganization = (inputValue: number) => {
    const newValue = filters.organizations?.filter(
      (item) => item !== inputValue
    );
    onFilters('organizations', newValue || []);
  };

  const handleRemoveDate = () => {
    onFilters('start_date', null);
    onFilters('end_date', null);
  };

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          {' '}
          results found
        </Box>
      </Box>

      <Stack
        flexGrow={1}
        spacing={1}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
      >
        {filters.name && (
          <BlockTableFilter label="Name:">
            <Chip
              size="small"
              label={filters.name}
              onDelete={() => onFilters('name', '')}
            />
          </BlockTableFilter>
        )}

        {filters.status && (
          <BlockTableFilter label="Status:">
            <Chip
              label={_.capitalize(filters.status)}
              size="small"
              onDelete={() => handleRemoveStatus()}
            />
          </BlockTableFilter>
        )}

        {!!filters.role?.length && (
          <BlockTableFilter label="Role:">
            {filters.role?.map((item) => (
              <Chip
                key={item}
                label={_.capitalize(item)}
                size="small"
                onDelete={() => handleRemoveRole(item)}
              />
            ))}
          </BlockTableFilter>
        )}
        {!!filters.organizations?.length && (
          <BlockTableFilter label="Organization:">
            {filters.organizations.map((item) => (
              <Chip
                key={item}
                label={organizations.find((org) => org._id === item)?.name}
                size="small"
                onDelete={() => handleRemoveOrganization(item)}
              />
            ))}
          </BlockTableFilter>
        )}

        {filters.start_date && filters.end_date && (
          <BlockTableFilter label="Date:">
            <Chip size="small" label={shortLabel} onDelete={handleRemoveDate} />
          </BlockTableFilter>
        )}

        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<DeleteIcon />}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  );
}
