import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { IUser } from 'types/user';
import { useBoolean } from 'v2/hooks/use-boolean';
import { getUserRoleColor } from 'v2/utils/user';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from 'v2/components/custom-dialog';
import Label from 'v2/components/label';
import { useCancelInviteMutation } from 'services/user';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
type Props = {
  row: IUser;
  onCanceledSuccess: (id: number) => void;
};

const PendingInvitationTableRow = ({ row, onCanceledSuccess }: Props) => {
  const [cancelInvite, { isLoading }] = useCancelInviteMutation();

  const { email, role, createdAt } = row;
  const confirm = useBoolean();

  const onCancelInvite = async () => {
    const response = await cancelInvite(row._id).unwrap();
    if (response.success) {
      enqueueSnackbar('Cancelled the pending invite', {
        variant: 'success'
      });
      confirm.onFalse();
      onCanceledSuccess(row._id);
    }
  };

  return (
    <>
      <TableRow hover>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="center">
          <Label variant="soft" color={getUserRoleColor(role)}>
            {role}
          </Label>
        </TableCell>
        <TableCell align="center">
          {new Date(createdAt).toLocaleString()}
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Cancel invite" placement="top" arrow>
            <IconButton
              color={confirm.value ? 'inherit' : 'default'}
              onClick={confirm.onTrue}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Cancel invite"
        content="Are you sure want to delete?"
        action={
          <LoadingButton
            variant="contained"
            color="error"
            loading={isLoading}
            onClick={onCancelInvite}
          >
            Confirm
          </LoadingButton>
        }
      />
    </>
  );
};

export default PendingInvitationTableRow;
