import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

const RenameSlideWarningDialog = ({ open, onClose }) => (
  <Dialog
    sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
    maxWidth="xs"
    open={open}
  >
    <DialogTitle color={(theme) => theme.palette.warning.main}>Warning!</DialogTitle>
    <DialogContent>Slide name is duplicated! Please rename your slide!</DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
    </DialogActions>
  </Dialog>
);

export { RenameSlideWarningDialog };
