const getMB = (bytes) => {
  return (bytes / 1024 / 1024).toFixed(4) + ` MB`
}

const getKB = (bytes) => {
  return (bytes / 1024).toFixed(2) + ` KB`
}

const getGB = (bytes) => {
  return `${(bytes / 1024 / 1024 / 1024).toFixed(4)} GB`
}

const getSize = (bytes) => {
  if (!bytes && bytes !== 0) {
    return null
  }
  if (bytes < 1024 * 1024) {
    return getKB(bytes)
  }

  if (bytes < 1024 * 1024 * 1024) {
    return getMB(bytes)
  }

  return getGB(bytes)
}

const mergeById = (sourceArr, incomingArr) => {
  return [
    ...sourceArr.filter(
      (el) => incomingArr.findIndex((tEl) => tEl._id === el._id) === -1
    ),
    ...incomingArr
  ]
}

export { getMB, getKB, getSize, mergeById }
