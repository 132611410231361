import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import {
  Folder as FolderIcon,
  CreateNewFolder as CreateNewFolderIcon
} from '@mui/icons-material';
import { CaseForm } from './CaseForm';
import { FolderForm } from './FolderForm';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  pushFolersAndFiles,
  selectCurrentFolderView,
  selectFoldersAndFiles,
  updatebyId
} from 'stores/slidebox/slideboxSlice';
import {
  useCreateProjectMutation,
  useRenameProjectMutation,
  useUpdateProjectMutation
} from 'services/projects';
import { FILE_TYPES } from 'constants';

const DialogProject = ({ onClose, data }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { _id: currentFolderId, ancestors } = useSelector(
    selectCurrentFolderView
  );

  const foldersAndFiles = useSelector(selectFoldersAndFiles);

  const [createProject, { isLoading: isCreatingProject }] =
    useCreateProjectMutation();
  const [renameProject, { isLoading: isRenamingProject }] =
    useRenameProjectMutation();
  const [updateProject, { isLoading: isUpdatingProject }] =
    useUpdateProjectMutation();

  const isLoading = isCreatingProject || isRenamingProject || isUpdatingProject;

  const { register, watch, formState, resetField, setValue, handleSubmit } =
    useForm({
      defaultValues: {
        _id: data?._id || '',
        name: data?.name || '',
        type: data?.type || '',
        caseIdentifiers: data?.caseIdentifiers || [],
        ancestors: data?.ancestors || [],
        caseIdentifierCount: data?.caseIdentifierCount || 0,
        tags: data?.tags || []
      }
    });

  const handleSubmitForm = async (values) => {
    try {
      if (values.caseIdentifiers && values.caseIdentifiers.length > 0) {
        values.caseIdentifiers = values.caseIdentifiers.map((item) => {
          if (item._id === 'new') item._id = '';
          delete item.isSelected;
          delete item.isError;
          return item;
        });
      }
      if (values._id && values.type === 'case') {
        const { data } = await updateProject({
          id: values._id,
          body: {
            name: values.name,
            caseIdentifiers: values.caseIdentifiers,
            caseIdentifierCount: values.caseIdentifierCount,
            tags: values.tags
          }
        }).unwrap();
        handleUpdateSuccess(data);
      } else if (values._id && values.type === 'folder') {
        const { data } = await renameProject({
          id: values._id,
          body: { name: values.name }
        }).unwrap();
        handleUpdateSuccess(data);
      } else if (!values._id) {
        const project = {
          name: values.name,
          type: values.type,
          ancestors: currentFolderId
            ? [...(ancestors ? ancestors : []), currentFolderId]
            : [],
          ...(currentFolderId && { parent: currentFolderId }),
          ...(values.type === 'case' && {
            caseIdentifiers: values.caseIdentifiers,
            caseIdentifierCount: values.caseIdentifierCount,
            tags: values.tags
          })
        };

        const { data } = await createProject(project).unwrap();
        dispatch(pushFolersAndFiles([data]));
        enqueueSnackbar(`Created ${project.name} successfully.`, {
          variant: 'success'
        });
        handleClose();
      } else {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    } catch (err) {
      console.log('file: DialogProject.js:114 ~ handleSubmitForm ~ err:', err);
      if (err.data?.error?.message) {
        enqueueSnackbar(err.data.error.message, { variant: 'error' });
      } else {
        enqueueSnackbar(err.data?.error, { variant: 'error' });
      }
    } finally {
    }
  };

  const handleUpdateSuccess = (updatedData) => {
    const findIndex = foldersAndFiles.findIndex(
      (f) => f._id === updatedData._id
    );
    if (findIndex !== -1) {
      const updated = JSON.parse(JSON.stringify(foldersAndFiles[findIndex]));
      updated.name = updatedData.name;
      updated.tags = updatedData.tags;
      if (data.type !== FILE_TYPES.FOLDER) {
        updated.caseIdentifiers = updatedData.caseIdentifiers;
        updated.caseIdentifierCount = updatedData.caseIdentifierCount;
      }
      dispatch(updatebyId(updated));
      enqueueSnackbar('Updated successfully.', {
        variant: 'success'
      });
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
    resetField();
  };

  let dialogTitle = '';
  switch (data.type) {
    case 'folder':
      dialogTitle = data?._id ? 'Rename Folder' : 'Create New Folder';
      break;

    case 'case':
      dialogTitle = data?._id ? 'Update Project' : 'New Project / Case study';
      break;

    default:
      dialogTitle = 'Create Project';
      break;
  }

  const renderForm = () => {
    switch (data.type) {
      case 'folder':
        return <FolderForm register={register} watch={watch} />;

      case 'case':
        return (
          <CaseForm register={register} watch={watch} setValue={setValue} />
        );

      default:
        return null;
    }
  };

  const isValidated = () => {
    // const caseIdentifiers = watch('caseIdentifiers');
    switch (data.type) {
      case 'folder':
        return watch('name')?.trim() !== '';

      case 'case':
        return (
          watch('name')?.trim() !== '' // &&
          // watch('caseIdentifierCount') > 0 &&
          // caseIdentifiers.every((item) => !item.isError)
        );

      default:
        return false;
    }
  };

  // const isCaseIdentifierSelected = () => {
  //   const caseIdentifiers = watch('caseIdentifiers');
  //   return caseIdentifiers.some((item) => item.isSelected);
  // };

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose();
        }
      }}
      maxWidth={data.type === 'case' ? 'sm' : 'xs'}
      fullWidth
    >
      <DialogTitle
        sx={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}
      >
        {data.type === 'case' ? <CreateNewFolderIcon /> : <FolderIcon />}
        &nbsp;{dialogTitle}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleSubmitForm)}>{renderForm()}</form>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '10px'
        }}
      >
        <Button
          variant="contained"
          color="gray"
          component="label"
          disableElevation
          disabled={formState.isSubmitting}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={formState.isSubmitting || isLoading}
          disabled={
            formState.isSubmitting ||
            !formState.isValid ||
            isLoading ||
            !isValidated() // ||
            // (data.type === 'case' && isCaseIdentifierSelected())
          }
          variant="contained"
          disableElevation
          onClick={handleSubmit(handleSubmitForm)}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export { DialogProject };
