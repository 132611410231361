import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { IUser, IUserTableFilterValue, IUserTableFilters } from 'types/user';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  emptyRows,
  getComparator,
  useTable
} from 'v2/components/table';
import { applyFilterUser } from '../UserManagement';
import { Box, Table, TableBody, TableContainer } from '@mui/material';
import UserTableToolbar from '../UserManagement/UserTableToolbar';
import UserTableFiltersResult from '../UserManagement/UserTableFiltersResult';
import Scrollbar from 'v2/components/scrollbar';
import PendingInvitationTableRow from './PendingInvitationTableRow';

interface Props {
  pendingInvitations: IUser[];
  onRefresh: VoidFunction;
}

const defaultFilters: IUserTableFilters = {
  name: '',
  role: [],
  start_date: null,
  end_date: null
};

const TABLE_HEAD = [
  { id: 'email', label: 'Email' },
  { id: 'role', label: 'Role', align: 'center' },
  { id: 'invitedAt', label: 'Invitation Date', align: 'center' },
  { id: '', width: 88 }
];

const PendingInvitations = ({ pendingInvitations, onRefresh }: Props) => {
  const table = useTable();

  const [tableData, setTableData] = useState(pendingInvitations);

  const [filters, setFilters] = useState(defaultFilters);

  const dateError =
    filters.start_date && filters.end_date
      ? filters.start_date.getTime() > filters.end_date.getTime()
      : false;

  const dataFiltered = applyFilterUser({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const onCanceledSuccess = useCallback(
    (id: number) => {
      const deleteRow = tableData.filter((row) => row?._id !== id);
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage.length);
      onRefresh();
    },
    [dataInPage.length, onRefresh, table, tableData]
  );

  useEffect(() => {
    setTableData(pendingInvitations);
  }, [pendingInvitations]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <UserTableToolbar
        shows={['name', 'role', 'start_date', 'end_date']}
        filters={filters}
        onFilters={handleFilters}
        organizations={[]}
        dateError={dateError}
      />

      {canReset && (
        <UserTableFiltersResult
          filters={filters}
          onFilters={handleFilters}
          //
          onResetFilters={handleResetFilters}
          //
          results={dataFiltered.length}
          organizations={[]}
          sx={{ p: 2.5, pt: 0 }}
        />
      )}

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              onSort={table.onSort}
            />

            <TableBody>
              {dataFiltered
                .slice(
                  table.page * table.rowsPerPage,
                  table.page * table.rowsPerPage + table.rowsPerPage
                )
                .map((row) => (
                  <PendingInvitationTableRow
                    key={row._id}
                    row={row}
                    onCanceledSuccess={onCanceledSuccess}
                  />
                ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(
                  table.page,
                  table.rowsPerPage,
                  tableData.length
                )}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={dataFiltered.length}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        //
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Box>
  );
};

export default PendingInvitations;
