import React, { useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useSearchParams, Navigate } from 'react-router-dom';
import { FormContainer } from 'react-hook-form-mui';
import { useSnackbar } from 'notistack';
import { Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import AuthLayout from '../../components/Layout/AuthLayout';
import { useResetPasswordMutation } from '../../services/user';
import { useGetDataFromRequestTokenMutation } from '../../services/userRequest';
import { yupResolver } from '@hookform/resolvers/yup';
import { PasswordInput } from '../../components/Shared/passwordInput';
import { validationSchema } from '../../components/Shared/validationSchema';
import { REQUEST_TYPES } from '../../constants';

const ResetPassword = () => {
  const defaultValue = {
    password: '',
    repeatPassword: ''
  };
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [tokenAvailable, setTokenAvailable] = useState(true);
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [getDataFromRequestToken] = useGetDataFromRequestTokenMutation();
  let [searchParams, setSearchParams] = useSearchParams();

  const {
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldFocusError: true
  });
  const token = searchParams.get('token');

  useEffect(() => {
    const handleResetToken = async (token) => {
      try {
        const response = await getDataFromRequestToken({
          token,
          type: REQUEST_TYPES.ResetPassword
        }).unwrap();
        const today = new Date();
        if (response.success && new Date(response.data.expiredDate) > today) {
          setTokenAvailable(true);
        } else {
          return navigate({ pathname: '/login' });
        }
      } catch (err) {
        console.log(err);
        setTokenAvailable(false);
      }
    };

    if (token) {
      handleResetToken(token);
    }
  }, [getDataFromRequestToken, navigate, token]);

  if (!token && !tokenAvailable) {
    enqueueSnackbar('Access Error !', {
      variant: 'error'
    });
    return <Navigate to="/login" />;
  }

  const handleResetPassword = async (form) => {
    try {
      const payload = {
        password: form.password,
        token: token
      };
      const response = await resetPassword(payload).unwrap();
      const { data } = response;
      if (!data) throw new Error('Something went wrong !');
      enqueueSnackbar('Reset password successfully', {
        variant: 'success'
      });

      return navigate({
        pathname: '/login',
        search: createSearchParams({ email: response.data.email }).toString()
      });
    } catch (err) {
      enqueueSnackbar('There is an error', { variant: 'error' });
    }
  };

  return (
    <AuthLayout>
      <FormContainer
        onSuccess={handleSubmit(handleResetPassword)}
        defaultValue={defaultValue}
      >
        <Stack direction="column" gap={2}>
          <PasswordInput
            {...register('password', {
              required: 'This field is required',
            })}
            placeholder="New Password"
            required
            fullWidth={true}
            type="password"
            error={!!errors.password}
            error-message={errors.password?.message}
          />
          <PasswordInput
            {...register('repeatPassword', {
              required: 'This field is required',
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();
                  return password === value || 'Passwords should match!';
                }
              }
            })}
            type="password"
            placeholder="Confirm New Password"
            required
            fullWidth={true}
            error={!!errors.repeatPassword}
            error-message={errors.repeatPassword?.message}
          />
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            value="submit"
            sx={{ height: '56px', borderRadius: '5px', boxShadow: 'none' }}
          >
            <span style={{ fontSize: '20px' }}>SET PASSWORD</span>
          </LoadingButton>
        </Stack>
      </FormContainer>
    </AuthLayout>
  );
};

export default ResetPassword;
