import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { getPublicImage } from '../../utils';
import { useAuth } from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const AuthLayout = ({ children }) => {
  const { user } = useAuth();
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));

  const goToHome = () => {
    window.location.href = 'https://www.targetedbioscience.com/';
  };

  return user ? (
    <Navigate to="/" />
  ) : (
    <Grid container sx={{ minHeight: '100vh', postion: 'relative' }}>
      <Grid
        item
        xs={5}
        md={4}
        sx={{ overflow: 'hidden', position: 'relative' }}
      >
        <img
          style={{
            height: '100%',
            position: 'absolute',
            right: '0',
            width: matchMd ? '100%' : 'unset'
          }}
          alt="login_banner"
          src={getPublicImage('login_left_banner.png')}
        />
      </Grid>
      <Grid item xs sx={{ backgroundColor: '#FEF0FF' }}></Grid>
      <Grid
        container
        sx={{ minHeight: '100vh', postion: 'relative', position: 'absolute' }}
      >
        {matchMd && (
          <Grid
            item
            xs={5}
            md={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                backgroundColor: '#6d007778',
                boxShadow: '-5px 5px 15px 5px rgba(0, 0, 0, 0.25)',
                backdropFilter: 'blur(3px)',
                borderRadius: '28px',
                padding: '12px',
                color: '#ffff'
              }}
            >
              <Typography
                sx={{
                  fontWeight: '900',
                  lineHeight: 1,
                  [theme.breakpoints.up('md')]: {
                    fontSize: '55px'
                  },
                  [theme.breakpoints.up('lg')]: {
                    fontSize: '65px'
                  },
                  [theme.breakpoints.up('xl')]: {
                    fontSize: '85px'
                  }
                }}
              >
                Spatial
                <br />
                Profiler
              </Typography>
              <Typography
                sx={{
                  fontWeight: '',
                  lineHeight: 1,
                  textAlign: 'center',
                  [theme.breakpoints.up('md')]: {
                    fontSize: '22px'
                  },
                  [theme.breakpoints.up('lg')]: {
                    fontSize: '26px'
                  },
                  [theme.breakpoints.up('xl')]: {
                    fontSize: '35px'
                  }
                }}
              >
                Precision Medicine
              </Typography>
            </div>
          </Grid>
        )}

        <Grid
          item
          xs
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              background: '#ffff',
              py: '24px',
              px: '24px',
              borderRadius: '30px',
              boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
              zIndex: 1,
              width: '95%',
              maxWidth: '500px',
              [theme.breakpoints.up('md')]: {
                maxWidth: '400px'
              },
              [theme.breakpoints.up('lg')]: {
                maxWidth: '500px'
              },
              [theme.breakpoints.up('xl')]: {
                maxWidth: '882px'
              }
            }}
          >
            <Box
              sx={{
                pb: '28px',
                mb: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box
                onClick={goToHome}
                sx={{
                  cursor: 'pointer',
                  [theme.breakpoints.up('xs')]: {
                    width: '100px'
                  },
                  [theme.breakpoints.up('lg')]: {
                    width: '120px'
                  }
                }}
              >
                <img alt="logo" width="100%" src={getPublicImage('logo.png')} />
              </Box>

              <Box sx={{ color: 'blue.main', ml: '34px' }}>
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                  Targeted
                  <br />
                  Bioscience
                </Typography>
              </Box>
            </Box>
            {children}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
