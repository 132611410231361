import { api } from './api';

export const messageAPI = api.injectEndpoints({
  endpoints: (build) => ({
    listMessages: build.query({
      query: ({ limit, cursor, groupId }) => ({
        url: `/groups/${groupId}/messages?limit=${limit || 50}&cursor=${
          cursor ?? ''
        }`
      }),
      transformResponse: (response) => {
        response.data.list?.reverse();
        return response.data;
      }
    }),
    sendMessage: build.mutation({
      query: ({ groupId, ...body }) => ({
        url: `/groups/${groupId}/messages`,
        method: 'POST',
        body
      })
    }),
    updateMessage: build.mutation({
      query: ({ groupId, messageId, ...body }) => ({
        url: `/groups/${groupId}/messages/${messageId}`,
        method: 'PATCH',
        body
      })
    }),
    deleteMessage: build.mutation({
      query: ({ groupId, messageId }) => ({
        url: `/groups/${groupId}/messages/${messageId}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useListMessagesQuery,
  useSendMessageMutation,
  useUpdateMessageMutation,
  useDeleteMessageMutation
} = messageAPI;

export const {
  endpoints: { listMessages }
} = messageAPI;
