import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { debounce } from 'lodash';
import { useGetBlockIdsQuery } from 'services/caseIdentifier';

const filter = createFilterOptions();

export const AutocompleteBlockId = ({
  isCreateable = false,
  isMulti = false,
  onChange,
  value,
  label = 'BlockId',
  placeholder = 'BlockId',
  sx = {}
}) => {
  const [blockIds, setBlockIds] = useState([]);

  const [blockIdQuery, setBlockIdQuery] = useState({
    limit: 100,
    page: 1,
    search: ''
  });

  const { data: fetchedBlockIds } = useGetBlockIdsQuery(blockIdQuery, {
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (fetchedBlockIds) {
      setBlockIds(
        fetchedBlockIds.map((blockId) => ({
          value: blockId,
          _id: blockId
        }))
      );
    }
  }, [fetchedBlockIds]);

  const debouncedSearch = debounce((search) => {
    setBlockIdQuery({
      ...blockIdQuery,
      search
    });
  }, 200);

  return (
    <Autocomplete
      multiple={isMulti}
      disableCloseOnSelect={isMulti}
      id="blockIds-outlined"
      sx={sx}
      onBlur={() => {
        setBlockIdQuery({
          ...blockIdQuery,
          search: ''
        });
      }}
      options={
        blockIds
          ? blockIds.map((blockId) => ({
              value: blockId.value,
              _id: blockId._id
            }))
          : []
      }
      freeSolo={isCreateable}
      onChange={(e, value) => {
        const convertBlockIds = value.map((blockId) => {
          if (typeof blockId === 'string') {
            return {
              value: blockId,
              _id: ''
            };
          }
          return blockId;
        });
        // remove duplicate blockIds
        const uniqueBlockIds = convertBlockIds.filter(
          (blockId, index, self) =>
            index === self.findIndex((t) => t.value === blockId.value)
        );
        // remove empty blockIds
        const filteredBlockIds = uniqueBlockIds.filter(
          (blockId) => blockId.value.trim() !== ''
        );
        onChange && onChange(filteredBlockIds);
      }}
      value={value || []}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const existingList = value ? [...value, ...options] : options;
        const isExisting = existingList.some((option) => {
          if (params.inputValue) {
            return option.value === params.inputValue;
          }
          return false;
        });
        if (params.inputValue !== '' && isCreateable && !isExisting) {
          filtered.push({
            _id: '',
            value: params.inputValue,
            label: `Add "${params.inputValue}"`
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.value;
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option) => (
        <li {...props}>{option.label || option.value}</li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          variant="outlined"
          placeholder={placeholder}
          label={label}
          value={blockIdQuery.search}
          onChange={(e) => {
            e.persist();
            debouncedSearch(e.target.value);
          }}
          size="small"
        />
      )}
    />
  );
};
