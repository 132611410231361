import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const PrivateRoute = ({ children, roles }) => {
  let location = useLocation();

  // sure user is existed now
  const { user } = useAuth();

  const isAllowedRole = user && roles.includes(user.role);

  if (!isAllowedRole) return <Navigate to="/" state={{ from: location }} />;

  return children;
};

export default PrivateRoute;
