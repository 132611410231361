import { api } from './api';

export const organizationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrganizations: build.query({
      query: (params) => ({ url: '/organizations', params }),
      transformResponse: (response) => response.data
    }),
    fetchOrganizations: build.mutation({
      query: (params) => ({ url: '/organizations', params }),
      transformResponse: (response) => response.data
    }),
    getOrganizationOptions: build.mutation({
      query: (params) => ({ url: '/organizations', params }),
      transformResponse: (response) => response.data
    }),
    fetchOrganizationOptions: build.query({
      query: (params) => ({ url: '/organizations', params }),
      transformResponse: (response) => response.data
    }),
    createOrganization: build.mutation({
      query: (body) => ({
        url: '/organizations/',
        method: 'POST',
        body
      })
    }),
    updateOrganization: build.mutation({
      query: (body) => ({
        url: `/organizations/${body.id}/`,
        method: 'PATCH',
        body
      })
    }),
    deleteOrganization: build.mutation({
      query: (body) => ({
        url: `/organizations/${body.id}/`,
        method: 'DELETE',
        body
      })
    }),
    getOrgUsers: build.mutation({
      query: (params) => ({
        url: `/users`,
        method: 'GET',
        params
      }),
      transformResponse: (response) => response.data
    })
  })
});

export const {
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationOptionsMutation,
  useGetOrganizationsQuery,
  useFetchOrganizationsMutation,
  useFetchOrganizationOptionsQuery,
  useGetOrgUsersMutation
} = organizationApi;

export const {
  endpoints: {
    getOrganizations,
    createOrganization,
    updateOrganization,
    getOrganizationOptions
  }
} = organizationApi;
