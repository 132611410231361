import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Avatar as MUIAvatar, useTheme } from '@mui/material';

/**
 * @param {string} url
 * @param {number?} size
 * @returns {JSX.Element}
 * @constructor
 */
const Avatar = ({ url, size = 50 }) => {
  const theme = useTheme();

  if (!url) {
    return (
      <AccountCircleIcon
        sx={{ fontSize: size, color: theme.palette.blue.main }}
      />
    );
  }

  return <MUIAvatar src={url} size={size} />;
};

export default Avatar;
