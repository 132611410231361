import {TextField, Typography} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import React, { forwardRef, useState } from 'react';

export const styles = (theme) => ({
  '& .MuiInputBase-input': {
    padding: '10px 55px',
    backgroundColor: 'transparent!important',
    fontSize: '20px',
    '&::placeholder': {
      color: '#C8C8C8',
      opacity: 1
    }
  },
  '& .MuiInputBase-root': {
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus':
      {
        border: '1px solid none',
        WebkitTextFillColor: 'none',
        WebkitBoxshadow: '0 0 0px 1000px rgb(255, 255, 255) inset',
        transition: 'background-color 5000s ease-in-out 0s'
      }
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#CCCCCC',
      borderWidth: '2px',
      borderRadius: '5px'
    },
    '&:hover fieldset': {
      borderColor: '#CCCCCC'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main
    }
  }
});

export const StyledInput = styled(TextField)(({ theme }) => {
  return { ...styles(theme) };
});

    export const PasswordInput = forwardRef(function PasswordInput(props, ref) {
      const theme = useTheme();
      const [showPassword, setShowPassword] = useState(false);
      const handleClickShowPassword = () => setShowPassword((show) => !show);
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
      const { 'error-message' : errorMessage} = props
      return (
        <div style={props.style} className={props.className}>
          <StyledInput
            {...props}
            ref={ref}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Typography
            variant="caption"
            sx={{ color: theme.palette.error.main }}
          >
            {errorMessage}
          </Typography>
        </div>
      );
    });