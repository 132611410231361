export const convertArray = (array) => {
  let result = [];
  if (array) {
    if (typeof array === 'string') {
      result = array ? array.split(',') : [];
    } else if (Array.isArray(array)) {
      result = array;
    } else {
      console.error(
        `The values for ${JSON.stringify(array)} is not a string or an array.`
      );
    }
  }
  return result;
};
