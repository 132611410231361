import { uuidv4 } from '@firebase/util';
const { SHAPE_TYPES } = require("constants");

export const COPY_ID = "copy-id"
export const COPY_OF = "Copy of"
export const FILL_OPACITY = "0.1"

/**
 * 
 * @param {*} anno annotation
 * @param {*} newX new x position
 * @param {*} newY new y position
 * @returns return new annotation with new position
 */
export function changeXY(anno, newX, newY) {
    const svgSelector = anno.target.selector
    let value = svgSelector.value
    switch (anno.shapeType) {
        case SHAPE_TYPES.rect:
            value = value.replace(/xywh=pixel:(-?\d+(\.\d+)?),(-?\d+(\.\d+)?)/, 'xywh=pixel:' + newX + ',' + newY)
            break;
        case SHAPE_TYPES.ellipse:
            value = value.replace(/cx="([^"]+)"/, `cx="${newX}"`).replace(/cy="([^"]+)"/, `cy="${newY}"`);
            break;
        case SHAPE_TYPES.Freehand:
            value = changeXYFreeHandShape(value, newX, newY)
            break;
        default:
            break;
    }
    return {
        ...anno,
        "target": {
            ...anno.target,
            selector: {
                ...svgSelector,
                value,
            },
        }
    };
}

/**
 * 
 * @param {*} value selector of FreeHand Shape
 * @returns array of points = [1,2,3,4,5,6,7]
 */
function getAllPointsFreeHandShape(value) {
    const start = value.indexOf("d=\"") + 3;
    const end = value.indexOf("\"", start);
    const pathData = value.slice(start, end);

    const regex = /[-+]?\d*\.?\d+/g;
    const matches = pathData.match(regex);
    const points = [];
    for (let i = 0; i < matches.length; i += 2) {
        const x = parseFloat(matches[i]);
        const y = parseFloat(matches[i + 1]);
        if (!isNaN(x) && !isNaN(y)) {
            points.push([x, y]);
        }
    }
    return points
}

/**
 * 
 * @param {*} value selector of FreeHand Shape
 * @param {*} newMouseX new x position
 * @param {*} newMouseY new y position
 * @returns return new selector value with new position
 */
function changeXYFreeHandShape(value, newMouseX, newMouseY) {
    const points = getAllPointsFreeHandShape(value)

    const startX = points[0][0];
    const startY = points[0][1];

    const deltaX = newMouseX - startX;
    const deltaY = newMouseY - startY;

    const translatedPoints = points.map(point => {
        const [x, y] = point;
        const translatedX = x + deltaX;
        const translatedY = y + deltaY;
        return [translatedX, translatedY];
    });

    const newPathData = "M" + translatedPoints.map(pt => pt.join(" ")).join(" L");
    const newTag = `<svg><path d="${newPathData}"></path></svg>`;
    return newTag
}

/**
 * @param {*} annotation current annotation
 * @returns return position x of annotation
 */
export function getXAnno(annotation) {
    switch (annotation.shapeType) {
        case SHAPE_TYPES.rect:
            const matchXRect = annotation.target.selector.value.match(/xywh=pixel:(-?\d+(\.\d+)?),/);
            return matchXRect.length ? parseFloat(matchXRect[1]) : null;
        case SHAPE_TYPES.ellipse:
            const matchXEllcipse = annotation.target.selector.value.match(/<ellipse\s+cx="([^"]+)"/);
            return matchXEllcipse.length ? parseFloat(matchXEllcipse[1]) : null;
        case SHAPE_TYPES.Freehand:
            const matchXFreehand = annotation.target.selector.value.match(/M([-+]?\d*\.?\d+)/);
            return matchXFreehand.length ? parseFloat(matchXFreehand[1]) : null;
        default:
            return 0
    }

}

/**
* @param {*} annotation current annotation
* @returns return position y of annotation
*/
export function getYAnno(annotation) {
    const { value } = annotation.target.selector
    switch (annotation.shapeType) {
        case SHAPE_TYPES.rect:
            const matchYRect = value.match(/xywh=pixel:[^,]+,(-?\d+(\.\d+)?)/);
            return matchYRect && matchYRect.length ? parseFloat(matchYRect[1]) : null;
        case SHAPE_TYPES.ellipse:
            const matchYEllcipse = value.match(/<ellipse\s+.*?\bcy="(-?\d+(\.\d+)?)"/);
            return matchYEllcipse && matchYEllcipse.length ? parseFloat(matchYEllcipse[1]) : null;
        case SHAPE_TYPES.Freehand:
            const matchXFreehand = annotation.target.selector.value.match(/M[-+]?\d*\.?\d+\s([-+]?\d*\.?\d+)/);
            return matchXFreehand && matchXFreehand.length ? parseFloat(matchXFreehand[1]) : null;
        default:
            return 0
    }
}

/**
 * Compare annotation with x position
 * @param {*} a annotation A
 * @param {*} b annotation B
 * @returns 
 */
export function compareX(a, b) {
    const xA = getXAnno(a);
    const xB = getXAnno(b);
    if (xA < xB) {
        return -1;
    }
    if (xA > xB) {
        return 1;
    }
    return 0;
}

const copyDistance = {
    [SHAPE_TYPES.rect]: 20,
    [SHAPE_TYPES.Freehand]: 10,
    [SHAPE_TYPES.ellipse]: 10
}

/**
* Copy all annotations to a new position
* @param {Array} annotations - Array of selected annotations
* @param {number} mouseX - New x position of the mouse
* @param {number} mouseY - New y position of the mouse
* @returns {Array} - New list of annotations with updated positions
*/
export const copyAnnotationsToNewPosition = (annotations) => {
    const newAnnotations = annotations.map((anno, index) => {
        const newUuid = uuidv4();
        const xCurrent = getXAnno(anno);
        const yCurrent = getYAnno(anno);
        const distance = copyDistance[anno.shapeType]
        const offSetX = xCurrent * distance / 100;
        const offSetY = yCurrent * distance / 100;
        const newX = offSetX + xCurrent;
        const newY = offSetY + yCurrent;
        const newAnnotation = changeXY(anno, newX, newY);
        newAnnotation.parentCopy = newAnnotation._id;
        newAnnotation.id = `${COPY_ID}:${newUuid}`;
        newAnnotation._id = `${COPY_ID}:${newUuid}`;
        newAnnotation.title = `${COPY_OF} ${newAnnotation.title}`
        return newAnnotation;
    });
    return newAnnotations;
};

/**
* Move all annotations to a new position
* @param {annotation} currentAnnotation - current selected annotations
* @param {Array} annotations - Array of selected annotations
* @param {number} mouseX - New x position of the mouse
* @param {number} mouseY - New y position of the mouse
* @returns {Array} - New list of annotations with updated positions
*/
export const moveAnnotationsToNewPosition = (currentAnnotation, annotations, mouseX, mouseY) => {
    const xCurrentAnno = getXAnno(currentAnnotation);
    const yCurrentAnno = getYAnno(currentAnnotation);
    const newAnnotations = annotations.map((anno) => {
        if (anno._id === currentAnnotation._id) {
            const newAnnotation = changeXY(currentAnnotation, mouseX, mouseY);
            newAnnotation.update = true
            return newAnnotation;
        }
        const xCurrent = getXAnno(anno);
        const yCurrent = getYAnno(anno);
        const distanceX = xCurrent - xCurrentAnno;
        const distanceY = yCurrent - yCurrentAnno;
        const newX = distanceX + mouseX;
        const newY = distanceY + mouseY;
        const newAnnotation = changeXY(anno, newX, newY);
        newAnnotation.update = true
        return newAnnotation;
    });
    return newAnnotations;
};

//change color when multiple selected
export const initSelectedAnno = (annoSelectedList) => {
    annoSelectedList.forEach((anno) => {
        const id = anno?._id ? anno?._id : anno?.id
        const element = document.querySelector(
            `[data-id="${id}"]`
        );
        if (element) {
            const childElements = element.children;
            if (childElements && childElements.length > 1) {
                addStyleToAnnotation(childElements[1], anno)
            }
        }
    })
}

//unset color when multiple selected
export const unSelectedAnno = (annoSelectedList) => {
    annoSelectedList.forEach((anno) => {
        const id = anno?._id ? anno?._id : anno?.id
        const element = document.querySelector(
            `[data-id="${id}"]`
        );
        if (element) {
            const childElements = element.children;
            if (childElements && childElements.length > 1) {
                removeStyleFromAnnotation(childElements[1], anno)
            }
        }
    })
}

export const addStyleToAnnotationAndPointer = (childElements, anno) => {
    Array.from(childElements).forEach((childElement, index) => {
        let innerChildElements = childElement.children;
        if (index > 0) {
            innerChildElements = innerChildElements[0].children;
        }
        if (innerChildElements && innerChildElements.length > 1) {
            addStyleToAnnotation(innerChildElements[1], anno, index > 0 ? false : true)
        }
    })
}

export const addStyleToAnnotation = (el, anno, fillOpacity) => {
    el.style.stroke = `${anno.stroke}`
    el.style.fill = `${anno.stroke}`
    if (fillOpacity !== false) {
        el.style.fillOpacity = `${FILL_OPACITY}`
    }
}

export const removeStyleFromAnnotation = (el, anno) => {
    el.style.stroke = `${anno.stroke}`
    el.style.fill = "none";
    el.style.fillOpacity = `${FILL_OPACITY}`
}

/* Set Handler for Annotation tools */
export function convertSvgToSelector(svgSelector) {
    const matches = svgSelector.value.match(/[-+]?[0-9]*\.?[0-9]+/g);
    const [x, y, width, height] = matches.map(parseFloat);

    const fragmentSelector = {
        type: "FragmentSelector",
        conformsTo: "http://www.w3.org/TR/media-frags/",
        value: `xywh=pixel:${x},${y},${width},${height}`
    };

    return fragmentSelector;
}