import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export default function MenuButton({ buttonIcon, menuItems, value }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (value, cb) => {
    handleClose()
    cb(value)
  }

  return (
    <div>
      <IconButton onClick={handleClick}>{buttonIcon}</IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {menuItems.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => handleMenuItemClick(value, item.onClick)}
            >
              {item.label}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
