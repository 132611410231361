import { Box, Button, Paper, TextField } from '@mui/material';
import { AutocompleteTag } from 'components/Shared/AutocompleteTag';
import React, { useState } from 'react';
import { CaseIdentifierItem } from './CaseIdentifierItem';
import Alert from 'components/Alert';

const CaseForm = ({ register, watch, setValue }) => {
  const currentIdentifiers = watch(`caseIdentifiers`);
  const [caseChanged, setCaseChanged] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    func: null
  });

  const handleAddIdentifier = () => {
    setValue &&
      setValue(`caseIdentifiers`, [
        ...currentIdentifiers.map((item) => ({ ...item, isSelected: false })),
        {
          _id: 'new',
          patientId: '',
          blockId: '',
          treatmentGroup: '',
          recuts: '',
          isSelected: true,
          isError: false
        }
      ]);
  };

  const handleSelectedIdentifier = (index) => {
    const newSelected = () => {
      setValue &&
        setValue(
          `caseIdentifiers`,
          currentIdentifiers
            .map((item, idx) => ({
              ...item,
              isSelected: index === idx
            }))
            .filter((item) => item._id !== 'new')
        );
    };
    if (caseChanged) {
      setConfirmDialog({
        open: true,
        func: newSelected
      });
      return;
    } else {
      newSelected();
    }
  };

  const handleCloseIdentifier = (index) => {
    setValue &&
      setValue(
        `caseIdentifiers`,
        currentIdentifiers.map((item, idx) => ({
          ...item,
          isSelected: index === idx ? false : item.isSelected
        }))
      );
  };

  const handleUpdateIdentifier = (index, data) => {
    const validateData = validateCaseIdentifiers(index, data);

    if (validateData._id === 'new') {
      validateData._id = '';
    }

    if (setValue) {
      setValue(
        `caseIdentifiers`,
        currentIdentifiers.map((item, idx) =>
          index === idx ? { ...item, ...validateData, isSelected: false } : item
        )
      );
    }
  };

  const handleDeleteIdentifier = (index, isSilent = false) => {
    const deleteIdentifier = () => {
      setValue &&
        setValue(
          `caseIdentifiers`,
          currentIdentifiers
            .filter((item, idx) => index !== idx && item._id !== 'new')
            .map((item, idx) => ({
              ...item,
              isSelected: false
            }))
        );
    };

    if (caseChanged && !isSilent) {
      setConfirmDialog({
        open: true,
        func: deleteIdentifier
      });
      return;
    }
    deleteIdentifier();
  };
  // validate caseIdentifiers
  const validateCaseIdentifiers = (index, item) => {
    let isError = false;
    if (item.patientId === '' || item.patientId.trim() === '') isError = true;

    //check duplicate patientId
    const patientId = item.patientId;
    const isDuplicate = currentIdentifiers.some(
      (item, idx) => item.patientId === patientId && index !== idx
    );
    if (isDuplicate) isError = true;

    return { ...item, isError };
  };

  const haveSelected = currentIdentifiers.some((item) => item.isSelected);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px'
      }}
    >
      <TextField
        fullWidth
        autoFocus
        size="small"
        margin="dense"
        type="text"
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '2px solid #CCCCCC',
              borderRadius: '5px'
            }
          }
        }}
        variant="outlined"
        placeholder="Enter project / study name"
        id="name"
        name="name"
        {...register('name', { required: true })}
      />
      <AutocompleteTag
        value={watch('tags')}
        onChange={(tags) => {
          setValue && setValue('tags', tags);
        }}
        isCreateable={true}
        isMulti={true}
      />
      <TextField
        fullWidth
        size="small"
        margin="dense"
        type="number"
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '2px solid #CCCCCC',
              borderRadius: '5px'
            }
          }
        }}
        variant="outlined"
        placeholder="Case count"
        id="caseIdentifierCount"
        name="caseIdentifierCount"
        {...register('caseIdentifierCount', { required: false })}
      />
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          justifyContent: 'start',
          flexWrap: 'wrap',
          listStyle: 'none',
          background: 'transparent',
          p: 0,
          m: 0,
          gap: '5px'
        }}
        component="ul"
      >
        {currentIdentifiers &&
          currentIdentifiers.length > 0 &&
          currentIdentifiers.map((item, index) => (
            <CaseIdentifierItem
              key={`caseIdentifiers-select-${index}`}
              data={item}
              index={index}
              handleSelected={handleSelectedIdentifier}
              handleClose={handleCloseIdentifier}
              handleUpdate={handleUpdateIdentifier}
              setCaseChanged={setCaseChanged}
              handleDelete={handleDeleteIdentifier}
              otherPatientIds={currentIdentifiers
                .filter((_, idx) => idx !== index)
                .map((item) => item.patientId)}
            />
          ))}
      </Paper>
      {!haveSelected &&
        watch('caseIdentifierCount') > 0 &&
        watch('caseIdentifierCount') > watch('caseIdentifiers').length && (
          <Box>
            <Button variant="contained" onClick={handleAddIdentifier}>
              Add Case Identifier
            </Button>
          </Box>
        )}
      <Alert
        title="Unsaved Changes"
        content="You have unsaved changes. Are you sure you want to leave?"
        open={confirmDialog.open}
        onDismiss={() => setConfirmDialog({ open: false, func: null })}
        onConfirm={() => {
          confirmDialog.func();
          setConfirmDialog({ open: false, func: null });
        }}
      />
    </Box>
  );
};

export { CaseForm };
