import useToggleState from '../../../hooks/useToggleState';
import { Box } from '@mui/material';
import Toolbar from './Toolbar';
import SearchBox from './SearchBox';
import GroupList from './GroupList';
import SubDrawer from '../../../components/Drawer/SubDrawer';

const GroupSubDrawer = ({ keyword, setKeyword }) => {
  const [open, openDrawer, closeDrawer] = useToggleState(true);

  return (
    <SubDrawer open={open}>
      <Box sx={{ p: 2, minWidth: 74 }}>
        <Toolbar
          expanded={open}
          onExpand={openDrawer}
          onCollapse={closeDrawer}
        />
        <SearchBox
          expanded={open}
          onExpand={openDrawer}
          value={keyword}
          onSubmit={setKeyword}
        />
        <GroupList expanded={open} />
      </Box>
    </SubDrawer>
  );
};

export default GroupSubDrawer;
