import { MoreVert } from '@mui/icons-material';
import { Box } from '@mui/material';
import MenuButton from 'components/MenuButton';
import { useState } from 'react';
import Alert from 'components/Alert';
import ShareButton from '../components/ShareButton';
import { useDeleteSlideMutation } from 'services/slides';
import { useDeleteSharedEntityMutation } from 'services/share';
import { useDeleteProjectMutation } from 'services/projects';
import { useSnackbar } from 'hooks/useSnackbar';
import { FILE_TYPES } from 'constants';
import {
  selectFoldersAndFiles,
  setFoldersAndFiles
} from 'stores/slidebox/slideboxSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../hooks/useAuth';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';
import { ROLES } from 'constants';

const FileAndFolderActionMenu = ({ row, rows }) => {
  const { dispatch, setDialogData, setProjectSlides } = useSlideboxContext();
  const {
    user: { email: loggedEmail, role }
  } = useAuth();
  const [shareclicked, setShareclicked] = useState(0);
  const [removeDialog, setRemoveDialog] = useState(false);
  const [unshareDialog, setUnshareDialog] = useState(false);

  const openDetailDrawer = () => {
    dispatch({
      type: 'SET_STATE',
      key: 'selectedRows',
      value: [row]
    });
    dispatch({ type: 'SET_STATE', key: 'openDetail', value: true });
  };
  const moveClicked = () => {
    dispatch({
      type: 'SET_STATE',
      key: 'selectedRows',
      value: [row]
    });
    dispatch({ type: 'SET_STATE', key: 'openMoveFolder', value: true });
  };
  let menuVerticalItems = [];
  if (row.type !== FILE_TYPES.FILE) {
    menuVerticalItems.push({
      label: 'Info',
      onClick: () => {
        openDetailDrawer();
      }
    });
  }
  if (row.isRenamable) {
    menuVerticalItems.push({
      label: 'Edit',
      onClick: () => {
        if (row.type === FILE_TYPES.FILE) {
          setDialogData('slide', row);
          setProjectSlides(rows);
        } else {
          setDialogData('project', row);
        }
      }
    });
  }
  if (role !== ROLES.SUPER_ADMIN || row.parent !== 'Managed by me') {
    if (row.type !== FILE_TYPES.FILE && row.parent !== 'Shared with me') {
      menuVerticalItems.push({
        label: 'Move',
        onClick: () => {
          moveClicked();
        }
      });
    }
  }
  if (row.type !== FILE_TYPES.FOLDER) {
    menuVerticalItems.push({
      label: 'Share',
      onClick: () => {
        let oldSharedClick = shareclicked;
        setShareclicked(oldSharedClick + 1);
      }
    });
  }
  if (row.isShareable && row.sharedEntities?.length > 0) {
    menuVerticalItems.push({
      label: 'Unshare',
      onClick: () => {
        setUnshareDialog(true);
      }
    });
  }
  if (row.isRemoveable) {
    menuVerticalItems.push({
      label: 'Delete',
      onClick: () => {
        setRemoveDialog(true);
      }
    });
  }

  const { enqueueSnackbar } = useSnackbar();

  const [deleteFloder, {}] = useDeleteProjectMutation();
  const [deleteSlide, {}] = useDeleteSlideMutation();
  const [deleteSharedEntity, {}] = useDeleteSharedEntityMutation();
  const reduxDispatch = useDispatch();
  const foldersAndFiles = useSelector(selectFoldersAndFiles);

  const handleDeleteFloderAndSlide = async () => {
    try {
      if (row.type === FILE_TYPES.FILE) await deleteSlide(row._id);
      else await deleteFloder(row._id);
      reduxDispatch(
        setFoldersAndFiles(foldersAndFiles.filter((x) => x._id !== row._id))
      );
      setRemoveDialog(false);
      enqueueSnackbar(`Remove ${row.type} successfully`, {
        variant: 'success'
      });
    } catch (error) {
      setRemoveDialog(false);
      enqueueSnackbar('Something wrong happened. Please try again', {
        variant: 'error'
      });
    }
  };
  const handleUnshare = async () => {
    try {
      row.sharedEntities.forEach((entity) => {
        if (entity.sharedBy === loggedEmail) {
          deleteSharedEntity(entity._id);
          reduxDispatch(
            setFoldersAndFiles(
              foldersAndFiles.map((item) => {
                if (item._id === row._id) {
                  return {
                    ...item,
                    sharedEntities: []
                  };
                }
                return item;
              })
            )
          );
        }
      });
      setUnshareDialog(false);
      enqueueSnackbar(`Unshared successfully`, {
        variant: 'success'
      });
    } catch (error) {
      setUnshareDialog(false);
      enqueueSnackbar('Something wrong happened. Please try again', {
        variant: 'error'
      });
    }
  };
  return menuVerticalItems.length ? (
    <Box>
      <MenuButton buttonIcon={<MoreVert />} menuItems={menuVerticalItems} />
      <ShareButton
        entities={[row]}
        calledFrom="menu"
        shareClicked={shareclicked}
      />
      <Alert
        open={removeDialog}
        onDismiss={() => setRemoveDialog(false)}
        title={`Delete ${row.type}`}
        content={`Are you sure to delete this ${row.type}?`}
        onConfirm={handleDeleteFloderAndSlide}
      />
      <Alert
        open={unshareDialog}
        onDismiss={() => setUnshareDialog(false)}
        title={`Unshare ${row.name}`}
        content={`Are you sure to unshare ${row.name}?`}
        onConfirm={handleUnshare}
      />
    </Box>
  ) : null;
};
export default FileAndFolderActionMenu;
