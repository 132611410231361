import { styled } from '@mui/material/styles';
import { NavLink } from "react-router-dom";
import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  Drawer,
  List,
  useMediaQuery,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { getPublicImage } from '../../utils';
import { ChevronLeft } from '@mui/icons-material';

const openedMixin = ({ theme, drawerwidth }) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  width: '100vw',
  [theme.breakpoints.up('sm')]: {
    width: drawerwidth,
  }
})

const closedMixin = ({ theme }) => {
  return {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '0px',
    [theme.breakpoints.up('sm')]: {
      width: `calc(50px + 60px)`
    }
  }
}
export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))



const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open, drawerwidth }) => ({
  width: '100vw',
  [theme.breakpoints.up('sm')]: {
    width: drawerwidth
  },
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin({ theme, drawerwidth }),
    '& .MuiDrawer-paper': openedMixin({ theme, drawerwidth })
  }),
  ...(!open && {
    ...closedMixin({ theme }),
    '& .MuiDrawer-paper': closedMixin({ theme })
  })
}))

const CustomListItem = styled(ListItem)(({ theme }) => ({
  '& .MuiTypography-root, .MuiListItemIcon-root': {
    color: theme.palette.blue.main,
    fontWeight: 500,
  },
  '&.active': {
    '& .MuiTypography-root,.MuiListItemIcon-root': {
      color: theme.palette.hotPink.main,

    }
  },
}));

const AppDrawer = ({
  drawerwidth,
  menus,
  open,
  handleDrawerClose,
  handleDrawerOpen
}) => {
  const { palette, breakpoints } = useTheme()
  const smAndUp = useMediaQuery(breakpoints.up('sm'))
  return (
    <CustomDrawer
      variant="permanent"
      open={open}
      sx={{ zIndex: 1202 }}
      drawerwidth={drawerwidth}
    >
      <DrawerHeader
        sx={{
          justifyContent: 'flex-start',
          marginBottom: '90px',
          marginTop: '20px',

          [breakpoints.up('sm')]: {
            marginBottom: '150px',
            paddingLeft: '20px'
          }
        }}
      >
        <Box
          sx={{
            display: 'block',
            margin: open ? '0px auto' : '',
            cursor: 'pointer'
          }}
        >
          <Box
            onClick={() => window.open('https://www.targetedbioscience.com/', '_blank')}
            sx={{
              justifyContent: open ? 'initial' : 'center',
              display: 'flex',
              padding: '0px 10px',
              alignItems: 'center'
            }}
          >
            <img
              alt="logo"
              width="50"
              src={getPublicImage('logo.png')}
              style={{ marginRight: open ? '12px' : 'auto' }}
            />
            {open && (
              <ListItemText
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '24px!important',
                    fontWeight: 600,
                    lineHeight: '24px',
                    color: palette.blue.main
                  }
                }}
              >
                Targeted
                <br />
                Bioscience
              </ListItemText>
            )}
          </Box>
        </Box>
      </DrawerHeader>
      <List>
        {menus.map((menu, index) => (
          <CustomListItem
            key={menu.text}
            disablePadding
            sx={{
              display: 'block',
              margin: open ? '0px 0px 0px 60px' : '',
              width: 'unset',
              position: 'relative',
              '&.active&:after': {
                content: '""',
                width: '12px',
                height: '100%',
                top: 0,
                left: open ? '-60px' : '0px',
                background: palette.hotPink.main,
                position: 'absolute',
                borderRadius: '0px 10px 10px 0px'
              }
            }}
            component={NavLink}
            to={menu.to}
            onClick={handleDrawerClose}
          >
            <Tooltip title={menu.text} placement="right">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: '30px'
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center'
                  }}
                >
                  <menu.icon sx={{ fontSize: '50px' }} />
                </ListItemIcon>
                <ListItemText
                  primary={menu.text}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': {
                      fontSize: '35px!important'
                    }
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </CustomListItem>
        ))}
      </List>
      {!smAndUp && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '50px'
          }}
        >
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              // boxShadow:
              //   'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
              color: palette.blue.main,
              border: '2px solid',
              borderColor: palette.blue.main
            }}
          >
            <ChevronLeft sx={{ fontSize: '30px' }} />
          </IconButton>
        </Box>
      )}
    </CustomDrawer>
  )
}

export default AppDrawer;