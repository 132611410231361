import Tool from '@recogito/annotorious/src/tools/Tool';
import RubberbandRectangle from './RubberbandRectangle';
import EditableRectangle from './EditableRectangle';

/**
 * A rubberband selector for rectangle selections.
 */
export default class RubberbandRectangleTool extends Tool {
  constructor(g, config, env) {
    super(g, config, env);

    this.rubberband = null;
  }

  startDrawing = (x, y) => {
    this.attachListeners({
      mouseMove: this.onMouseMove,
      mouseUp: this.onMouseUp
    });

    this.rubberband = new RubberbandRectangle(
      x,
      y,
      this.g,
      this.env,
      this.config.color
    );
  };

  stop = () => {
    if (this.rubberband) {
      this.rubberband.destroy();
      this.rubberband = null;
    }
  };

  onMouseMove = (x, y) => this.rubberband.dragTo(x, y);

  onMouseUp = () => {
    this.detachListeners();
    this.started = false;

    const { width, height } = this.rubberband.getBoundingClientRect();

    const minWidth = this.config.minSelectionWidth || 4;
    const minHeight = this.config.minSelectionHeight || 4;

    if (width >= minWidth && height >= minHeight) {
      // Emit the SVG shape with selection attached
      const { element } = this.rubberband;
      element.annotation = this.rubberband.toSelection();
      
      // Emit the completed shape...
      this.emit('complete', element);
    } else {
      this.emit('cancel');
    }

    this.stop();
  };

  get isDrawing() {
    return this.rubberband != null;
  }

  createEditableShape = (annotation) => {
    let svgSelector = annotation.selector('SvgSelector');
    if (!svgSelector) {
      const fragSelector = annotation.selector('FragmentSelector');
      svgSelector = convertSelectorToSvg(fragSelector)
      const newAnnotation = annotation.clone(
        { "target": {
          selector:svgSelector,
          source:annotation.underlying.target.source
        }}
      )
      return new EditableRectangle(newAnnotation, this.g, this.config, this.env)
    }
    return new EditableRectangle(annotation, this.g, this.config, this.env)
  }
}

RubberbandRectangleTool.identifier = 'rect';

RubberbandRectangleTool.supports = (annotation) => {
  let svgSelector = annotation.selector('SvgSelector');
  if (!svgSelector) {
    const fragSelector = annotation.selector('FragmentSelector');
    svgSelector = convertSelectorToSvg(fragSelector)
  }
  if (svgSelector) return svgSelector.value?.match(/^<svg.*<rect/g);

};
function convertSelectorToSvg(selector) {
  const matches = selector.value.match(/[-+]?[0-9]*\.?[0-9]+/g);
  const [x, y, width, height] = matches.map(parseFloat);

  const svgSelector = {
    type: "SvgSelector",
    value: `<svg><rect x="${x}" y="${y}" width="${width}" height="${height}" /></svg>`,
    width: width
  };

  return svgSelector;
}