import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { debounce } from 'lodash';
import { useGetTreatmentGroupsQuery } from 'services/caseIdentifier';

const filter = createFilterOptions();

export const AutocompleteTreatmentGroup = ({
  isCreateable = false,
  isMulti = false,
  onChange,
  value,
  label = 'TreatmentGroup',
  placeholder = 'TreatmentGroup',
  sx = {}
}) => {
  const [treatmentGroupIds, setTreatmentGroups] = useState([]);

  const [treatmentGroupIdQuery, setTreatmentGroupQuery] = useState({
    limit: 100,
    page: 1,
    search: ''
  });

  const { data: fetchedTreatmentGroups } = useGetTreatmentGroupsQuery(
    treatmentGroupIdQuery,
    {
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    if (fetchedTreatmentGroups) {
      setTreatmentGroups(
        fetchedTreatmentGroups.map((treatmentGroupId) => ({
          value: treatmentGroupId,
          _id: treatmentGroupId
        }))
      );
    }
  }, [fetchedTreatmentGroups]);

  const debouncedSearch = debounce((search) => {
    setTreatmentGroupQuery({
      ...treatmentGroupIdQuery,
      search
    });
  }, 200);

  return (
    <Autocomplete
      multiple={isMulti}
      disableCloseOnSelect={isMulti}
      id="treatmentGroupIds-outlined"
      sx={sx}
      onBlur={() => {
        setTreatmentGroupQuery({
          ...treatmentGroupIdQuery,
          search: ''
        });
      }}
      options={
        treatmentGroupIds
          ? treatmentGroupIds.map((treatmentGroupId) => ({
              value: treatmentGroupId.value,
              _id: treatmentGroupId._id
            }))
          : []
      }
      freeSolo={isCreateable}
      onChange={(e, value) => {
        const convertTreatmentGroups = value.map((treatmentGroupId) => {
          if (typeof treatmentGroupId === 'string') {
            return {
              value: treatmentGroupId,
              _id: ''
            };
          }
          return treatmentGroupId;
        });
        // remove duplicate treatmentGroupIds
        const uniqueTreatmentGroups = convertTreatmentGroups.filter(
          (treatmentGroupId, index, self) =>
            index === self.findIndex((t) => t.value === treatmentGroupId.value)
        );
        // remove empty treatmentGroupIds
        const filteredTreatmentGroups = uniqueTreatmentGroups.filter(
          (treatmentGroupId) => treatmentGroupId.value.trim() !== ''
        );
        onChange && onChange(filteredTreatmentGroups);
      }}
      value={value || []}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const existingList = value ? [...value, ...options] : options;
        const isExisting = existingList.some((option) => {
          if (params.inputValue) {
            return option.value === params.inputValue;
          }
          return false;
        });
        if (params.inputValue !== '' && isCreateable && !isExisting) {
          filtered.push({
            _id: '',
            value: params.inputValue,
            label: `Add "${params.inputValue}"`
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.value;
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option) => (
        <li {...props}>{option.label || option.value}</li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          variant="outlined"
          placeholder={placeholder}
          label={label}
          value={treatmentGroupIdQuery.search}
          onChange={(e) => {
            e.persist();
            debouncedSearch(e.target.value);
          }}
          size="small"
        />
      )}
    />
  );
};
