import {TextField, Typography} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { forwardRef } from 'react';

export const styles = (theme) => ({
  '& .MuiInputBase-input': {
    padding: '10px 55px',
    backgroundColor: 'transparent!important',
    fontSize: '20px',
    '&::placeholder': {
      color: '#C8C8C8',
      opacity: 1
    }
  },
  '& .MuiInputBase-root': {
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus':
      {
        border: '1px solid none',
        WebkitTextFillColor: 'none',
        WebkitBoxshadow: '0 0 0px 1000px rgb(255, 255, 255) inset',
        transition: 'background-color 5000s ease-in-out 0s'
      }
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#CCCCCC',
      borderWidth: '2px',
      borderRadius: '5px'
    },
    '&:hover fieldset': {
      borderColor: '#CCCCCC'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main
    }
  }
});

export const StyledInput = styled(TextField)(({ theme }) => {
  return { ...styles(theme) };
});

    export const CustomInput = forwardRef(function CustomInput(props, ref) {
      const theme = useTheme();
      const { 'error-message' : errorMessage} = props
      return (
        <div style={props.style} className={props.className}>
          <StyledInput {...props} ref={ref} />
          <Typography
            variant="caption"
            sx={{ color: theme.palette.error.main }}
          >
            {errorMessage}
          </Typography>
        </div>
      )
    });