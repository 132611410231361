import { Grid, TextField } from '@mui/material';
import { AutocompleteAnnotationTitle } from 'components/Shared/AutocompleteAnnotationTitle';
import { AutocompleteAnnotationColors } from 'components/Shared/AutocompleteAnnotationColors';
import { AutocompleteAnnotationShape } from 'components/Shared/AutocompleteAnnotationShape';
import { AutocompleteBlockId } from 'components/Shared/AutocompleteBlockId';
import { AutocompletePatientId } from 'components/Shared/AutocompletePatientId';
import { AutocompleteProjectName } from 'components/Shared/AutocompleteProjectName';
import { AutocompleteRecut } from 'components/Shared/AutocompleteRecut';
import { AutocompleteSlideName } from 'components/Shared/AutocompleteSlideName';
import { AutocompleteSlideGroup } from 'components/Shared/AutocompleteSlideGroup';
import { AutocompleteTag } from 'components/Shared/AutocompleteTag';
import { AutocompleteTreatmentGroup } from 'components/Shared/AutocompleteTreatmentGroup';
import { AnalysisContext } from 'hooks/useAnalysisContext';
import React, { useContext } from 'react';
import { AnalysisBiomarker } from './AnalysisBiomarker';

export const AnalysisFilterForm = () => {
  const { state, saveState } = useContext(AnalysisContext);

  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        md={4}
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        gap={2}
      >
        <TextField
          fullWidth
          id={`analysis-filter-form-keyword`}
          label="Search"
          variant="outlined"
          size="small"
          value={state.filter}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          onChange={(e) => saveState({ filter: e.target.value })}
        />
        <AutocompleteProjectName
          placeholder="Project Names"
          label="Project Names"
          isCreateable={false}
          isMulti={true}
          value={state.projects || []}
          onChange={(value) => saveState({ projects: value })}
        />
        <AutocompleteSlideName
          placeholder="Slide Names"
          label="Slide Names"
          isCreateable={false}
          isMulti={true}
          value={state.slides || []}
          onChange={(value) => saveState({ slides: value })}
        />
        <AutocompleteSlideGroup
          label="Capture Groups"
          placeholder="Capture Groups"
          isCreateable={false}
          isMulti={true}
          value={state.slideGroups || []}
          onChange={(value) => saveState({ slideGroups: value })}
        />
        <AutocompleteAnnotationTitle
          placeholder="Annotation Titles"
          label="Annotation Titles"
          isCreateable={false}
          isMulti={true}
          value={state.annotationTitles || []}
          onChange={(value) => saveState({ annotationTitles: value })}
        />
        <Grid spacing={2} container>
          <Grid item xs={6}>
            <AutocompleteAnnotationShape
              placeholder="Annotation Shapes"
              label="Annotation Shapes"
              isCreateable={false}
              isMulti={true}
              value={state.annotationShapes || []}
              onChange={(value) => saveState({ annotationShapes: value })}
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteAnnotationColors
              placeholder="Annotation Colors"
              label="Annotation Colors"
              isCreateable={false}
              isMulti={true}
              value={state.annotationColors || []}
              onChange={(value) => saveState({ annotationColors: value })}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        gap={2}
      >
        <AutocompleteTag
          placeholder="Tags"
          label="Tags"
          isCreateable={false}
          isMulti={true}
          sx={{}}
          value={state.tags || []}
          onChange={(value) => saveState({ tags: value })}
        />
        <AutocompletePatientId
          placeholder="Patient Ids"
          label="Patient Ids"
          isCreateable={false}
          isMulti={true}
          sx={{}}
          value={state.patientIds || []}
          onChange={(value) => saveState({ patientIds: value })}
        />
        <AutocompleteBlockId
          placeholder="Block Ids"
          label="Block Ids"
          isCreateable={false}
          isMulti={true}
          sx={{}}
          value={state.blockIds || []}
          onChange={(value) => saveState({ blockIds: value })}
        />
        <AutocompleteTreatmentGroup
          placeholder="Treatment Groups"
          label="Treatment Groups"
          isCreateable={false}
          isMulti={true}
          sx={{}}
          value={state.treatmentGroups || []}
          onChange={(value) => saveState({ treatmentGroups: value })}
        />
        <AutocompleteRecut
          placeholder="Recuts"
          label="Recuts"
          isCreateable={false}
          isMulti={true}
          sx={{}}
          value={state.recuts || []}
          onChange={(value) => saveState({ recuts: value })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <AnalysisBiomarker />
      </Grid>
    </Grid>
  );
};
