import { api } from './api'
  
  export const appServiceAPI = api.injectEndpoints({
    endpoints: (build) => ({
      getNotifications: build.query({
        query: () => ({ url: '/notifications' }),
        transformResponse: (response, meta, arg) => {
          return {
            success: response.success,
            data: response.data
          };
        }
      }),
      readNotification: build.mutation({
        query: (id) => ({
          url: `/notifications/read/${id}`,
          method: 'GET'
        })
      }),
      readAllNotification: build.mutation({
        query: () => ({
          url: `/notifications/read-all`,
          method: 'GET'
        })
      }),
    })
  })
  
  export const {
    useGetNotificationsQuery,
    useReadNotificationMutation,
    useReadAllNotificationMutation
  } = appServiceAPI
  
  export const {
    endpoints: { getNotifications, readNotification, readAllNotification }
  } = appServiceAPI
  