import { api } from './api';

export const tagApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTags: build.query({
      query: (params) => ({ url: '/tags', params }),
      transformResponse: (response) => response.data
    }),
    createTags: build.mutation({
      query: (body) => ({
        url: '/tags',
        method: 'POST',
        body
      })
    })
  })
});

export const { useGetTagsQuery, useCreateTagsMutation } = tagApi;

export const {
  endpoints: { getTags, createTags }
} = tagApi;
