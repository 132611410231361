import { Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useMemo } from "react";

const Error403Dialog = ({handleClose, open, error}) => {

  const contents = useMemo(() => {
    if (error === 'unverified')
      return {
        title: 'Please Verify Your Account',
        description:
          'You cannot log in until your account has been verified. Please check your email address for <strong>Verification Link</strong>.<br />'
      }
    if (error === 'deactivated')
      return {
        title: 'Your Account Has Been Deactivated',
        description:
          'You cannot log in at the moment.<br />Please contact us for support.<br />'
      }
    return {
      title: 'Error',
      description:
        'You cannot log in at the moment.<br />Please contact us for support.<br />'
    }
  }, [error])
return ( open && 
  <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
    <DialogTitle sx={{ textAlign: 'center' }}>
      <Typography color="primary" sx={{ fontWeight: '600', fontSize: '20px' }}>
        {contents.title}
      </Typography>
      <Typography
        mt={2}
        dangerouslySetInnerHTML={{ __html: contents.description }}
      ></Typography>
    </DialogTitle>
    <DialogContent sx={{ textAlign: 'center' }}>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleClose}
        sx={{ mt: 2 }}
      >
        Close
      </Button>
    </DialogContent>
  </Dialog>
)
}
export default Error403Dialog;