import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import Alert from 'components/Alert';
import useToggleState from 'hooks/useToggleState';
import { useState } from 'react';

const CommentDeleteButton = ({ id, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [alertVisible, openDeleteAlert, closeDeleteAlert] =
    useToggleState(false);

  const handleDeleteComment = async () => {
    if (isDeleting) return;
    closeDeleteAlert();
    setTimeout(() => {
      onDelete(id, () => setIsDeleting(false));
    }, 1000);
  };

  return (
    <>
      <Button
        size="small"
        color="error"
        display="none"
        sx={{ minWidth: 'unset' }}
        variant="contained"
        onClick={openDeleteAlert}
      >
        <DeleteIcon fontSize="xs" />
      </Button>
      <Alert
        content="Are you sure to delete this comment?"
        onConfirm={handleDeleteComment}
        open={alertVisible}
        onDismiss={closeDeleteAlert}
        title="Delete comment"
      />
    </>
  );
};

export default CommentDeleteButton;
