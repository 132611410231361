import {
    SHARED_WITH_ME_FOLDER_NAME
} from '../constants';

export const navigateNotification = async (link, navigate, handleSetCurrentView) => {
    const url = new URL(link);
    const pathname = url.pathname;
    const pathnameParts = pathname.split('/').filter(part => part !== '');

    if(pathnameParts[0] === 'projects') {
        await handleSetCurrentView(pathnameParts[1], SHARED_WITH_ME_FOLDER_NAME);
        navigate(`/slidebox`);
    } else {
        window.location.href = link;
    }
};
