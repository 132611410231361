import React, { useState, useMemo } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import {
  AddOutlined as AddOutlinedIcon,
  Folder as FolderIcon,
  CreateNewFolder as CreateNewFolderIcon
} from '@mui/icons-material';
// Components
// Stores and Services
import { selectCurrentFolderView } from '../../stores/slidebox/slideboxSlice';
// Constants
import {
  FILE_TYPES,
  MANAGED_BY_ME_FOLDER_NAME,
  SHARED_WITH_ME_FOLDER_NAME,
  ROLES
} from '../../constants';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';
import { useSelector } from 'react-redux';
import { useAuth } from 'hooks/useAuth';

const CreateSlidebox = ({ children }) => {
  const { setDialogData } = useSlideboxContext();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const openMenu = Boolean(menuAnchorEl);
  const {
    user: { role }
  } = useAuth();
  const isCollaborator = useMemo(() => role === ROLES.COLLABORATOR, [role]);
  const handleOpenMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleOpenDialog = (type) => {
    setDialogData('project', { type });
    handleCloseMenu();
  };

  const { _id, type: currentFolderType } = useSelector(selectCurrentFolderView);

  const btnDisabled =
    currentFolderType === FILE_TYPES.CASE ||
    _id === MANAGED_BY_ME_FOLDER_NAME ||
    _id === SHARED_WITH_ME_FOLDER_NAME;

  return (
    <>
      {children && children({ onClick: handleOpenMenu, disabled: btnDisabled })}
      { (!children && !isCollaborator ) && (
        <Button
          startIcon={<AddOutlinedIcon />}
          sx={{
            alignSelf: 'center',
            marginBottom: '12px',
            marginLeft: '12px',
            paddingLeft: '24px',
            paddingRight: '24px'
          }}
          disableElevation
          variant="contained"
          component="label"
          onClick={handleOpenMenu}
          disabled={btnDisabled}
        >
          New
        </Button>
      )}
      <Menu
        id="menu-create-new-slidebox"
        anchorEl={menuAnchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
      >
        {/* <MenuItem onClick={() => handleOpenDialog('folder')}>
          <FolderIcon />
          &nbsp;Folder
        </MenuItem> */}
        <MenuItem onClick={() => handleOpenDialog('case')}>
          <CreateNewFolderIcon />
          &nbsp;Project
        </MenuItem>
      </Menu>
    </>
  );
};
export { CreateSlidebox };
