import { api } from './api';

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMembers: build.query({
      query: () => ({ url: '/users/members' }),
      transformResponse: (response) => response?.data
    }),
    getUploads: build.query({
      query: () => ({ url: '/uploads' }),
      providesTags: ({ data }) => [
        ...data.map(({ _id }) => ({ type: 'Uploads', _id })),
        { type: 'Uploads', id: 'LIST' }
      ]
    }),
    getUpload: build.query({
      query: (id) => `/uploads/${id}`
    }),
    addUpload: build.mutation({
      query: (body) => ({
        url: `/uploads`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Uploads', id: 'LIST' }]
    }),
    resetPassword: build.mutation({
      query: (body) => ({ url: '/users/resetPassword/', method: 'PATCH', body })
    }),
    invite: build.mutation({
      query: (body) => ({
        url: '/users/invite',
        method: 'POST',
        body
      })
    }),
    cancelInvite: build.mutation({
      query: (id) => ({
        url: `/users/cancel-invite/${id}`,
        method: 'DELETE'
      })
    }),
    forgotPassword: build.mutation({
      query: (body) => ({
        url: '/users/forgotPassword/',
        method: 'POST',
        body
      })
    }),
    verifyAccount: build.mutation({
      query: (userId) => ({
        url: `/users/verifyAccount/${userId}`,
        method: 'POST'
      })
    }),
    updateUserStatus: build.mutation({
      query: (id) => ({
        url: `/users/${id}/status`,
        method: 'POST'
      })
    }),
    updateUserRole: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/users/${id}/role`,
        method: 'PATCH',
        body: patch
      })
    }),
    updateUser: build.mutation({
      query: ({ id, ...patch }) => {
        console.log('patch:', patch);
        return {
          url: `/users/${id}`,
          method: 'PUT',
          body: patch
        };
      }
    }),
    createUser: build.mutation({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body
      })
    }),
    deleteUser: build.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE'
      })
    }),
    getUsersInOrganization: build.mutation({
      query: () => ({ url: '/users/members', method: 'GET' }),
      transformResponse: (response) => response.data
    }),
    updateUserCollaboratorLimit: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/users/${id}/collaboratorLimit`,
        method: 'PATCH',
        body: patch
      })
    }),
    getCollaborators: build.query({
      query: () => 'collaborators',
      transformResponse: (response) => response?.data
    }),
    getUser: build.query({
      query: (id) => `/users/${id}`
    })
  })
});

export const {
  useGetUploadsQuery,
  useAddUploadMutation,
  useGetUploadQuery,
  useGetMembersQuery,
  useResetPasswordMutation,
  useInviteMutation,
  useCancelInviteMutation,
  useForgotPasswordMutation,
  useVerifyAccountMutation,
  useUpdateUserStatusMutation,
  useUpdateUserRoleMutation,
  useDeleteUserMutation,
  useGetUsersInOrganizationMutation,
  useUpdateUserCollaboratorLimitMutation,
  useGetCollaboratorsQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useCreateUserMutation
} = userApi;

export const {
  endpoints: {
    getUploads,
    getUpload,
    addUpload,
    getAllUsers,
    getShareableMembers
  }
} = userApi;
