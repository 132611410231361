import {
    Box,
    IconButton,
    TextField,
    LinearProgress,
    Stack,
    Tooltip
  } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { isEmpty } from 'lodash';
import MentionInput from 'components/MentionInput';
import useTaggableUsers from 'hooks/useTaggableUsers';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import ArticleIcon from '@mui/icons-material/Article';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ToggleButton from '@mui/material/ToggleButton';

const CommentBox = ({ comment, onSubmit }) => {
  const taggableUsers = useTaggableUsers({ enableFetch: true });
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState('');
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const inputAccept = '.c, .cs, .cpp, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .js, .sh, .ts';
  const [isBiomarkerContext, setIsBiomarkerContext] = useState(false);

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFile(file);
    }
  };

  const handleAttachClick = () => {
    fileInputRef.current.click();
  };

  const handleBiomarkerToggle = () => {
    setIsBiomarkerContext(!isBiomarkerContext);
  };

  useEffect(() => {
    if (comment) setText(comment);
  }, [comment]);

  const handleSubmit = async () => {
    if (!onSubmit) return;
    if (isEmpty(text?.trim())) return;
    if (isLoading) return;
    setIsLoading(true);
    await onSubmit(text, file, isBiomarkerContext, () => {
      setIsLoading(false);
      setText('');
      setFile(null);
      setFileName('');
    });
  };

  return (
    <Stack spacing={1}>
      <Box>{isLoading && <LinearProgress />}</Box>
      <Stack alignItems="flex-start" spacing={1}>
        <MentionInput
          rows={2}
          sx={{ fontSize: '0.85rem', maxHeight: '150px' }}
          inputStyles={{
            overflowY: 'scroll',
            fontSize: '0.85rem',
            border: 'none',
            outline: 'none'
          }}
          placeholder="Write a comment..."
          value={text}
          disabled={isLoading}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSubmit();
            }
          }}
          data={taggableUsers}
        />
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          p={1}
          justifyContent={'space-between'}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={0.5}
            justifyContent={'flex-start'}
          >
            <Tooltip title="Click to upload file">
              <IconButton onClick={handleAttachClick} variant="contained" size="small">
                <AttachFileIcon />
                <input
                  ref={fileInputRef}
                  accept={inputAccept}
                  type="file"
                  hidden
                  onChange={handleChange}
                />
              </IconButton>
            </Tooltip>
            {fileName && (
              <TextField
                variant="outlined"
                size="small"
                value={fileName}
                InputProps={{
                  style: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                  readOnly: true,
                }}
              />
            )}
            <Tooltip title="Toggle for biomarker context awareness">
              <ToggleButton
              value="biomarkercontextawareness"
              selected={isBiomarkerContext}
              onChange={handleBiomarkerToggle}
              variant="contained"
              size="small"
              >
                {isBiomarkerContext ? (
                    <ArticleIcon sx={{ color: 'green' }} />
                  ) : (
                    <ArticleIcon />
                  )}
              </ToggleButton>
            </Tooltip>
          </Box>

          <IconButton
            variant="contained"
            size="small"
            disabled={isEmpty(text?.trim()) || isLoading}
            onClick={handleSubmit}
          >
            {comment ? <CheckIcon /> : <SendIcon />}
          </IconButton>
        </Box>
      </Stack>
    </Stack>
  );
};

export default CommentBox;
