import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Input,
  InputLabel,
  Stack,
  Typography
} from '@mui/material';
import { useGetMembersQuery } from '../../../services/user';
import {
  TextFieldElement,
  AutocompleteElement,
  useForm
} from 'react-hook-form-mui';
import { useSnackbar } from '../../../hooks/useSnackbar';

import { useWS, webSocketType } from 'contexts/WebSocketContext';
import { isAccessGroup } from 'utils/websocket';
import { useAuth } from 'hooks/useAuth';
import { useGroupsContext } from 'contexts/GroupsContext';
import { LoadingButton } from '@mui/lab';

function NewGroupDialog({ open, onClose }) {
  const { createGroup, lastEvent } = useWS();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, data } = useGetMembersQuery();
  const { setSelectedGroup } = useGroupsContext();
  const { control, handleSubmit } = useForm({
    defaultValues: {}
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const members =
    data?.map((item) => ({
      id: item._id,
      label: `${item.firstName} ${item.lastName}`.trim()
    })) ?? [];

  async function submit(formData) {
    try {
      setIsSubmitting(true);
      createGroup(formData);
    } catch (e) {
      setIsSubmitting(false);
      enqueueSnackbar(e.message || 'Something went wrong!', {
        variant: 'error'
      });
    }
  }

  React.useEffect(() => {
    if (!lastEvent) return;
    if (!isSubmitting) return;
    if (isAccessGroup(lastEvent, user._id, webSocketType.ROOM_CREATED)) {
      setIsSubmitting(false);
      onClose?.();
      enqueueSnackbar('Create group successfully', { variant: 'success' });
      setSelectedGroup?.(lastEvent.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastEvent, user._id, isSubmitting]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <form noValidate id="group-create-form" onSubmit={handleSubmit(submit)}>
          <Grid container spacing={3}>
            <Grid item>
              <Stack alignItems="center" spacing={2}>
                <Typography variant="h5">New Group</Typography>
                <InputLabel htmlFor="group-cover">
                  <Avatar src="https://via.placeholder.com/150" />
                </InputLabel>
                <Box hidden>
                  <Input
                    type="file"
                    name="file"
                    id="group-cover"
                    inputProps={{ accept: 'image/png, image/jpeg' }}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item flex={1} flexDirection="column" spacing={1} container>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <InputLabel required htmlFor="group-name">
                    Group name
                  </InputLabel>
                  <TextFieldElement
                    control={control}
                    name="name"
                    fullWidth
                    id="group-name"
                    required
                  />
                </Stack>
                <Stack spacing={1}>
                  <InputLabel htmlFor="group-about">About</InputLabel>
                  <TextFieldElement
                    control={control}
                    fullWidth
                    id="group-about"
                    multiline
                    minRows={4}
                    maxRows={10}
                    name="description"
                  />
                </Stack>
                <Stack spacing={1}>
                  <InputLabel required htmlFor="group-members">
                    Members
                  </InputLabel>
                  <AutocompleteElement
                    control={control}
                    loading={isLoading}
                    multiple
                    matchId
                    id="group-members"
                    name="members"
                    options={members}
                    required
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="gray"
          disableElevation
          disabled={isSubmitting}
        >
          Back
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          form="group-create-form"
          loading={isSubmitting}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default NewGroupDialog;
