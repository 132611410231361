import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Autocomplete,
  useTheme,
  Divider
} from '@mui/material';

import { useGetProjectDetailsMutation } from '../../../../services/projects';
import ChipsArray from '../../../../components/Chips';

import {
  selectCurrentFolderView,
  updatebyId
} from '../../../../stores/slidebox/slideboxSlice';
import { FormContainer, useFieldArray, useForm } from 'react-hook-form-mui';
import { LoadingButton } from '@mui/lab';
import { PersonAddAlt1Outlined } from '@mui/icons-material';
import {
  StyledInput,
  styles as inputStyles
} from '../../../../components/Shared/Input';
import {
  useShareEntityMutation,
  useGetShareableUsersMutation,
  useGetShareableUsersByEntitiesMutation
} from 'services/share';
import { useGetFileByIdMutation } from 'services/slides';
import { FILE_TYPES, ROLES } from '../../../../constants';
import { useAuth } from '../../../../hooks/useAuth';
import ErrorDialog from 'components/Dialog/ErrorDialog';

const ShareButton = ({ entities, calledFrom, shareClicked }) => {
  const theme = useTheme();
  const [getProjectDetails] = useGetProjectDetailsMutation();
  const [getFileById, {}] = useGetFileByIdMutation();
  const {
    user: { email: loggedEmail, role: loggedRole }
  } = useAuth();

  const [shareEntity, { isLoading: isSharing }] = useShareEntityMutation();
  const [getShareableUsers, { isLoading: isLoadingShareableUsers }] =
    useGetShareableUsersMutation();
  const [getShareableUsersByEntities] =
    useGetShareableUsersByEntitiesMutation();
  const [shareableUsers, setShareableUsers] = useState([]);

  const { _id: id } = useSelector(selectCurrentFolderView);

  const { enqueueSnackbar } = useSnackbar();

  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [disableShare, setDisableShare] = useState(false);

  useEffect(() => {
    if (shareClicked > 0) {
      handleOpenShareDialog();
    }
  }, [shareClicked]);

  const reduxDispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState('');

  const [inputValue, setInputValue] = React.useState('');
  const onCloseErrorDialog = () => {
    setErrorMessage('');
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    getValues
  } = useForm({
    defaultValues: {
      emails: ['']
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails'
  });

  const handleOpenShareDialog = async () => {
    let users = [];
    let entityIds = [];
    entities.map((entity) => entityIds.push(entity._id));
    if (entities.length === 1) {
      const entity = entities[0];
      users = await getShareableUsers({
        id: entity._id,
        type: getSharedEntityType(entity.type)
      }).unwrap();
    } else {
      users = await getShareableUsersByEntities({
        ids: entityIds,
        type: getSharedEntityType(entities[0].type)
      }).unwrap();
    }
    let tempUsers = [];
    users.users.concat(users.existingUsers).forEach((user) => {
      if (typeof(user) !== 'undefined' && user?.email !== loggedEmail) {
        tempUsers.push(user);
      }
    });
    setShareableUsers(tempUsers);
    users.existingUsers.forEach((user) => {
      if (user.email !== loggedEmail) {
        checkExistingUser({
          ...user,
          label: `${user.firstName} ${user.lastName} (${user.email})`,
          name: `${user.firstName} ${user.lastName}`
        });
      }
    });
    setOpenShareDialog(true);
    setShareButtonState();
  };

  const memberOptions = useMemo(() => {
    if (!shareableUsers.length) return [];
    const selectedEmails = fields.map((field) => field.emails);
    return shareableUsers
      .map((m) => ({
        label: `${m.firstName} ${m.lastName} (${m.email})`,
        name: `${m.firstName} ${m.lastName}`,
        email: m.email
      }))
      .filter((el) => !selectedEmails.includes(el.email));
  }, [shareableUsers, fields]);

  const checkExistingUser = (email) => {
    const selectedUsers = getValues('emails');
    if (email) {
      const isPresent = selectedUsers.some(
        (item) => item.emails === email.email
      );
      if (!isPresent) {
        append({ emails: email.email, name: email.name, image: email.image });
      }
    }
    setInputValue('');
  };
  const handleCloseShareDialog = () => {
    setOpenShareDialog(false);
    reset();
  };
  const getSharedEntityType = (type) => {
    return type === FILE_TYPES.FOLDER || type === FILE_TYPES.CASE
      ? 'project'
      : 'slide';
  };

  const handleConfirmShare = async (form) => {
    try {
      const promises = entities.map((entity) => {
        const { _id } = entity;
        const type = getSharedEntityType(entity.type);
        const values = form.emails.map((email) => email.emails);
        return shareEntity({
          id: entity._id,
          emails: values,
          type
        })
          .unwrap()
          .then((res) => {
            if (type === 'slide') {
              getFileById(_id)
                .unwrap()
                .then(({ data }) => {
                  reduxDispatch(updatebyId({ ...data, parent: id }));
                });
            } else {
              getProjectDetails(_id)
                .unwrap()
                .then(({ data }) => {
                  reduxDispatch(updatebyId({ ...data, parent: id }));
                });
            }
          });
      });
      await Promise.all(promises);
      handleCloseShareDialog();
      reset();
      enqueueSnackbar('Share successfully', { variant: 'success' });
    } catch (error) {
      const { data } = error;
      setErrorMessage(
        data && typeof data.error === 'string'
          ? data.error
          : 'Something went wrong!'
      );
    }
  };
  const setShareButtonState = () => {
    let ownItems = true;
    entities.forEach((entity) => {
      if (entity.createdBy.email !== loggedEmail) {
        ownItems = false;
      }
    });

    if (loggedRole === ROLES.SUPER_ADMIN || loggedRole === ROLES.ADMIN) {
      setDisableShare(false);
    } else {
      setDisableShare(!ownItems);
    }
  };
  return (
    <Box style={{ marginLeft: '4px' }}>
      {calledFrom !== 'menu' && (
        <Tooltip title="Share with">
          <Button variant="outlined" onClick={handleOpenShareDialog}>
            <PersonAddAlt1Outlined
              fontSize="small"
              sx={{ marginRight: '4px' }}
            />{' '}
            Share with
          </Button>
        </Tooltip>
      )}
      <Dialog open={openShareDialog} maxWidth="sm" fullWidth keepMounted>
        <FormContainer onSuccess={handleSubmit(handleConfirmShare)}>
          <Divider />
          <DialogContent sx={{ maxHeight: '400px', overflow: 'auto' }}>
            {fields.length > 0 && (
              <ChipsArray
                items={fields}
                handleDelete={(id) => remove(id)}
                disableShare={disableShare}
              />
            )}
            {!disableShare && (
              <Autocomplete
                inputValue={inputValue}
                value={inputValue}
                sx={{ mt: '4px' }}
                onInputChange={(event, newValue) => {
                  setInputValue(newValue);
                }}
                onChange={(event, newValue) => {
                  checkExistingUser(newValue);
                }}
                id="controllable-states-demo"
                options={memberOptions}
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
                onKeyUp={(event) => {
                  event.stopPropagation();
                }}
                onKeyPress={(event) => {
                  event.stopPropagation();
                }}
                renderInput={(params) => (
                  <StyledInput
                    fullWidth
                    {...params}
                    sx={{
                      ...inputStyles(theme),
                      '& .MuiInputBase-input': {
                        padding: '7px 16px!important',
                        fontSize: '16px !important',
                        height: '0.4375em !important'
                      }
                    }}
                    name={`emails`}
                    aria-describedby="email"
                    placeholder="Add Users"
                  />
                )}
              />
            )}
          </DialogContent>
          <Divider />
          <DialogActions>
            {!isSharing && (
              <Button
                variant="contained"
                color="buttonLightGray"
                disableElevation
                onClick={handleCloseShareDialog}
              >
                Cancel
              </Button>
            )}
            {!disableShare && (
              <LoadingButton
                loading={isSharing}
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                disabled={disableShare}
              >
                Share
              </LoadingButton>
            )}
          </DialogActions>
        </FormContainer>
      </Dialog>
      <ErrorDialog errorMessage={errorMessage} onClose={onCloseErrorDialog} />
    </Box>
  );
};
export default ShareButton;
