import { api } from './api';

export const shareApi = api.injectEndpoints({
  endpoints: (build) => ({
    shareEntity: build.mutation({
      query: ({ id, emails, type }) => ({
        url: `/sharedEntities/share`,
        method: 'POST',
        body: { emails, id, type }
      })
    }),
    getShareableUsers: build.mutation({
      query: ({ id, type }) => ({
        url: `/sharedEntities/shareableUsers?id=${id}&type=${type}`
      }),
      transformResponse: (response) => response.data
    }),
    getShareableUsersByEntities: build.mutation({
      query: ({ ids, type }) => ({
        url: `/sharedEntities/shareableUsersByEntities?ids=${ids}&type=${type}`
      }),
      transformResponse: (response) => response.data
    }),
    deleteSharedEntity: build.mutation({
      query: (id) => ({
        url: `/sharedEntities/${id}`,
        method: 'DELETE'
      })
    }),
  })
});

export const { useShareEntityMutation, useGetShareableUsersMutation, useGetShareableUsersByEntitiesMutation, useDeleteSharedEntityMutation } =
  shareApi;

export const {
  endpoints: { shareEntity }
} = shareApi;
