import { TextField } from '@mui/material';
import React from 'react';

const FolderForm = ({ register, watch }) => {
  return (
    <TextField
      fullWidth
      autoFocus
      size="small"
      margin="dense"
      type="text"
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: '2px solid #CCCCCC',
            borderRadius: '5px'
          }
        }
      }}
      variant="outlined"
      placeholder="Enter folder name"
      id="name"
      name="name"
      {...register('name', { required: true })}
    />
  );
};

export { FolderForm };
