import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const listOperators = [
  {
    value: 'equals',
    label: 'Equals'
  },
  // {
  //   value: 'notEquals',
  //   label: 'Does Not Equal'
  // },
  {
    value: 'contains',
    label: 'Contains'
  },
  // {
  //   value: 'notContains',
  //   label: 'Does Not Contain'
  // },
  {
    value: 'gte',
    label: 'Greater Than'
  },
  {
    value: 'lte',
    label: 'Less Than'
  }
  // {
  //   value: 'gt',
  //   label: 'Greater Than or Equal To'
  // },
  // {
  //   value: 'lt',
  //   label: 'Less Than or Equal To'
  // },

  // {
  //   value: 'startsWith',
  //   label: 'Starts With'
  // },
  // {
  //   value: 'endsWith',
  //   label: 'Ends With'
  // }
];

export const AutocompleteOperator = ({
  isMulti = false,
  onChange,
  value,
  label = 'Operator',
  placeholder = 'Operator',
  sx = {}
}) => {
  const buildValue = (value) => {
    if (!value) return isMulti ? [] : null;
    if (isMulti) {
      return listOperators.filter((operator) => value.includes(operator.value));
    } else {
      return listOperators.find((operator) => operator.value === value);
    }
  };

  return (
    <Autocomplete
      multiple={isMulti}
      id="operator-outlined"
      options={listOperators}
      disableCloseOnSelect={isMulti}
      size="small"
      sx={sx}
      value={buildValue(value)}
      onChange={(event, newValue) => {
        if (!newValue) return onChange('');
        if (isMulti) {
          onChange(newValue.map((operator) => operator.value));
        } else {
          onChange(newValue.value);
        }
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      renderInput={(params) => (
        <TextField
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          {...params}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};
