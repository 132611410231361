export const handleBackendError = (
  error: any
): {
  message: string;
  errors?: {
    [key: string]: string;
  };
} => {
  if (typeof error === 'string') return { message: error };
  try {
    const data = error.data;
    if (typeof data === 'string') return { message: data };
    if (!data)
      return {
        message: error.response?.data?.message || 'Something went wrong'
      };

    const errors = Object.keys(data).reduce((acc: any, key) => {
      acc[key] = data[key].join(' ');
      return acc;
    }, {});

    return {
      message: error.response?.data?.message || errors[Object.keys(errors)[0]],
      errors
    };
  } catch (error) {
    return { message: 'Something went wrong' };
  }
};
