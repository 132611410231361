import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { debounce } from 'lodash';
import { useGetRecutsQuery } from 'services/caseIdentifier';

const filter = createFilterOptions();

export const AutocompleteRecut = ({
  isCreateable = false,
  isMulti = false,
  onChange,
  value,
  label = 'Recut',
  placeholder = 'Recut',
  sx = {}
}) => {
  const [recutIds, setRecuts] = useState([]);

  const [recutIdQuery, setRecutQuery] = useState({
    limit: 100,
    page: 1,
    search: ''
  });

  const { data: fetchedRecuts } = useGetRecutsQuery(recutIdQuery, {
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (fetchedRecuts) {
      setRecuts(
        fetchedRecuts.map((recutId) => ({
          value: recutId,
          _id: recutId
        }))
      );
    }
  }, [fetchedRecuts]);

  const debouncedSearch = debounce((search) => {
    setRecutQuery({
      ...recutIdQuery,
      search
    });
  }, 200);

  return (
    <Autocomplete
      multiple={isMulti}
      disableCloseOnSelect={isMulti}
      id="recutIds-outlined"
      sx={sx}
      onBlur={() => {
        setRecutQuery({
          ...recutIdQuery,
          search: ''
        });
      }}
      options={
        recutIds
          ? recutIds.map((recutId) => ({
              value: recutId.value,
              _id: recutId._id
            }))
          : []
      }
      freeSolo={isCreateable}
      onChange={(e, value) => {
        const convertRecuts = value.map((recutId) => {
          if (typeof recutId === 'string') {
            return {
              value: recutId,
              _id: ''
            };
          }
          return recutId;
        });
        // remove duplicate recutIds
        const uniqueRecuts = convertRecuts.filter(
          (recutId, index, self) =>
            index === self.findIndex((t) => t.value === recutId.value)
        );
        // remove empty recutIds
        const filteredRecuts = uniqueRecuts.filter(
          (recutId) => recutId.value.trim() !== ''
        );
        onChange && onChange(filteredRecuts);
      }}
      value={value || []}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const existingList = value ? [...value, ...options] : options;
        const isExisting = existingList.some((option) => {
          if (params.inputValue) {
            return option.value === params.inputValue;
          }
          return false;
        });
        if (params.inputValue !== '' && isCreateable && !isExisting) {
          filtered.push({
            _id: '',
            value: params.inputValue,
            label: `Add "${params.inputValue}"`
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.value;
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option) => (
        <li {...props}>{option.label || option.value}</li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          variant="outlined"
          placeholder={placeholder}
          label={label}
          value={recutIdQuery.search}
          onChange={(e) => {
            e.persist();
            debouncedSearch(e.target.value);
          }}
          size="small"
        />
      )}
    />
  );
};
