import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import Alert from 'components/Alert';
import useToggleState from 'hooks/useToggleState';
import { useSnackbar } from 'hooks/useSnackbar';
import { useDeleteSlideCommentMutation } from 'services/slides';
import useSlideCommentSocket from 'hooks/useSlideCommentSocket';

const CommentDeleteButton = ({ commentId, slideId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { emitSlideCommentDeleted } = useSlideCommentSocket(slideId);
  const [deleteComment, { isLoading: isDeleting }] =
    useDeleteSlideCommentMutation();
  const [alertVisible, openDeleteAlert, closeDeleteAlert] =
    useToggleState(false);

  const handleDeleteComment = async () => {
    if (isDeleting || !commentId) return;
    closeDeleteAlert();
    try {
      await deleteComment({ slideId, commentId });
      emitSlideCommentDeleted({ _id: commentId, slideId });
    } catch (e) {
      console.warn(e);
      enqueueSnackbar(e.message || 'Something went wrong!', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <IconButton onClick={openDeleteAlert} size="small" color="buttonGray">
        <DeleteIcon fontSize="xs" />
      </IconButton>
      <Alert
        content="Are you sure to delete this comment?"
        onConfirm={handleDeleteComment}
        open={alertVisible}
        onDismiss={closeDeleteAlert}
        title="Delete comment"
      />
    </>
  );
};

export default CommentDeleteButton;
