import { useDispatch } from 'react-redux';
import { useSnackbar } from '../useSnackbar';

import {
  DEFAULT_ROOT_FOLDER_NAME,
  MANAGED_BY_ME_FOLDER_NAME,
  SHARED_WITH_ME_FOLDER_NAME
} from '../../constants';

import {
  useGetAllManagedProjectsMutation,
  useGetProjectDetailsMutation,
  useGetProjectFolderChildrenByIdMutation,
  useGetRootProjectsMutation,
  useGetSharedWithMeProjectsMutation
} from '../../services/projects';

import {
  pushFolersAndFiles,
  setBreadcrumbs,
  setCurrentFolderView,
  setFilters,
  setPage,
  setRowsPerPage,
  setState
} from '../../stores/slidebox/slideboxSlice';

export const defaultSlideBoxContextState = {
  selectedNodeId: '',
  selectedNodeType: '',
  openDetail: false,
  openMoveFolder: false,
  selectedRows: []
};

export const slideBoxContextReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE': {
      return { ...state, [action.key]: action.value };
    }
    case 'TOGGLE_SELECTED_ROW': {
      const cloned = JSON.parse(JSON.stringify(state.selectedRows));
      const found = cloned.findIndex((r) => r._id === action.value._id);
      if (found !== -1) {
        cloned.splice(found, 1);
      } else {
        cloned.push(action.value);
      }
      return { ...state, selectedRows: cloned };
    }
    default:
      return state;
  }
};

const slidebox = {
  _id: null,
  name: DEFAULT_ROOT_FOLDER_NAME,
  ancestors: [],
  createdByEmail: null
};

const managedByMe = {
  _id: MANAGED_BY_ME_FOLDER_NAME,
  name: MANAGED_BY_ME_FOLDER_NAME,
  ancestors: [],
  createdByEmail: null
};

const sharedWithMe = {
  _id: SHARED_WITH_ME_FOLDER_NAME,
  name: SHARED_WITH_ME_FOLDER_NAME,
  ancestors: [],
  createdByEmail: null
};

const generateBreadcrumbs = (data, ancestors, result = []) => {
  if (!data) return result.filter((x) => x !== undefined);
  if (
    data &&
    (data._id === null ||
      data._id === MANAGED_BY_ME_FOLDER_NAME ||
      data._id === SHARED_WITH_ME_FOLDER_NAME)
  ) {
    result.unshift(data);
    return result;
  }
  result.unshift(data);
  let parent = {};
  if (!data.parent) {
    parent = slidebox;
  } else if (data.parent === MANAGED_BY_ME_FOLDER_NAME) {
    parent = managedByMe;
  } else if (data.parent === SHARED_WITH_ME_FOLDER_NAME) {
    parent = sharedWithMe;
  } else {
    parent = ancestors.find((e) => e._id === data.parent);
  }
  return generateBreadcrumbs(parent, ancestors, result);
};

export const useSlideBox = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [getRootProjects] = useGetRootProjectsMutation();

  const [getAllManagedProjects] = useGetAllManagedProjectsMutation();
  const [getProjectDetails] = useGetProjectDetailsMutation();
  const [getSharedWithMeProjects] = useGetSharedWithMeProjectsMutation();

  const [getProjectFolderChildrenById] =
    useGetProjectFolderChildrenByIdMutation();

  const handleSlidebox = async () => {
    dispatch(setCurrentFolderView({ ...slidebox }));
    dispatch(setBreadcrumbs([{ ...slidebox }]));
    try {
      const response = await getRootProjects().unwrap();
      dispatch(pushFolersAndFiles(response.data));
    } catch (err) {
      console.log(err);
    }
  };

  const handleManagedByMe = async () => {
    dispatch(setCurrentFolderView({ ...managedByMe }));
    dispatch(setBreadcrumbs([{ ...managedByMe }]));
    try {
      const response = await getAllManagedProjects().unwrap();
      dispatch(pushFolersAndFiles(response.data));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSharedWithMe = async () => {
    dispatch(setCurrentFolderView({ ...sharedWithMe }));
    dispatch(setBreadcrumbs([{ ...sharedWithMe }]));
    try {
      const response = await getSharedWithMeProjects().unwrap();
      dispatch(pushFolersAndFiles(response.data));
    } catch (err) {
      console.log(err);
    }
  };

  const handleFolder = async (id, parentId) => {
    // get current view folder details
    let clonedData;
    try {
      const { data } = await getProjectDetails(id).unwrap();

      clonedData = { ...data };
      if (parentId === MANAGED_BY_ME_FOLDER_NAME) {
        clonedData.parent = MANAGED_BY_ME_FOLDER_NAME;
      }
  
      if (parentId === SHARED_WITH_ME_FOLDER_NAME) {
        clonedData.parent = SHARED_WITH_ME_FOLDER_NAME;
      }
  
      dispatch(
        setCurrentFolderView({
          ...clonedData,
          createdByEmail: clonedData.createdBy.email
        })
      );
      const breadcrumbs = generateBreadcrumbs(
        clonedData,
        clonedData.ancestors,
        []
      );
      dispatch(setBreadcrumbs(breadcrumbs));
    }
    catch (err) {
      console.log(err);
    }

    // get current view folder children
    try {
      const response = await getProjectFolderChildrenById(id).unwrap();
      dispatch(pushFolersAndFiles(response.map((slide) => {
        if (slide.project === id) {
          return {
            ...slide,
            parentCaseIdentifiers: clonedData?.caseIdentifiers
          }
        }
        return slide;
      })));
      // Push current view and its Parent to breadcrumbs
    } catch (err) {
      enqueueSnackbar(
        err.data.error,
        {
          variant: 'error'
        }
      );
      console.log(err);
    }
  };

  const handleSetCurrentView = async (id, parentId = null) => {
    dispatch(setState({ key: 'page', value: 0 }));
    dispatch(setState({ key: 'isSlideboxLoading', value: true }));
    if (!id) {
      await handleSlidebox();
    } else if (id === MANAGED_BY_ME_FOLDER_NAME) {
      await handleManagedByMe();
    } else if (id === SHARED_WITH_ME_FOLDER_NAME) {
      await handleSharedWithMe();
    } else {
      await handleFolder(id, parentId);
    }
    dispatch(setState({ key: 'isSlideboxLoading', value: false }));
  };

  const handleSetSlideBoxPage = (page) => {
    dispatch(setPage(page));
  };

  const handleSetSlideBoxRowsPerPage = (rowsPerPage) => {
    dispatch(setRowsPerPage(rowsPerPage));
  };

  const handleSetFilters = ({ keyword, fileType, ownerEmail, updatedAt }) => {
    dispatch(setFilters({ keyword, fileType, ownerEmail, updatedAt }));
  };

  return {
    handleSetFilters,
    handleSetCurrentView,
    handleSetSlideBoxPage,
    handleSetSlideBoxRowsPerPage
  };
};
