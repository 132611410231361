import { Grid } from '@mui/material';

/**
 * @param {{label: string, value: string}[]} rows
 * @returns {ReactNode}
 * @constructor
 */
const AnnotationItemInfoRows = ({ rows = [] }) => {
  return rows.map((row, index) => (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      key={index}
    >
      <Grid item xs={6}>
        {row.label}:
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: '500'
        }}
      >
        {row.value}
      </Grid>
    </Grid>
  ));
};

export default AnnotationItemInfoRows;
