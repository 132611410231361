import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import Avatar from 'components/Avatar';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from 'hooks/useAuth';
import CancelIcon from '@mui/icons-material/Cancel';
import CommentDeleteButton from './CommentDeleteButton';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

const googleApiUrl = process.env.REACT_APP_GOOGLE_CLOUD_STORAGE;

const CommentList = ({
  onDelete,
  isLoading,
  editId,
  onEdit,
  onCancelEdit,
  data
}) => {
  const commentRef = React.useRef(null);
  const {
    user: { _id: uid }
  } = useAuth();

  const disableEditButton = (
    <Button
      size="small"
      color="buttonGray"
      sx={{ minWidth: 'unset' }}
      variant="contained"
      display="none"
      onClick={onCancelEdit}
    >
      <CancelIcon fontSize="xs" />
    </Button>
  );

  useEffect(() => {
    if (commentRef.current) {
      commentRef.current.scrollTop = commentRef.current.scrollHeight;
    }
  }, [data]);

  const renderActionButtons = (comment) => {
    if (uid !== comment.createdBy?._id) return null;
    if (editId && editId === comment._id) {
      return disableEditButton;
    }

    return (
      <>
        <Button
          size="small"
          color="success"
          display="none"
          sx={{ minWidth: 'unset' }}
          variant="contained"
          onClick={onEdit(comment.id)}
        >
          <EditIcon fontSize="xs" />
        </Button>
        <CommentDeleteButton id={comment.id} onDelete={onDelete} />
      </>
    );
  };

  return (
    <Stack
      ref={commentRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        width: '100%'
      }}
      spacing={1}
      p={1}
    >
      {data?.map((comment) => {
        const isCurrentUser = comment.createdBy?._id === uid;

        return (
          <Stack
            key={comment._id}
            display="flex"
            flexDirection="row"
            justifyContent={isCurrentUser ? 'flex-end' : 'flex-start'}
            marginLeft="auto"
            gap={0.5}
            width="100%"
            spacing={0.5}
          >
            {!isCurrentUser && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                sx={{
                  paddingBottom: '15px'
                }}
              >
                <Avatar src={comment.createdBy?.avatar} size={40} />
              </Box>
            )}
            <Stack
              id={`comment-${comment._id}`}
              display="flex"
              flexDirection="column"
              alignItems={isCurrentUser ? 'flex-end' : 'flex-start'}
              position="relative"
              maxWidth="90%"
              gap={0.5}
              sx={{
                '&:hover': {
                  '& .comment-actions': {
                    display: 'flex'
                  }
                }
              }}
            >
              {!isCurrentUser && (
                <Typography fontWeight={500} fontSize="0.625rem">
                  {`${comment.createdBy.firstName || ''} ${
                    comment.createdBy.lastName || ''
                  }`.trim()}
                </Typography>
              )}
              <Box
                sx={{
                  backgroundColor: isCurrentUser ? 'blue.main' : 'gray.main',
                  color: isCurrentUser ? 'white' : 'black',
                  minWidth: '65px',
                  padding: 1,
                  boxShadow: 5,
                  borderRadius: isCurrentUser
                    ? '10px 2px 10px 10px'
                    : '2px 10px 10px 10px',
                  wordBreak: 'break-word',
                  fontSize: '0.85rem'
                }}
              >
                <ReactMarkdown
                  components={{
                    p: ({ node, ...props }) => (
                      <Typography
                        variant="body1"
                        sx={{ margin: 0, whiteSpace: 'pre-wrap' }}
                        {...props}
                      />
                    ),
                    code({ node, inline, className, children, ...props }) {
                      return (
                        <Box
                          component="code"
                          sx={{
                            backgroundColor: 'rgba(27,31,35,.05)',
                            padding: '0.2em 0.4em',
                            borderRadius: '3px',
                            fontSize: '85%',
                            fontFamily:
                              'SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace'
                          }}
                          {...props}
                        >
                          {children}
                        </Box>
                      );
                    }
                  }}
                >
                  {comment.content}
                </ReactMarkdown>
              </Box>
              <Typography
                variant="body2"
                fontSize="0.625rem"
                align={isCurrentUser ? 'right' : 'left'}
              >
                {moment(comment.createdAt).fromNow()}
              </Typography>
              <Stack
                position="absolute"
                direction="row"
                alignItems="center"
                gap={0.5}
                display="none"
                className="comment-actions"
                justifyContent="flex-end"
                bottom={5}
                left={isCurrentUser ? 'auto' : 0}
                right={isCurrentUser ? 0 : 'auto'}
              >
                {renderActionButtons(comment)}
              </Stack>
              {comment.file && (
                <div>
                  <a
                    href={`${googleApiUrl}/${comment.file.fileUrl}`}
                    download={comment.file.fileName}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: 'inline-block',
                      maxWidth: '75%',
                      wordWrap: 'break-all',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    {comment.file.fileName}
                  </a>
                </div>
              )}
            </Stack>
          </Stack>
        );
      })}
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Stack>
  );
};

export default CommentList;
