import { Box, Button, Chip, InputBase, TextField } from '@mui/material';
import { AutocompleteBiomarkerType } from 'components/Shared/AutocompleteBiomarkerType';
import { AutocompleteOperator } from 'components/Shared/AutocompleteOperator';
import { AnalysisContext } from 'hooks/useAnalysisContext';
import React, { useContext, useEffect, useState } from 'react';

export const AnalysisBiomarker = () => {
  const { state, saveState } = useContext(AnalysisContext);

  const [biomarkers, setBiomarkers] = useState(state.biomarkers || []);

  const [currentBiomarker, setCurrentBiomarker] = useState({
    type: '',
    operator: '',
    value: ''
  });

  useEffect(() => {
    saveState({
      biomarkers
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biomarkers]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: 2,
        width: '100%'
      }}
    >
      <Box
        sx={{
          border: '2px solid #CCCCCC',
          borderRadius: '5px',
          width: '100%',
          minHeight: '40px',
          display: 'flex',
          flexDirection: 'row',
          gap: 0.5,
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '6px',
          flexWrap: 'wrap'
        }}
        variant="outlined"
      >
        {biomarkers.map((biomarker, index) => (
          <Chip
            key={index}
            label={`${biomarker.type} ${biomarker.operator} ${biomarker.value}`}
            onDelete={() => {
              const newBiomarkers = [...biomarkers];
              newBiomarkers.splice(index, 1);
              setBiomarkers(newBiomarkers);
            }}
          />
        ))}
        <InputBase
          sx={{
            ml: 1,
            flex: 1
          }}
          readOnly
          placeholder="Biomarkers"
          inputProps={{ 'aria-label': 'Type' }}
        />
      </Box>
      <AutocompleteBiomarkerType
        placeholder="Biomarker"
        label="Biomarker"
        isMulti={false}
        value={currentBiomarker.type}
        onChange={(value) => {
          setCurrentBiomarker({
            ...currentBiomarker,
            value: '',
            type: value
          });
        }}
      />
      <AutocompleteOperator
        placeholder="Operator"
        label="Operator"
        isMulti={false}
        value={currentBiomarker.operator}
        onChange={(value) => {
          setCurrentBiomarker({
            ...currentBiomarker,
            operator: value
          });
        }}
      />

      <TextField
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '2px solid #CCCCCC',
              borderRadius: '5px'
            }
          }
        }}
        size="small"
        fullWidth
        variant="outlined"
        label="Value"
        value={currentBiomarker.value}
        onChange={(event) => {
          setCurrentBiomarker({
            ...currentBiomarker,
            value: event.target.value
          });
        }}
      />
      <Box>
        <Button
          disabled={
            !currentBiomarker.type ||
            !currentBiomarker.operator ||
            !currentBiomarker.value
          }
          variant="contained"
          onClick={() => {
            setBiomarkers([...biomarkers, currentBiomarker]);
            setCurrentBiomarker({
              type: '',
              operator: '',
              value: ''
            });
          }}
        >
          Add
        </Button>
      </Box>
    </Box>
  );
};
