import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormContainer } from 'react-hook-form-mui';
import { useSnackbar } from 'notistack';
import { Stack, FormControlLabel, Checkbox, FormControl } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AuthLayout from '../../components/Layout/AuthLayout';
import { setCredentials } from '../../stores/auth/authSlice';
import { useLoginMutation } from '../../services/auth';
import { CustomInput } from '../../components/Shared/Input';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import ForgotPasswordDialog from './components/ForgotPasswordDialog';
import { useVerifyAccountMutation } from '../../services/user';
import VerificationDialog from './components/VerificationDialog';
import Error403Dialog from './components/Error403Dialog';

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [login, { isLoading }] = useLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, reset } = useForm({});
  const [verifyAccount] = useVerifyAccountMutation();

  const [verifyDialog, setVerifytDialog] = useState(false);

  const [error403, setError403] = useState({ error: '', open: false });

  const handleLogin = async (form) => {
    try {
      Object.keys(form).map(
        (k) => (form[k] = typeof form[k] == 'string' ? form[k].trim() : form[k])
      );
      const response = await login({
        email: form.email,
        password: form.password,
        ...(form.organization && { organization: form.organization.trim() })
      }).unwrap();
      dispatch(setCredentials(response.data));
      enqueueSnackbar('Login successful', { variant: 'success' });
      navigate('/');
    } catch (err) {
      if (err.status === 403) {
        const error = err.data.error.message || '';
        setError403({ error, open: true });
      } else {
        enqueueSnackbar('Invalid email or password', {
          variant: 'error'
        });
      }
    }
  };

  const verifyActivationKey = async (key) => {
    try {
      const response = await verifyAccount(key).unwrap();
      if (response.success) {
        setVerifytDialog(true);
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Invalid Activation Key !', { variant: 'error' });
    }
  };

  useEffect(() => {
    let defaultValues = {
      email: '',
      password: ''
    };
    if (searchParams.get('activationKey')) {
      verifyActivationKey(searchParams.get('activationKey'));
    }
    if (searchParams.get('email')) {
      defaultValues.email = searchParams.get('email');
    }
    reset(defaultValues);
  }, []);

  const handleShowPassword = (e) => {
    setShowPassword(e.target.checked);
  };
  return (
    <AuthLayout>
      <FormContainer onSuccess={handleSubmit(handleLogin)}>
        <Stack direction="column" gap={2}>
          <FormControl>
            <CustomInput
              fullWidth
              inputProps={{
                placeholder: 'Organization',
                style: {
                  color: theme.palette.primary.main,
                  WebkitTextFillColor: theme.palette.primary.main
                }
              }}
              {...register('organization')}
            />
          </FormControl>
          <FormControl>
            <CustomInput
              fullWidth
              inputProps={{
                placeholder: 'Email Address',
                style: {
                  color: theme.palette.primary.main,
                  WebkitTextFillColor: theme.palette.primary.main
                }
              }}
              {...register('email', {
                required: true
              })}
            />
          </FormControl>
          <FormControl>
            <CustomInput
              fullWidth={true}
              inputProps={{
                style: {
                  color: theme.palette.primary.main,
                  WebkitTextFillColor: theme.palette.primary.main
                }
              }}
              id="password"
              {...register('password')}
              aria-describedby="password"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
            />
          </FormControl>
          <FormControlLabel
            control={<Checkbox onChange={handleShowPassword} />}
            label={
              <span
                style={{
                  fontSize: '20px',
                  color: theme.palette.text.textGray1,
                  fontWeight: '500'
                }}
              >
                Show password
              </span>
            }
            sx={{ fontSize: '20px' }}
          />
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            value="submit"
            sx={{ height: '56px', borderRadius: '5px', boxShadow: 'none' }}
          >
            <span style={{ fontSize: '33px' }}>LOGIN</span>
          </LoadingButton>
        </Stack>
      </FormContainer>
      <ForgotPasswordDialog sx={{ mt: '20px' }} />
      <VerificationDialog
        open={verifyDialog}
        handleClose={() => setVerifytDialog(false)}
      />
      <Error403Dialog
        open={error403.open}
        error={error403.error}
        handleClose={() => setError403({ error: '', open: false })}
      />
    </AuthLayout>
  );
};

export default Login;
