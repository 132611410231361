import * as React from 'react';
import Box from '@mui/material/Box';
import { Drawer } from '@mui/material';

const RightAppDrawer = ({
  open,
  headerSlot,
  children,
  ...props
}) => {
  return (
    <Drawer
      hideBackdrop={false}
      anchor={'right'}
      open={open}
      className="wew"
      onClose={() => {}}
      sx={{overflow: 'hidden', ...props.sx}}
      {...props}
    >
      <Box>{headerSlot}</Box>
           {children}
    </Drawer>
  )
}
export default RightAppDrawer;