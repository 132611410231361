import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'v2/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from 'v2/components/hook-form';

import { EUserRole } from 'types/user';
import { USER_ROLES_OPTIONS } from 'v2/utils/user';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { IOrganization } from 'types/organization';
import { useAuth } from 'hooks/useAuth';
import { useGetAllProjectsMutation } from 'services/projects';
import { IProject } from 'types/project';
import { IOption } from 'v2/utils/general';
import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import { useInviteMutation } from 'services/user';
import { handleBackendError } from 'v2/utils/handle-backend-error';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  organizations: IOrganization[];
  onCallback?: VoidFunction;
};

export default function UserInviteForm({
  open,
  onClose,
  organizations = [],
  onCallback
}: Props) {
  const {
    user: { role, email: loggedEmail, organization }
  } = useAuth();

  const [projects, setProjects] = useState<IProject[]>([]);
  const [getAllProjects] = useGetAllProjectsMutation();
  const [invite, { isLoading }] = useInviteMutation();

  const { enqueueSnackbar } = useSnackbar();
  const [optionRoles, setOptionRoles] = useState<IOption[]>([]);
  const [optionProjects, setOptionProjects] = useState<
    IOption<{
      email: string;
    }>[]
  >([]);

  const InviteUserSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email must be a valid email address'),
    role: Yup.mixed<EUserRole>().oneOf(Object.values(EUserRole)),
    organizationId: Yup.number().required('Organization is required'),
    projects: Yup.array().of(Yup.number())
  });

  const defaultValues = useMemo(
    () => ({
      email: '',
      role: EUserRole.USER,
      organizationId: organization?._id,
      projects: []
    }),
    [organization]
  );

  const methods = useForm({
    resolver: yupResolver(InviteUserSchema),
    defaultValues
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { organizationId, ...rest } = data;
    try {
      await invite({
        ...rest,
        organizationId:
          role === EUserRole.SUPER_ADMIN ? organizationId : organization?._id
      }).unwrap();
      enqueueSnackbar('Invite member successfully', { variant: 'success' });
      if (onCallback) onCallback();
      reset();
      onClose();
    } catch (error) {
      enqueueSnackbar(
        handleBackendError(error).message || 'Something went wrong',
        {
          variant: 'error'
        }
      );
    }
  });

  useEffect(() => {
    if (role === EUserRole.SUPER_ADMIN) {
      setOptionRoles(USER_ROLES_OPTIONS);
    } else if (role === EUserRole.ADMIN) {
      setOptionRoles(
        USER_ROLES_OPTIONS.filter(
          (option) => option.value !== EUserRole.SUPER_ADMIN
        )
      );
    } else if (role === EUserRole.USER) {
      setOptionRoles([]);
    }
  }, [role]);

  // reset form when open
  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // get all projects
  useEffect(() => {
    const getAllProjectsAsync = async () => {
      try {
        const response: any = await getAllProjects({});
        if (response && response.data?.data) {
          setProjects(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getAllProjectsAsync();
  }, [getAllProjects]);

  const organizationId = watch('organizationId');

  // update projects options
  useEffect(() => {
    setValue('projects', []);
    if (organizationId && projects.length > 0) {
      const options = projects
        .filter(
          (project) => project.createdBy.organization._id === organizationId
        )
        .map((project) => ({
          value: project._id,
          label: project.name,
          email: project.createdBy.email
        }));
      setOptionProjects(options);
    } else {
      setOptionProjects([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, organizationId]);

  const isSelectAllProject =
    optionProjects.length > 0 &&
    watch('projects').length === optionProjects.length;

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1
            }}
          >
            <PersonAddIcon />
            Invite new user
          </Box>
        </DialogTitle>
        <DialogContent>
          <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
            Invite a new user to your team. The user will receive an email with
            instructions on how to set up their account.
          </Alert>
          <Box rowGap={3} columnGap={2} display="grid">
            <RHFTextField name="email" label="Email Address" />
            {role !== EUserRole.USER && (
              <RHFSelect name="role" label="Role">
                {optionRoles.map((role) => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            )}
            {role === EUserRole.SUPER_ADMIN && (
              <RHFSelect name="organizationId" label="Organization">
                {organizations?.map((organization: IOrganization) => (
                  <MenuItem key={organization._id} value={organization._id}>
                    {organization.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            )}
            <FormControl>
              <InputLabel id="select-projects-label">Projects</InputLabel>
              <Select
                labelId="select-projects-label"
                id="select-projects"
                multiple
                value={watch('projects')}
                onChange={(event) => {
                  let values = event.target.value as any[];
                  if (values.includes('all') && !isSelectAllProject) {
                    values = optionProjects.map((project) =>
                      Number(project.value)
                    );
                  } else if (values.includes('all') && isSelectAllProject) {
                    values = [];
                  }
                  setValue('projects', values as any);
                }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          optionProjects.find(
                            (project) => project.value === value
                          )?.label
                        }
                      />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value="all">
                  <Checkbox checked={isSelectAllProject} />
                  <ListItemText
                    primary={isSelectAllProject ? 'Select None' : 'Select all'}
                  />
                </MenuItem>
                {optionProjects.map((project) => (
                  <MenuItem key={project.value} value={project.value}>
                    <Checkbox
                      checked={
                        (watch('projects') as number[]).indexOf(
                          Number(project.value)
                        ) > -1
                      }
                    />
                    <Typography component="span" mr={1}>
                      {project.label}
                    </Typography>
                    <Typography
                      component="span"
                      color="text.secondary"
                      variant="caption"
                    >
                      {loggedEmail === project?.email
                        ? 'owned'
                        : `by ${project?.email}`}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            disabled={isSubmitting || isLoading}
            onClick={onClose}
          >
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting || isLoading}
          >
            Invite
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
