import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Input,
  InputLabel,
  Stack,
  Typography
} from '@mui/material';
import { useGetMembersQuery } from '../../../services/user';
import {
  AutocompleteElement,
  TextFieldElement,
  useForm
} from 'react-hook-form-mui';
import map from 'lodash/map';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useGroupsContext } from '../../../contexts/GroupsContext';
import { useWS } from 'contexts/WebSocketContext';

function EditGroupDialog({ group, open, onClose, editable }) {
  const { updateGroup } = useWS();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, data } = useGetMembersQuery();
  const { emitEvent, setSelectedGroup } = useGroupsContext();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: group.name,
      description: group.description,
      members: map(group.members, '_id')
    }
  });

  if (!group) return null;

  const members =
    (editable ? data : group.members)?.map((item) => ({
      id: item._id,
      label: `${item.firstName} ${item.lastName}`.trim()
    })) ?? [];

  async function handleUpdateGroup(formData) {
    if (!editable) return;
    try {
      await updateGroup({
        id: group._id,
        ...formData
      }).unwrap();
      // TODO WS
      // setSelectedGroup?.(response.data);
      onClose?.();
      enqueueSnackbar('Update group successfully', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message || 'Something went wrong!', {
        variant: 'error'
      });
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <form
          noValidate
          id="group-edit-form"
          onSubmit={handleSubmit(handleUpdateGroup)}
        >
          <Grid container spacing={3}>
            <Grid item>
              <Stack alignItems="center" spacing={2}>
                <Typography variant="h5">Edit Group</Typography>
                <InputLabel htmlFor="group-cover">
                  <Avatar src={group.cover} />
                </InputLabel>
                <Box hidden>
                  <Input
                    type="file"
                    name="file"
                    id="group-cover"
                    inputProps={{ accept: 'image/png, image/jpeg' }}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item flex={1} flexDirection="column" spacing={1} container>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <InputLabel required htmlFor="group-name">
                    Group name
                  </InputLabel>
                  <TextFieldElement
                    control={control}
                    name="name"
                    fullWidth
                    id="group-name"
                    required
                    disabled={!editable}
                  />
                </Stack>
                <Stack spacing={1}>
                  <InputLabel htmlFor="group-about">About</InputLabel>
                  <TextFieldElement
                    control={control}
                    fullWidth
                    id="group-about"
                    multiline
                    minRows={4}
                    maxRows={10}
                    name="description"
                    disabled={!editable}
                  />
                </Stack>
                <Stack spacing={1}>
                  <InputLabel required htmlFor="group-members">
                    Members
                  </InputLabel>
                  <AutocompleteElement
                    control={control}
                    loading={isLoading}
                    multiple
                    matchId
                    id="group-members"
                    name="members"
                    options={members}
                    required
                    autocompleteProps={{
                      disabled: !editable
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="gray"
          disableElevation
        >
          Back
        </Button>
        {editable && (
          <Button
            type="submit"
            form="group-edit-form"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default EditGroupDialog;
