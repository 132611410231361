import { createSlice } from '@reduxjs/toolkit';
import {
  DEFAULT_ROOT_FOLDER_NAME,
  MANAGED_BY_ME_FOLDER_NAME,
  ROLES,
  SHARED_WITH_ME_FOLDER_NAME
} from '../../constants';
import { mergeById } from '../../utils/fileUtils';

const initialState = {
  foldersAndFiles: [],
  breadcrumbs: [
    {
      _id: null,
      name: DEFAULT_ROOT_FOLDER_NAME,
      type: null
    }
  ],
  currentFolderView: {
    _id: null,
    name: DEFAULT_ROOT_FOLDER_NAME,
    type: null,
    ancestors: [],
    caseIdentifiers: [],
    tags: [],
    createdByEmail: null
  },
  filters: {
    keyword: '',
    fileType: '',
    ownerEmail: '',
    updatedAt: ''
  },
  page: 0,
  rowsPerPage: 0,
  isSlideboxLoading: false,
  expandFloder: []
};

const managedActions = {
  isRenamable: true,
  isRemoveable: true,
  isShareable: true
};

const sharedActions = {
  isRenamable: false,
  isRemoveable: false,
  isShareable: false
};

export const slideboxSlice = createSlice({
  name: 'slidebox',
  initialState: JSON.parse(JSON.stringify(initialState)),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setState: (state, { payload }) => {
      const { key, value } = payload;
      state[key] = value;
    },
    setExpandFloder: (state, { payload }) => {
      state.expandFloder = payload;
    },
    setFoldersAndFiles: (state, { payload }) => {
      state.foldersAndFiles = payload;
    },
    pushFolersAndFiles: (state, { payload }) => {
      let data = mergeById(state.foldersAndFiles, payload).sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      const currentState = JSON.parse(JSON.stringify(state));
      const currentFolderAction = currentState.breadcrumbs[0].name;
      switch (currentFolderAction) {
        case DEFAULT_ROOT_FOLDER_NAME:
        case MANAGED_BY_ME_FOLDER_NAME:
          data = data.map((item) => ({
            ...item,
            ...managedActions
          }));
          break;
        case SHARED_WITH_ME_FOLDER_NAME:
          data = data.map((item) => ({
            ...item,
            ...sharedActions
          }));
          break;
        default:
          break;
      }
      state.foldersAndFiles = data;
    },
    updatebyId: (state, { payload }) => {
      const index = state.foldersAndFiles.findIndex(
        (x) => x._id === payload._id
      );

      if (index !== -1) {
        const newState = [...state.foldersAndFiles];
        newState[index] = payload;
        const currentState = JSON.parse(JSON.stringify(state));
        const currentFolderAction = currentState.breadcrumbs[0].name;
        switch (currentFolderAction) {
          case DEFAULT_ROOT_FOLDER_NAME:
          case MANAGED_BY_ME_FOLDER_NAME:
            newState[index] = {
              ...newState[index],
              ...managedActions
            };
            break;
          case SHARED_WITH_ME_FOLDER_NAME:
            newState[index] = {
              ...newState[index],
              ...sharedActions
            };
            break;
          default:
            break;
        }

        state.foldersAndFiles = newState;
      }
    },
    setCurrentFolderView: (state, { payload }) => {
      state.currentFolderView = payload;
    },
    resetState: (state, { payload }) => {
      state.currentFolderView = initialState.currentFolderView;
      state.foldersAndFiles = initialState.foldersAndFiles;
    },
    addToBreadcrumbs: (state, { payload }) => {
      state.breadcrumbs = [...state.breadcrumbs, payload];
    },
    removeFromBreadcrumbs: (state, { payload }) => {
      const newBreadcrumbs = [...state.breadcrumbs];
      newBreadcrumbs.splice(payload);
      state.breadcrumbs = newBreadcrumbs;
    },
    setBreadcrumbs: (state, { payload }) => {
      state.breadcrumbs = payload;
    },
    reCalculateFolderProps: (state, { payload }) => {
      const { oldDestinations, newDestinations, fileSize, annotationCount } =
        payload;
      const foldersAndFiles = [...state.foldersAndFiles];
      if (oldDestinations.length) {
        oldDestinations.forEach((id) => {
          const index = foldersAndFiles.findIndex((x) => x._id === id);
          if (index !== -1) {
            foldersAndFiles[index].size -= fileSize;
            foldersAndFiles[index].annotationCount -= annotationCount;
          }
        });
      }
      if (newDestinations.length) {
        newDestinations.forEach((id) => {
          const index = foldersAndFiles.findIndex((x) => x._id === id);
          if (index !== -1) {
            foldersAndFiles[index].size += fileSize;
            foldersAndFiles[index].annotationCount += annotationCount;
          }
        });
      }
      state.foldersAndFiles = foldersAndFiles;
    },
    setFilters: (state, { payload }) => {
      state.filters = payload;
      localStorage.setItem('slideboxFilter', JSON.stringify(payload));
    },
    resetFilters: (state) => {
      state.filters.fileType = '';
      state.filters.ownerEmail = '';
      state.filters.updatedAt = '';
      localStorage.setItem(
        'slideboxFilter',
        JSON.stringify({
          keyword: state.filters.keyword,
          fileType: '',
          ownerEmail: '',
          updatedAt: ''
        })
      );
    },
    setPage: (state, { payload }) => {
      state.page = payload;
      localStorage.setItem('slideboxPage', JSON.stringify(payload));
    },
    setRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload;
      localStorage.setItem('slideboxRowsPerPage', JSON.stringify(payload));
    },
    removePaginationAndFilters: (state) => {
      localStorage.removeItem('slideboxPage');
      localStorage.removeItem('slideboxRowsPerPage');
      localStorage.removeItem('slideboxFilter');
      state.page = 0;
      state.rowsPerPage = 10;

      state.filters.fileType = '';
      state.filters.ownerEmail = '';
      state.filters.updatedAt = '';
      localStorage.setItem(
        'slideboxFilter',
        JSON.stringify({
          keyword: state.filters.keyword,
          fileType: '',
          ownerEmail: '',
          updatedAt: ''
        })
      );
    }
  }
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
});

// Getters
export const selectFoldersAndFiles = (state) => state.slidebox.foldersAndFiles;

export const selectCurrentFolderView = (state) =>
  state.slidebox.currentFolderView;
export const selectExpandFloder = (state) => state.slidebox.expandFloder;
export const selectBreadcrumbs = (state) => state.slidebox.breadcrumbs;

export const slideBoxFilters = (state) => state.slidebox.filters;

export const slideBoxPage = (state) => state.slidebox.page;

export const slideBoxRowsPerPage = (state) => state.slidebox.rowsPerPage;

export const isSlideboxLoading = (state) => state.slidebox.isSlideboxLoading;

// actions
export const {
  setExpandFloder,
  setFoldersAndFiles,
  pushFolersAndFiles,
  setCurrentFolderView,
  resetState,
  addToBreadcrumbs,
  removeFromBreadcrumbs,
  reCalculateFolderProps,
  setBreadcrumbs,
  updatebyId,
  setFilters,
  resetFilters,
  setPage,
  setRowsPerPage,
  removePaginationAndFilters,
  setState
} = slideboxSlice.actions;

export default slideboxSlice.reducer;
