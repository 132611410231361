import {Select, Typography} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { forwardRef } from 'react';

export const StyledSelect = styled(Select)(({theme}) => {
    return {
      '& .MuiSelect-select.MuiSelect-outlined': {
        padding: '10px 55px',
        backgroundColor: 'transparent!important',
        fontSize: '20px',
        display: 'flex',
        alignItems: 'center'
      },
      '& .placeholder': {
        color: '#C8C8C8',
        opacity: 1
      },
      '&.MuiOutlinedInput-root': {
        fieldset: {
          top: 0,
          borderColor: '#CCCCCC',
          borderWidth: '2px',
          borderRadius: '5px',
          legend: {
            display: 'none'
          }
        }
      }
    }});

    export const CustomSelect = forwardRef(function CustomSelect(props, ref) {
      const theme = useTheme()
      const { 'error-message' : errorMessage} = props
      return (
        <>
          <StyledSelect {...props} ref={ref} />
          <Typography variant='caption' sx={{ color: theme.palette.error.main }}>
            {errorMessage}
          </Typography>
        </>
      )
    });