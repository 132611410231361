import { api } from './api';

export const caseIdentifierAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientIds: build.query({
      query: (params) => ({ url: '/caseIdentifiers/patientIds', params }),
      transformResponse: (response) => response.data
    }),
    getBlockIds: build.query({
      query: (params) => ({ url: '/caseIdentifiers/blockIds', params }),
      transformResponse: (response) => response.data
    }),
    getRecuts: build.query({
      query: (params) => ({ url: '/caseIdentifiers/recuts', params }),
      transformResponse: (response) => response.data
    }),
    getTreatmentGroups: build.query({
      query: (params) => ({ url: '/caseIdentifiers/treatmentGroups', params }),
      transformResponse: (response) => response.data
    }),
    getCaseIdentifiers: build.query({
      query: (params) => ({ url: '/caseIdentifiers', params }),
      transformResponse: (response) => response.data
    }),
    createCaseIdentifiers: build.mutation({
      query: (body) => ({
        url: '/caseIdentifiers',
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetPatientIdsQuery,
  useGetBlockIdsQuery,
  useGetRecutsQuery,
  useGetTreatmentGroupsQuery,
  useGetCaseIdentifiersQuery,
  useCreateCaseIdentifiersMutation
} = caseIdentifierAPI;
