import Accordion from 'components/Accordion';
import {
  Box,
  Button,
  Divider,
  Input,
  InputLabel,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useContext, useRef, useState } from 'react';
import { SlideViewerContext } from 'hooks/useSlideViewerContext';
import { useUploadSlideAttachmentMutation } from 'services/slides';
import { useSnackbar } from 'hooks/useSnackbar';
import { first, last } from 'lodash';

const SlideAttachments = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useContext(SlideViewerContext);
  const [upload, { isLoading }] = useUploadSlideAttachmentMutation();
  const fileRef = useRef(null);
  const [files, setFiles] = useState([]);

  const uploadAttachment = async (e) => {
    e.preventDefault();
    if (isLoading || !state.slide || !files.length) return;
    try {
      const formData = new FormData();
      formData.append('file', files[0]);
      const updatedSlide = await upload({
        id: state.slide._id,
        body: formData
      }).unwrap();
      dispatch({ type: 'SET_STATE', key: 'slide', value: updatedSlide.data });
      if (fileRef.current) fileRef.current.value = null;
      setFiles([]);
      enqueueSnackbar('Uploaded the file successfully', {
        variant: 'success'
      });
    } catch (e) {
      console.warn(e);
      enqueueSnackbar(e.message ?? e.error ?? e.toString(), {
        variant: 'error'
      });
    }
  };

  const getAttachmentUrl = (attachment = '') => {
    if (attachment.startsWith('http')) return attachment;
    return `${process.env.REACT_APP_BACKEND_API}${attachment}`;
  };

  const getFileNameFromURL = (url) => {
    const urlObject = new URL(url);
    const pathnameParts = urlObject.pathname.split('/');
    const filenameWithEncodedSpaces = pathnameParts[pathnameParts.length - 1];
    const filename = decodeURIComponent(filenameWithEncodedSpaces); 
    return filename;
  }
  
  return (
    <Accordion heading="Attachments">
      <Stack spacing={1} p={2} pt={0}>
        <List dense disablePadding>
          {state?.slide?.attachments?.map((attachment, index) => (
            <ListItem key={index}>
              <ListItemText>
                <Link
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                  target="_blank"
                  rel="noopener"
                  href={getAttachmentUrl(attachment)}
                  underline="hover"
                >
                  <OpenInNewIcon fontSize="small" />
                  {getFileNameFromURL(attachment)}
                </Link>
              </ListItemText>
            </ListItem>
          ))}
        </List>
        {isLoading && <LinearProgress />}
        {state?.slide?.attachments?.length > 0 && <Divider />}
        <form onSubmit={uploadAttachment}>
          <Stack mt={1} spacing={1}>
            <InputLabel
              sx={{ color: '#111', fontWeight: 500 }}
              htmlFor="attachment"
            >
              Upload an attachment
            </InputLabel>
            <Input
              id="attachment"
              type="file"
              accept="image/*, text/*, application/*"
              required
              onChange={(e) => setFiles(e.target.files)}
              inputRef={fileRef}
            />

            <Typography variant="overline">
              Supported formats: image, document
            </Typography>
            <Box>
              <Button
                type="submit"
                size="small"
                variant="contained"
                disabled={!files.length || isLoading}
              >
                Upload
              </Button>
            </Box>
          </Stack>
        </form>
      </Stack>
    </Accordion>
  );
};

export default SlideAttachments;
