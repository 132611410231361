import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  createFilterOptions
} from '@mui/material';
import { useGetAnnotationColorsQuery } from 'services/annotation';
import { debounce } from 'lodash';
const filter = createFilterOptions();

export const AutocompleteAnnotationColors = ({
  isCreateable = false,
  isMulti = false,
  onChange,
  value,
  label = 'Annotation',
  placeholder = 'Annotation',
  sx = {}
}) => {
  const [annotationColors, setAnnotationColors] = useState([]);

  const [annotationColorQuery, setAnnotationQuery] = useState({
    limit: 100,
    page: 1,
    search: ''
  });

  const { data: fetchedAnnotationColors } = useGetAnnotationColorsQuery(
    annotationColorQuery,
    {
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    if (fetchedAnnotationColors) {
      setAnnotationColors(
        fetchedAnnotationColors.map((annotationColor) => ({
          color: annotationColor,
          _id: annotationColor
        }))
      );
    }
  }, [fetchedAnnotationColors]);

  const debouncedSearch = debounce((search) => {
    setAnnotationQuery({
      ...annotationColorQuery,
      search
    });
  }, 200);

  return (
    <Autocomplete
      multiple={isMulti}
      disableCloseOnSelect={isMulti}
      id="annotationColors-outlined"
      sx={sx}
      onBlur={() => {
        setAnnotationQuery({
          ...annotationColorQuery,
          search: ''
        });
      }}
      options={
        annotationColors
          ? annotationColors.map((annotationColor) => ({
              color: annotationColor.color,
              _id: annotationColor._id
            }))
          : []
      }
      freeSolo={isCreateable}
      onChange={(e, value) => {
        const convertAnnotationColors = value.map((annotationColor) => {
          if (typeof annotationColor === 'string') {
            return {
              color: annotationColor,
              _id: ''
            };
          }
          return annotationColor;
        });
        // remove duplicate annotationColors
        const uniqueAnnotationColors = convertAnnotationColors.filter(
          (annotationColor, index, self) =>
            index === self.findIndex((t) => t.color === annotationColor.color)
        );
        // remove empty annotationColors
        const filteredAnnotationColors = uniqueAnnotationColors.filter(
          (annotationColor) => annotationColor.color.trim() !== ''
        );
        onChange && onChange(filteredAnnotationColors);
      }}
      value={value || []}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const existingList = value ? [...value, ...options] : options;
        const isExisting = existingList.some((option) => {
          if (params.inputValue) {
            return option.color === params.inputValue;
          }
          return false;
        });
        if (params.inputValue !== '' && isCreateable && !isExisting) {
          filtered.push({
            _id: '',
            color: params.inputValue,
            label: `Add "${params.inputValue}"`
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.color;
      }}
      isOptionEqualToValue={(option, value) => option.color === value.color}
      renderOption={(props, option) => (
        <li {...props}>
          <Box
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: option.color,
              marginRight: '10px',
              borderRadius: '5px'
            }}
          />

          {option.label || option.color}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          variant="outlined"
          placeholder={placeholder}
          label={label}
          value={annotationColorQuery.search}
          onChange={(e) => {
            e.persist();
            debouncedSearch(e.target.value);
          }}
          size="small"
        />
      )}
    />
  );
};
