import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser, selectToken } from '../stores/auth/authSlice';

export const useAuth = () => {
  // Get state.auth.user
  const user = useSelector(selectCurrentUser);
  const token = useSelector(selectToken);
  return useMemo(
    () => ({ user, token: token ? token.replace('Bearer ', '') : null }),
    [user, token]
  );
};
