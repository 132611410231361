import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const ListItem = styled('li')(({ theme }) => ({
  margin: '4px 8px 4px 0'
}));

export default function ChipsArray({ items, handleDelete, disableShare }) {
  return (
    <Paper
    elevation={0}
      sx={{
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        listStyle: 'none',
        background: 'transparent',
        p: 0,
        m: 0
      }}
      component="ul"
    >
      {items.map(({ emails, id, image, name }, index) => {
        let icon;
        if (!image) {
          icon = <AccountCircleIcon />;
        }

        if (!emails) {
          return null;
        }

        return (
          <ListItem key={id}>
            <Tooltip title={emails}>
              <Chip
                color="primary"
                variant="outlined"
                sx={{ borderRadius: 1, p: '18px 0px' }}
                icon={icon}
                avatar={
                  image && (
                    <Avatar
                      alt={emails}
                      sx={{ width: 56, height: 56 }}
                      src={image}
                    />
                  )
                }
                label={
                  <Typography
                    sx={{ display: 'inline' }}
                    variant="subtitle1"
                    color="primary"
                  >
                    {name}
                  </Typography>
                }
                onDelete={!disableShare ? () => handleDelete(index) : undefined} 
              />
            </Tooltip>
          </ListItem>
        );
      })}
    </Paper>
  );
}
