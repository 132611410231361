import { SVG_NAMESPACE } from '@recogito/annotorious/src/util/SVG';
import { getRectangleSize } from './Rectangle';

export default class RectangleMask {
  constructor(imageDimensions, rectangle) {
    this.w = imageDimensions.naturalWidth;
    this.h = imageDimensions.naturalHeight;

    this.rectangle = rectangle;

    const { x, y, width, height } = getRectangleSize(this.rectangle);

    this.mask = document.createElementNS(SVG_NAMESPACE, 'path');
    this.mask.setAttribute('fill-rule', 'evenodd');
    this.mask.setAttribute('class', 'a9s-selection-mask');

    this.mask.setAttribute(
      'd',
      `M0 0 h${this.w} v${this.h} h-${this.w} z M${x},${y} h${width} v${height} h-${width} z`
    );
  }

  redraw = () => {
    const { x, y, width, height } = getRectangleSize(this.rectangle);

    this.mask.setAttribute(
      'd',
      `M0 0 h${this.w} v${this.h} h-${this.w} z M${x},${y} h${width} v${height} h-${width} z`
    );
  };

  get element() {
    return this.mask;
  }

  destroy = () =>{
    return this.mask.parentNode.removeChild(this.mask)
    };
}
