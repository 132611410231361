import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

export const AutocompleteBiomarkerType = ({
  isMulti = false,
  onChange,
  value,
  label = 'Biomarker Type',
  placeholder = 'Biomarker Type',
  sx = {}
}) => {
  return (
    <Autocomplete
      multiple={isMulti}
      id="biomarker-type-outlined"
      options={['Accession', 'Description', 'Coverage']}
      size="small"
      sx={sx}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      getOptionLabel={(option) =>
        option.charAt(0).toUpperCase() + option.slice(1)
      }
      renderOption={(props, option) => (
        <li {...props}>{option.charAt(0).toUpperCase() + option.slice(1)}</li>
      )}
      renderInput={(params) => (
        <TextField
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid #CCCCCC',
                borderRadius: '5px'
              }
            }
          }}
          {...params}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};
