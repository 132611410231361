import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  TextField,
  DialogActions,
  Button
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import Alert from 'components/Alert';
import {
  useDeleteSlideGroupMutation,
  useUpdateSlideGroupMutation
} from 'services/slides';
import { useSnackbar } from 'notistack';

const AnnotationGroupMenu = ({
  slideId,
  group,
  anchorEl,
  open = false,
  onClose,
  onGroupChanged
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [name, setName] = useState(group.Name);
  const [updateSlideGroup, { isLoading: renaming }] =
    useUpdateSlideGroupMutation();
  const [deleteSlideGroup, { isLoading: deleting }] =
    useDeleteSlideGroupMutation();

  const handleOpenDialog = () => {
    onClose();
    setOpenDialog(true);
  };
  const handleOpenDeleteAlert = () => {
    onClose();
    setDeleteAlert(true);
  };

  const handleDelete = async () => {
    if (!slideId) return;
    try {
      const response = await deleteSlideGroup({
        id: slideId,
        groupId: group.Id
      }).unwrap();
      onGroupChanged?.(response.data.groups);
      enqueueSnackbar('Deleted group successfully', {
        variant: 'success'
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Error deleting group', {
        variant: 'error'
      });
    } finally {
      setDeleteAlert(false);
    }
  };

  const handleSubmit = async () => {
    if (!name.trim() || !slideId || deleting) return;
    try {
      const response = await updateSlideGroup({
        id: slideId,
        group: {
          Id: group.Id,
          id: group.id,
          Name: name
        }
      }).unwrap();
      onGroupChanged?.(response.data.groups);
      enqueueSnackbar('Renamed group successfully', {
        variant: 'success'
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Error updating group', {
        variant: 'error'
      });
    } finally {
      setOpenDialog(false);
    }
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        <MenuItem disableRipple onClick={handleOpenDialog}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem disableRipple onClick={handleOpenDeleteAlert}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Rename</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Annotation group name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onKeyDown={(e) => e.stopPropagation()}
            onKeyUp={(e) => e.stopPropagation()}
            onChange={(e) => setName(e.target.value)}
            required
            disabled={renaming}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="default" onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!name.trim() || renaming}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Alert
        open={deleteAlert}
        title="Delete annotation group"
        content="Are you sure to delete this group and all its annotations?"
        onDismiss={() => setDeleteAlert(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default AnnotationGroupMenu;
