import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Collapse,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton
} from '@mui/material';
import AnnotationItem from './AnnotationItem';
import { SlideViewerContext } from 'hooks/useSlideViewerContext';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import useAnnotationSocket from 'hooks/useAnnotationSocket';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AnnotationGroupMenu from './AnnotationGroupMenu';
import { useDroppable } from '@dnd-kit/core';

const AnnotationGroup = ({ hidden, group }) => {
  const annotationRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [menuEl, setMenuEl] = useState(null);
  const [state, dispatch] = useContext(SlideViewerContext);
  const [searchParams] = useSearchParams();
  const annotationId = searchParams.get('annotation');
  const { emitAnnotationUpserted } = useAnnotationSocket(
    state.slide._id,
    dispatch,
    state.anno
  );
  const { isOver, setNodeRef } = useDroppable({
    id: `droppable-${group.Color}`,
    data: {
      groupColor: group.Color
    }
  });

  const annotations = useMemo(() => {
    return state.annotations.filter(
      (anno) => anno.group === group.id && anno.stroke?.toLowerCase() === group.Color?.toLowerCase()
    );
  }, [state.annotations, group]);

  /**
   * auto expand the Group if it includes the annotation ID in search params
   */
  useEffect(() => {
    if (annotationId) {
      if (annotations.some((item) => item._id === parseInt(annotationId))) {
        const timeout = setTimeout(() => {
          setOpen(true);
        }, 200);
        return () => clearTimeout(timeout);
      }
    }
  }, [annotationId, annotations]);
  /**
   * Auto scroll to the annotation element matching the ID in search params
   */
  useEffect(() => {
    if (annotationId && open) {
      if (annotationRef.current) {
        annotationRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotationId, annotationRef.current, open]);

  const handleAnnoUpdated = (updatedAnno) => {
    emitAnnotationUpserted(updatedAnno, true);
  };

  const handleGroupChanged = useCallback(
    (groups) => {
      dispatch({
        type: 'UPDATE_GROUP',
        payload: groups
      });
    },
    [dispatch]
  );

  return (
    <>
      <List>
        <ListItem
          disablePadding
          sx={{
            display: 'block',
            backgroundColor: isOver ? 'rgba(83,134,228,0.5)' : undefined
          }}
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(e) => setMenuEl(e.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
          }
          ref={setNodeRef}
        >
          <ListItemButton
            onClick={() => {
              dispatch({ type: 'SET_STATE', key: 'color', value: group.Color });
              dispatch({
                type: 'UPDATE_GROUP_CHANGE',
                key: 'selectedGroupChange',
                value: 1
              });
              setOpen(!open);
            }}
            disabled={hidden}
            sx={{
              minHeight: 48,
              justifyContent: !hidden ? 'initial' : 'center',
              px: 2.5
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: !hidden ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </ListItemIcon>
            <ListItemText
              primary={`${group.Name} (${group.Count})`}
              sx={{
                color: group.Color,
                opacity: hidden ? 0 : 1,
                '& .MuiTypography-root': { fontWeight: '700' }
              }}
            />
          </ListItemButton>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ padding: '8px 20px' }}>
            {annotations.length
              ? annotations.map((annotation, index) => (
                  <Box position="relative" key={index}>
                    {annotation._id === parseInt(annotationId) && (
                      <Box
                        ref={annotationRef}
                        position="absolute"
                        className="animate-highlight-primary"
                        zIndex={3}
                      />
                    )}
                    <AnnotationItem
                      annotation={annotation}
                      index={index}
                      onUpdated={handleAnnoUpdated}
                    />
                  </Box>
                ))
              : 'No annotation found'}
          </Box>
        </Collapse>
      </List>
      <AnnotationGroupMenu
        slideId={state?.slide?._id}
        anchorEl={menuEl}
        open={!!menuEl}
        onClose={() => setMenuEl(null)}
        group={group}
        onGroupChanged={handleGroupChanged}
      />
    </>
  );
};
export default AnnotationGroup;
