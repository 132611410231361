import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { formatDate } from "../../utils/appHelpers";

const ActivityLog = ({ log }) => {
  return (
    <ListItem alignItems="flex-start" px={0}>
      <ListItemAvatar>
        <Avatar alt={log.createdUser.fullName} src={log.createdUser.avatar} />
      </ListItemAvatar>
      <ListItemText
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        primary={
          <Box>
            <Box>
              <Typography
                sx={{ display: 'inline' }}
                variant="subtitle2"
                color="primary"
              >
                {log.createdUser.email}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="caption"
              >
                {`${formatDate(log.atTime, 'MMM DD, YYYY LT')}`}
              </Typography>
            </Box>
          </Box>
        }
        secondary={
          <Typography
            sx={{ display: 'inline', whiteSpace: 'normal', fontWeight: 500 }}
            component="span"
            variant="body2"
          >
            {log.description}
          </Typography>
        }
      />
    </ListItem>
  )
}
export default ActivityLog