import { api } from './api';

export const userRequestApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDataFromRequestToken: build.mutation({
      query: ({ token, type }) => ({
        url: `/userRequests?token=${token}&type=${type}`,
        method: 'GET'
      })
    }),
    getPendingInvitations: build.query({
      query: ({ type, createdBy }) => ({
        url: `/userRequests/pendingInvitations?createdBy=${createdBy}&type=${type}`,
        method: 'GET'
      }),
      transformResponse: (response, meta, arg) => {
        if (response) {
          const { data, success } = response;
          if (success) {
            return data;
          }
        }
        return [];
      }
    })
  })
});

export const {
  useGetDataFromRequestTokenMutation,
  useGetPendingInvitationsQuery
} = userRequestApi;
