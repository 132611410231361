import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Divider
} from '@mui/material';

import { useCopySlideToProjectMutation } from 'services/slides';
import { FormContainer, useForm, Controller } from 'react-hook-form-mui';
import { LoadingButton } from '@mui/lab';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CustomSelect } from '../../../../components/Shared/Select';
import { useGetAllProjectsMutation } from '../../../../services/projects';
import { ROLES } from '../../../../constants';
import { useAuth } from '../../../../hooks/useAuth';
import ErrorDialog from 'components/Dialog/ErrorDialog';
import RenameDialog from './RenameDialog';

const CopyButton = ({ entity, calledFrom, copyClicked }) => {
  const [ copySlide, { isLoading: isCopying } ] = useCopySlideToProjectMutation();
  const [ getAllProjects, ] = useGetAllProjectsMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, reset, control, formState: { errors }, } = useForm({ defaultValues: { emails: [''] } });
  const { user: { email: loggedEmail, role: loggedRole } } = useAuth();
  
  const [ copiableProjects, setCopiableProjects ] = useState( [] );
  const [ selectedProject, setSelectedProject ] = useState( '' );
  const [ disableCopy, setDisableCopy ] = useState( false );
  const [ errorMessage, setErrorMessage ] = useState( '' );
  const [ errorRenameMessage, setErrorRenameMessage ] = useState( '' );
  const [ openCopyDialog, setOpenCopyDialog] = useState(false);
  
  const onCloseErrorDialog = () => { setErrorMessage(''); setErrorRenameMessage(''); };

  const setCopyButtonState = () => {
    let ownItems = true;

    if ( entity.createdBy.email !== loggedEmail ) {
      ownItems = false;
    }

    if ( loggedRole === ROLES.SUPER_ADMIN || loggedRole === ROLES.ADMIN ) {
      setDisableCopy( false );
    } else {
      setDisableCopy( !ownItems );
    }
  };

  const handleOpenCopyDialog = async () => {
    let projects = [];
    let projectRes = [];
    projectRes = await getAllProjects( {} ).unwrap();

    projects = projectRes.data;
    let tempProjects = [];
    projects.forEach( ( project ) => {
      if ( typeof ( project ) !== 'undefined' ) {
        if ( project._id === selectedProject )
          return;
        tempProjects.push( project );
      }
    } );
    setCopiableProjects( tempProjects );
    setOpenCopyDialog( true );
    setCopyButtonState();
  };

  const handleCloseShareDialog = () => {
    setOpenCopyDialog(false);
    reset();
  };

  const handleConfirmCopy = async ( form, renamedName="" ) => {
    const body = { id: entity._id, project: selectedProject, newName: renamedName };
    return copySlide( body )
      .unwrap()
      .then( ( res ) => {
        handleCloseShareDialog();
        reset();
        enqueueSnackbar( 'Copy successfully', { variant: 'success' } );
      } )
      .catch( ( error ) => {
        if ( error.status === 409 ) {
          setErrorRenameMessage( error.data.error );
          return;
        } else {
          const { data } = error;
          setErrorMessage(
            data && typeof data.error === 'string'
              ? data.error
              : 'Something went wrong!'
          );
        }
      } );
  };

  const handleSubmitRenameRequest = ( newName ) => {
    handleConfirmCopy( { }, newName );
  };
  
  useEffect( () => {
    if ( copyClicked > 0 ) {
      handleOpenCopyDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ copyClicked ] );

  return (
    <Box style={{ marginLeft: '4px' }}>
      {calledFrom !== 'menu' && (
        <Tooltip title="Copy Slide To">
          <Button variant="outlined" onClick={handleOpenCopyDialog}>
            <ContentCopyIcon
              fontSize="small"
              sx={{ marginRight: '4px' }}
            />{' '}
            Copy To
          </Button>
        </Tooltip>
      )}
      <Dialog open={openCopyDialog} maxWidth="sm" fullWidth keepMounted>
        <FormContainer onSuccess={handleSubmit(handleConfirmCopy)}>
          <Divider />
          <DialogContent sx={{ maxHeight: '400px', overflow: 'auto' }}>
            <Controller
              name="project"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  fullWidth
                  id="project"
                  aria-describedby="project"
                  SelectDisplayProps={{ style: { padding: '4px 12px' } }}
                  displayEmpty
                  error={!!errors.role}
                  error-message={errors.role?.message}
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span className="placeholder">Select project</span>
                      );
                    }
                    setSelectedProject(selected);

                    return copiableProjects.find(
                      (project) => project._id === selected
                    ).name;
                  }}
                >
                  {copiableProjects.map((project) => {
                    return (
                      <MenuItem value={project._id} key={project._id}>
                        {project.name}
                      </MenuItem>
                    );
                  })}
                </CustomSelect>
              )}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            {!isCopying && (
              <Button
                variant="contained"
                color="buttonLightGray"
                disableElevation
                onClick={handleCloseShareDialog}
              >
                Cancel
              </Button>
            )}
            {!disableCopy && (
              <LoadingButton
                loading={isCopying}
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                disabled={disableCopy}
              >
                Copy
              </LoadingButton>
            )}
          </DialogActions>
        </FormContainer>
      </Dialog>

      {/* //*---------------ERROR DIALOGS---------------// */}
      <ErrorDialog errorMessage={errorMessage} onClose={onCloseErrorDialog} />
      <RenameDialog errorMessage={errorRenameMessage} onClose={onCloseErrorDialog} submitRequest={handleSubmitRenameRequest}  />
    </Box>
  );
};
export default CopyButton;
