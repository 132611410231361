import { IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import Avatar from 'components/Avatar';
import moment from 'moment';
import { getMentionText } from 'utils/mentionHelper';
import { useGetSlideCommentsQuery } from 'services/slides';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from 'hooks/useAuth';
import useToggleState from 'hooks/useToggleState';
import CancelIcon from '@mui/icons-material/Cancel';
import CommentBox from './CommentBox';
import CommentDeleteButton from './CommentDeleteButton';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';

const CommentList = ({ slideId }) => {
  const [searchParams] = useSearchParams();
  const slideCommentId = searchParams.get('slide_comment');
  const commentRef = useRef(null);
  const { data, isLoading } = useGetSlideCommentsQuery(slideId);
  const {
    user: { _id: uid }
  } = useAuth();
  const [editId, enableEdit, disableEdit] = useToggleState(null);

  const disableEditButton = (
    <IconButton size="small" color="buttonGray" onClick={disableEdit}>
      <CancelIcon fontSize="xs" />
    </IconButton>
  );

  useEffect(() => {
    if (slideCommentId && !isLoading && commentRef.current) {
      const timeout = setTimeout(() => {
        commentRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 400);
      return () => clearTimeout(timeout);
    }
  }, [slideCommentId, isLoading]);

  if (isLoading) return <LinearProgress />;

  if (!data?.length) return <Typography>There is no comment yet.</Typography>;

  const renderActionButtons = (comment) => {
    if (uid !== comment.createdBy?._id) return null;
    if (editId && editId === comment._id) {
      return disableEditButton;
    }
    return (
      <Stack flexDirection="row">
        <IconButton
          size="small"
          color="buttonGray"
          onClick={() => enableEdit(comment._id)}
        >
          <EditIcon fontSize="xs" />
        </IconButton>
        <CommentDeleteButton slideId={slideId} commentId={comment._id} />
      </Stack>
    );
  };

  return (
    <Stack spacing={1}>
      {data?.map((comment) => (
        <Stack key={comment._id} spacing={0.5}>
          {comment._id === slideCommentId && <div ref={commentRef} />}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1}>
              <Avatar src={comment.createdBy?.avatar} size={40} />
              <Stack>
                <Typography fontWeight={500} fontSize="0.9rem">
                  {`${comment.createdBy.firstName} ${comment.createdBy.lastName}`.trim()}
                </Typography>
                <Typography variant="body2" fontSize="0.75rem">
                  {moment(comment.createdAt).fromNow()}
                </Typography>
              </Stack>
            </Stack>
            {renderActionButtons(comment)}
          </Stack>
          <Stack>
            {editId === comment._id ? (
              <CommentBox
                slideId={slideId}
                comment={comment}
                onSuccess={disableEdit}
              />
            ) : (
              <Typography
                variant="body1"
                fontSize="0.85rem"
                whiteSpace="pre-wrap"
                sx={{
                  wordBreak: 'break-word'
                }}
              >
                {getMentionText(comment.content)}
              </Typography>
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default CommentList;
