import { useGroupsContext } from 'contexts/GroupsContext';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import GroupDetailHeader from './GroupDetailHeader';
import GroupChatMessages from './GroupChatMessages';
import GroupChatBox from './GroupChatBox';

const GroupDetail = () => {
  const { selectedGroup } = useGroupsContext();

  if (!selectedGroup) {
    return (
      <Box p={3}>
        <Typography>
          Select a group to view its messages or add a new group.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <GroupDetailHeader group={selectedGroup} />
      <GroupChatMessages />
      <GroupChatBox
        groupId={selectedGroup._id}
        groupMembers={[...selectedGroup.members, selectedGroup.managedBy]}
      />
    </>
  );
};

export default GroupDetail;
