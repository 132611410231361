import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CreateIcon from '@mui/icons-material/Create';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { CustomInput } from 'components/Shared/Input';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useUpdateSlideMutation } from 'services/slides';
import { SlideViewerContext } from '../../../hooks/useSlideViewerContext';
import { RenameSlideWarningDialog } from 'features/Slidebox/FileExplorer/components/ActionMenuComponents/RenameSlideWarningDialog';
import { useGetProjectFolderChildrenByIdMutation } from 'services/projects';

const SlideDetails = ({ hidden, handleDrawerOpen }) => {
  const [state, dispatch] = useContext(SlideViewerContext);
  const [renameSlide] = useUpdateSlideMutation();
  const { enqueueSnackbar } = useSnackbar();

  const slide = useMemo(() => state.slide, [state.slide]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [slideName, setSlideName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openRenamingModal, setOpenRenamingModal] = useState(false);
  const [currentProjectSlides, setCurrentProjectSlides] = useState([]);

  const [getProjectFolderChildrenById] =
    useGetProjectFolderChildrenByIdMutation();

  const handleToggleEditMode = () => {
    setEditMode(() => !editMode);
  };

  const handleSlideNameChange = (e) => {
    setSlideName(e.target.value);
  };

  const handleSaveSlideName = async () => {
    try {
      const formattedSlideName = slideName.trim();

      if (!formattedSlideName) return;
      if (formattedSlideName === state.slide.name) return;

      const projectSlideNames = currentProjectSlides.filter((_slide) => _slide._id !== slide._id).map((slide) => slide.name);
      
      if (projectSlideNames.includes(formattedSlideName)) {
        setOpenRenamingModal(true);
        return;
      }

      setIsLoading(true);
      const response = await renameSlide({
        id: state.slide._id,
        name: formattedSlideName,
        project: state.slide.project
      }).unwrap();
      if (response.success) {
        dispatch({
          type: 'SET_STATE',
          key: 'slide',
          value: {
            ...state.slide,
            name: formattedSlideName
          }
        });
        handleToggleEditMode();
        enqueueSnackbar('Rename filename', {
          variant: 'success'
        });
      }
    } catch (error) {
      enqueueSnackbar('Rename filename failed !', {
        variant: 'error'
      });
      setSlideName(state.slide.name);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickArrow = () => {
    setOpen(!open);
    if (hidden) handleDrawerOpen();
  };

  const handleCloseRenamingModal = () => {
    setOpenRenamingModal(false);
  }

  useEffect(() => {
    if (hidden) setOpen(false);
  }, [hidden]);

  useEffect(() => {
    setSlideName(state.slide.name);
  }, [state.slide, editMode]);

  useEffect(() => {
    const getChildren = async (id) => {
      try {
        if ( id ) {
          const data = await getProjectFolderChildrenById( id ).unwrap();
          setCurrentProjectSlides( data );
        } else {
          setCurrentProjectSlides( [] );
        }
      } catch ( err ) {
        setCurrentProjectSlides( [] );
      }
    };

    if (state.slide) {
      getChildren(state.slide.project);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.slide]);

  return (
    <List>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          onClick={() => handleClickArrow()}
          sx={{
            minHeight: 48,
            justifyContent: !hidden ? 'initial' : 'center',
            px: 2.5
          }}
        >
          {hidden ? (
            <Tooltip title="Slide Details" placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !hidden ? 3 : 'auto',
                  justifyContent: 'center'
                }}
              >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </ListItemIcon>
            </Tooltip>
          ) : (
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: !hidden ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </ListItemIcon>
          )}
          <ListItemText
            primary={`Slide Details: ${slideName}`}
            primaryTypographyProps={{
              sx: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                '& .MuiTypography-root': { fontWeight: '700' },
                opacity: hidden ? 0 : 1,
                fontWeight: '700',
              },
            }}
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ padding: '8px 20px' }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              Filename:
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}
            >
              {editMode ? (
                <CustomInput
                  fullWidth
                  value={slideName}
                  onChange={handleSlideNameChange}
                  inputProps={{
                    style: {
                      padding: '6px 8px',
                      fontSize: '14px'
                    }
                  }}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    sx={{
                      mr: 1,
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {slideName}
                  </Typography>
                  <IconButton color="primary" onClick={handleToggleEditMode}>
                    <CreateIcon sx={{ fontSize: '15px' }} />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Grid item xs={6}>
              Unique Id:
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}
            >
              {slide._id}
            </Grid>
            <Grid item xs={6}>
              Height:
            </Grid>
            <Grid item xs={6}>
              {slide.height}
            </Grid>
            <Grid item xs={6}>
              Width:
            </Grid>
            <Grid item xs={6}>
              {slide.width}
            </Grid>
            <Grid item xs={6}>
              Magnification:
            </Grid>
            <Grid item xs={6}>
              -
            </Grid>
            <Grid item xs={6}>
              Resolution:
            </Grid>
            <Grid item xs={6}>
              -
            </Grid>
          </Grid>
          {editMode && (
            <Grid container spacing={2} sx={{ marginTop: '2px' }}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  disabled={isLoading}
                  fullWidth
                  size="small"
                  onClick={handleToggleEditMode}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  size="small"
                  loading={isLoading}
                  onClick={handleSaveSlideName}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Box>
      </Collapse>
      <RenameSlideWarningDialog open={openRenamingModal} onClose={handleCloseRenamingModal} />
    </List>
  );
};
export default SlideDetails;
