import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  Box,
  DialogContent,
  Typography,
  TextField,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const RenameDialog = ({ errorMessage, onClose = () => {}, submitRequest }) => {
  const [ open, setOpen ] = useState( false );
  const [ newName, setNewName ] = useState( '' );

  useEffect(() => {
    if (errorMessage) setOpen(true);
  }, [errorMessage])

  const close = () => {
    setOpen( false );
    submitRequest( newName );
    onClose();
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth keepMounted>
      <DialogContent sx={{ textAlign: 'center' }}>
        <HighlightOffIcon color="error" sx={{ fontSize: '60px' }} />
        <Typography variant="h5">{errorMessage}</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2
          }}
        >
          <TextField
            label="New Name"
            value={newName}
            onChange={( e ) => setNewName( e.target.value )}
            sx={{ mr: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={close}
          >
            Send
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default RenameDialog;
