import React, { useState } from 'react'
import {
  Button,
  Stack,
  FormControl,
  Typography,
  Box,
  useTheme
} from '@mui/material'
import { FormContainer } from 'react-hook-form-mui'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useForm } from 'react-hook-form'
import { CustomInput } from '../../../components/Shared/Input'
import { useForgotPasswordMutation } from '../../../services/user'
import { LoadingButton } from '@mui/lab'

const ForgotPasswordDialog = (props) => {
  const theme = useTheme()
  const [requestError, setRequestError] = useState()
  const [showRetryDialog, setShowRetryDialog] = useState(false)
  const [open, setOpen] = useState(false)
  const [forgotPassword, { isLoading, isSuccess, reset: resetRequest }] =
    useForgotPasswordMutation()
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { email: '', role: '' } })
  const handleClose = (event, reason) => {
    if (reason) return
    setRequestError('')
    setOpen(false)
    reset()
    resetRequest()
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleSubmitForgotPassword = async (form) => {
    try {
      await forgotPassword(form).unwrap()
      setShowRetryDialog(false)
    } catch (err) {
      const { data } = err
      setRequestError(
        data && typeof data.error === 'string'
          ? data.error
          : 'Something went wrong!'
      )
      setShowRetryDialog(true)
    }
  }
  return (
    <>
      <Button onClick={handleOpen} sx={{ ...props.sx }}>
        <Typography variant="h5" color="primary" sx={{ fontWeight: 600 }}>
          Forgot password?
        </Typography>
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        {isSuccess && (
          <>
            <DialogTitle>
              <Typography
                variant="h6"
                color="primary"
                sx={{ fontWeight: '600' }}
              >
                Check email for reset link
              </Typography>
              <Typography mt={2} variant="subtitle2">
                A password reset link has been sent to your email, but may take
                several minutes to show up in your inbox.
                <br />
              </Typography>
              <Typography variant="caption">
                <strong>Please wait at least 10 minutes</strong> before
                attempting another reset.
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'center' }}>
              <Button
                fullWidth
                variant="contained"
                color="gray"
                disableElevation
                onClick={handleClose}
                sx={{ mt: 2 }}
              >
                Close
              </Button>
            </DialogContent>
          </>
        )}
        {showRetryDialog && (
          <DialogContent sx={{ textAlign: 'center' }}>
            <HighlightOffIcon color="error" sx={{ fontSize: '60px' }} />
            <Typography variant="h5">{requestError}</Typography>
            <Button
              variant="contained"
              color="gray"
              disableElevation
              onClick={() => {
                setRequestError('')
                setShowRetryDialog(false)
              }}
              sx={{ mt: 2 }}
            >
              Retry
            </Button>
          </DialogContent>
        )}
        {!showRetryDialog && !isSuccess && (
          <FormContainer onSuccess={handleSubmit(handleSubmitForgotPassword)}>
            <DialogTitle>
              <Typography
                variant="h6"
                color="primary"
                sx={{ fontWeight: '600' }}
              >
                Forgot your password ?
              </Typography>
              <Typography variant="subtitle2">
                Please enter the email you use to sign in to{' '}
                <strong
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: '600'
                  }}
                >
                  Targeted Bioscience
                </strong>
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Stack direction="column" gap={2}>
                <FormControl>
                  <CustomInput
                    fullWidth
                    inputProps={{ style: { padding: '4px 12px' } }}
                    id="email"
                    type="email"
                    {...register('email', {
                      required: 'This field is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address'
                      }
                    })}
                    aria-describedby="email"
                    placeholder="Email Address"
                    error={!!errors.email}
                    error-message={errors.email?.message}
                  />
                </FormControl>
              </Stack>
              <Box
                mt={2}
                display={'flex'}
                flexDirection="column"
                alignItems={'center'}
              >
                <LoadingButton
                  fullWidth
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Request password reset
                </LoadingButton>
                {!isLoading && (
                  <Button
                    sx={{ mt: 2 }}
                    fullWidth
                    color="primary"
                    disableElevation
                    onClick={handleClose}
                  >
                    Back to login
                  </Button>
                )}
              </Box>
            </DialogContent>
          </FormContainer>
        )}
      </Dialog>
    </>
  )
}

export default ForgotPasswordDialog
