import { Box, IconButton, Stack } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDraggable } from '@dnd-kit/core';

const AnnotationItemHead = ({
  editMode,
  id,
  stroke,
  onEdit,
  onDelete,
  children
}) => {
  const { listeners, setNodeRef, transform, setActivatorNodeRef } =
    useDraggable({
      id: `draggable-${id}`,
      data: {
        id,
        stroke
      },
      disabled: !id
    });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        backgroundColor: '#F3F7F0'
      }
    : {};

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...style
      }}
      ref={setNodeRef}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>{children}</Box>

      {!editMode && (
        <Stack direction="row">
          <IconButton
            color="secondary"
            ref={setActivatorNodeRef}
            {...listeners}
          >
            <DragIndicatorIcon sx={{ fontSize: '18px' }} />
          </IconButton>
          <IconButton color="primary" onClick={onEdit}>
            <CreateIcon sx={{ fontSize: '18px' }} />
          </IconButton>
          <IconButton color="error" onClick={onDelete}>
            <DeleteForeverIcon sx={{ fontSize: '18px' }} />
          </IconButton>
        </Stack>
      )}
    </Box>
  );
};

export default AnnotationItemHead;
