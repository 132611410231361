import {
  Avatar,
  Badge,
  Box,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useGetNotificationsQuery,
  useReadNotificationMutation,
  useReadAllNotificationMutation
} from '../../../services/appServices';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { MarkunreadRounded, DraftsRounded } from '@mui/icons-material';
import moment from 'moment';
import './style.scss';
import useNotificationSocket from 'hooks/useNotificationSocket';
import { useReducer } from 'react';
import { useSlideBox } from '../../../hooks/slideBox/useSlideBox';
import { navigateNotification } from '../../../utils/notificationHelper';

const initialState = {
  notifications: [],
  unRead: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_NOTIFICATION': {
      return {
        ...state,
        notifications: [action.value, ...state.notifications],
        unRead: state.unRead + 1
      };
    }
    case 'READ_ALL': {
      const newNotifications = state.notifications.map((v) => ({
        ...v,
        isRead: true
      }));
      return {
        ...state,
        notifications: newNotifications,
        unRead: 0
      };
    }
    case 'READ': {
      const newNotifications = state.notifications.map((v) => {
        if (v._id === action.value?._id) {
          return {
            ...v,
            isRead: true
          };
        }
        return v;
      });
      return {
        ...state,
        notifications: newNotifications,
        unRead: newNotifications.filter((v) => !v.isRead).length
      };
    }
    case 'SET_NOTIFICATION': {
      return {
        ...state,
        notifications: action.value.notifications,
        unRead: action.value.unRead
      };
    }
    default:
      return state;
  }
};

const NotificationMenu = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { handleSetCurrentView } = useSlideBox();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const openProfileDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClickAction = async (notification) => {
    if (!notification.isRead) {
      await readNotification(notification._id);
      refetch();
      dispatch({ type: 'READ', value: notification });
    }
    if (notification.action) {
      await navigateNotification(
        notification.action,
        navigate,
        handleSetCurrentView
      );
    }
  };

  const [readAllNotification] = useReadAllNotificationMutation('');
  const [readNotification] = useReadNotificationMutation();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dropdown = Boolean(anchorEl);

  const handleReadAllNotification = async () => {
    await readAllNotification().unwrap();
    refetch();
    dispatch({ type: 'READ_ALL' });
  };
  const { data, refetch } = useGetNotificationsQuery();
  useNotificationSocket(dispatch, data, refetch);
  useEffect(() => {
    if (data?.data?.length) {
      const initNotifications = {
        notifications: data.data,
        unRead: data?.data.filter((v) => !v.isRead).length
      };
      dispatch({
        type: 'SET_NOTIFICATION',
        key: 'notifications',
        value: initNotifications
      });
    }
  }, [data]);

  return (
    <div className="notification-header">
      <Badge badgeContent={state.unRead} color="error">
        <NotificationsRoundedIcon
          className="notification-header_icon"
          onClick={(event) =>
            data && data.data.length ? openProfileDropdown(event) : null
          }
          sx={{
            fontSize: '50px',
            color: theme.palette.text.textGray1,
            cursor: 'pointer'
          }}
        />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        id="notification_menu"
        open={dropdown}
        onClose={handleClose}
        onClick={handleClose}
        autoFocus={false}
        PaperProps={{
          elevation: 0,
          sx: {
            '& .MuiList-root': {
              padding: 0
            },
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography
          variant="h4"
          sx={{
            padding: '12px 16px 8px',
            fontWeight: 'bold',
            position: 'fixed',
            top: 0,
            zIndex: 100,
            width: '100%',
            background: 'white'
          }}
        >
          Notifications
        </Typography>
        <Button
          size="small"
          variant="contained"
          color="gray"
          sx={{
            position: 'fixed',
            top: '12px',
            right: '16px',
            zIndex: 101, // Higher than the Typography's z-index
            marginTop: '5px'
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleReadAllNotification();
          }}
        >
          Mark All as Read
        </Button>

        <Box
          sx={{
            marginTop: '62px',
            marginBottom: '5px',
            overflowX: 'hidden',
            overflowY: 'auto',
            maxHeight: '66.6667vh'
          }}
        >
          {state &&
            state.notifications.map((noti) => (
              <MenuItem
                onClick={() => handleClickAction(noti)}
                key={noti._id}
                style={{
                  padding: '5px',
                  backgroundColor: !noti.isRead && 'rgba(87, 35, 115, 0.08)'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    padding: '6px 16px',
                    cursor: 'pointer'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Avatar
                      sx={{
                        marginRight: '6px',
                        textTransform: 'uppercase',
                        width: '30px',
                        height: '30px',
                        backgroundColor: noti.isRead
                          ? 'gray'
                          : 'rgba(87, 35, 115, 0.2)'
                      }}
                    >
                      {noti.isRead ? (
                        <DraftsRounded fontSize="16" />
                      ) : (
                        <MarkunreadRounded fontSize="16" color="primary" />
                      )}
                    </Avatar>
                  </Box>
                  <ListItemText
                    primary={moment(noti.createdAt).format('MMM DD, YYYY LTS')}
                    secondary={
                      <Typography
                        sx={{
                          display: 'block',
                          maxWidth: '350px',
                          whiteSpace: 'break-spaces',
                          overflowWrap: 'break-word'
                        }}
                        component="div"
                        variant="body2"
                        color="text.primary"
                      >
                        {noti.description}
                      </Typography>
                    }
                  />
                </Box>
              </MenuItem>
            ))}
        </Box>
      </Menu>
    </div>
  );
};
export default NotificationMenu;
