import { api } from './api';

export const slideSettingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveSettings: build.mutation({
      query: (body) => ({
        url: `/slideSettings`,
        method: 'POST',
        body
      })
    })
  })
});

export const { useSaveSettingsMutation } = slideSettingsApi;

export const {
  endpoints: { saveSettings }
} = slideSettingsApi;
