import { useMemo, useState } from 'react';
import {
  Storage as StorageIcon,
  ArrowRight as ArrowRightIcon,
  ArrowDropDown as ArrowDropDownIcon,
  FolderShared
} from '@mui/icons-material';
import {
  Button,
  List,
  ListItemText,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  useTheme
} from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useSelector } from 'react-redux';
import {
  FILE_TYPES,
  MANAGED_BY_ME_FOLDER_NAME,
  ROLES,
  SHARED_WITH_ME_FOLDER_NAME
} from '../../../constants';
import NavigationPaneFile from './components/NavigationPaneFile';
import NavigationPaneFolder from './components/NavigationPaneFolder';
import {
  selectFoldersAndFiles,
  selectCurrentFolderView
} from '../../../stores/slidebox/slideboxSlice';
import { useAuth } from '../../../hooks/useAuth';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';
import { useSlideBox } from 'hooks/slideBox/useSlideBox';

const FolderTree = () => {
  const theme = useTheme();
  const { dispatch } = useSlideboxContext();

  const {
    user: { role }
  } = useAuth();

  const foldersAndFiles = useSelector(selectFoldersAndFiles);

  const [open, setOpen] = useState(true);
  const { _id: currentViewId, parent: currentViewParent } = useSelector(
    selectCurrentFolderView
  );

  const { handleSetCurrentView } = useSlideBox();

  const handleItemClick = (id) => {
    dispatch({ type: 'SET_STATE', key: 'selectedRows', value: [] });
    handleSetCurrentView(id);
  };

  const childrenTreeNodes = useMemo(() => {
    return foldersAndFiles.filter((item) => !item.parent);
  }, [foldersAndFiles]);

  const handleExpand = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const renderMangedByMeProjects = useMemo(() => {
    return (
      <ListItem
        selected={
          currentViewId === MANAGED_BY_ME_FOLDER_NAME ||
          currentViewParent === MANAGED_BY_ME_FOLDER_NAME
        }
        onClick={() => handleItemClick(MANAGED_BY_ME_FOLDER_NAME)}
        sx={{
          color: theme.palette.primary.main,
          borderRadius: ' 0px 50px 50px 0px',
          ' .MuiListItemSecondaryAction-root': {
            left: '0px',
            right: 'unset'
          },
          ' .MuiListItemIcon-root': {
            color: theme.palette.primary.main
          }
        }}
        disablePadding
      >
        <ListItemButton
          role={undefined}
          dense
          sx={{
            paddingLeft: '30px',
            paddingRight: '0px!important',
            borderRadius: ' 0px 50px 50px 0px'
          }}
        >
          <ListItemIcon sx={{ minWidth: 'unset' }}>
            <PeopleAltIcon
              sx={{
                marginRight: '10px',
                fontSize: '20px'
              }}
            ></PeopleAltIcon>
          </ListItemIcon>
          <ListItemText
            sx={{
              my: 0,
              textTransform: 'none',
              '& span': { fontSize: '16px' }
            }}
            primary="Managed by me"
          />
        </ListItemButton>
      </ListItem>
    );
  }, [role, currentViewId]);

  const renderSharedWithMeProjects = useMemo(() => {
    return (
      <ListItem
        selected={
          currentViewId === SHARED_WITH_ME_FOLDER_NAME ||
          currentViewParent === SHARED_WITH_ME_FOLDER_NAME
        }
        onClick={() => handleItemClick(SHARED_WITH_ME_FOLDER_NAME)}
        sx={{
          color: theme.palette.primary.main,
          borderRadius: ' 0px 50px 50px 0px',
          ' .MuiListItemSecondaryAction-root': {
            left: '0px',
            right: 'unset'
          },
          ' .MuiListItemIcon-root': {
            color: theme.palette.primary.main
          }
        }}
        disablePadding
      >
        <ListItemButton
          role={undefined}
          dense
          sx={{
            paddingLeft: '30px',
            paddingRight: '0px!important',
            borderRadius: ' 0px 50px 50px 0px'
          }}
        >
          <ListItemIcon sx={{ minWidth: 'unset' }}>
            <FolderShared
              sx={{
                marginRight: '10px',
                fontSize: '20px'
              }}
            ></FolderShared>
          </ListItemIcon>
          <ListItemText
            sx={{
              my: 0,
              textTransform: 'none',
              '& span': { fontSize: '16px' }
            }}
            primary="Shared with me"
          />
        </ListItemButton>
      </ListItem>
    );
  }, [role, currentViewId]);

  return (
    <>
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          flexGrow: '1',
          overflow: 'auto',
          paddingTop: '0px',
          marginTop: '8px',
          ' .Mui-selected': {
            borderWidth: ' 2px 2px 2px 0px',
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main
          }
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItem
          selected={currentViewId === null}
          onClick={() => handleItemClick(null)}
          sx={{
            color: theme.palette.primary.main,
            borderRadius: ' 0px 50px 50px 0px',
            ' .MuiListItemSecondaryAction-root': {
              left: '0px',
              right: 'unset'
            }
          }}
          secondaryAction={
            <Button
              onClick={(event) => handleExpand(event)}
              sx={{ minWidth: '30px', padding: '0px' }}
            >
              {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </Button>
          }
          disablePadding
        >
          <ListItemButton
            role={undefined}
            dense
            sx={{
              paddingLeft: '30px',
              paddingRight: '8pxpx!important',
              borderRadius: ' 0px 50px 50px 0px'
            }}
          >
            <ListItemIcon sx={{ minWidth: 'unset' }}>
              <StorageIcon
                sx={{
                  marginRight: '10px',
                  fontSize: '20px',
                  color: theme.palette.primary.main
                }}
              ></StorageIcon>
            </ListItemIcon>
            <ListItemText
              sx={{
                my: 0,
                textTransform: 'none',
                '& span': { fontSize: '16px' }
              }}
              primary="Slidebox"
            />
          </ListItemButton>
        </ListItem>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{
            ' .MuiCollapse-wrapperInner': { width: 'unset' },
            marginBottom: '4px'
          }}
        >
          {childrenTreeNodes.map((item) => {
            if (
              item.type === FILE_TYPES.FOLDER ||
              item.type === FILE_TYPES.CASE
            ) {
              return (
                <NavigationPaneFolder
                  folder={item}
                  key={item._id}
                  paddingLeft={30}
                  setOpen={setOpen}
                  style={{ padding: '0px 0px 0px 0px' }}
                />
              );
            }
            return (
              <NavigationPaneFile
                file={item}
                key={item._id}
                paddingLeft={30}
                style={{ padding: '0px 0px 0px 0px' }}
              />
            );
          })}
        </Collapse>
        {(role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN) &&
          renderMangedByMeProjects}
        {role !== ROLES.SUPER_ADMIN && renderSharedWithMeProjects}
      </List>
    </>
  );
};
export default FolderTree;
