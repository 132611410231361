import { useMemo } from 'react';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import { useAuth } from './useAuth';
import { useGetMembersQuery } from '../services/user';

const useTaggableUsers = ({ users = [], enableFetch = false }) => {
  const {
    user: { _id: uid }
  } = useAuth();
  const { data = [], isLoading } = useGetMembersQuery(
    {},
    {
      skip: !enableFetch
    }
  );

  return useMemo(() => {
    if (enableFetch && isLoading) return [];
    const userCollection = enableFetch ? [...data, ...users] : users;
    const members = filter(
      userCollection,
      (item) => !item.isDeleted && item.isActive && item._id !== uid
    );
    return uniqBy(
      members.map((item) => ({
        id: item._id,
        display: `${item.firstName} ${item.lastName}`.trim()
      })),
      'id'
    );
  }, [enableFetch, isLoading, data, users, uid]);
};

export default useTaggableUsers;
