import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { useAuth } from 'hooks/useAuth';
import ChatMessageActions from './ChatMessageActions';
import ChatMessageEditBox from './ChatMessageEditBox';
import useToggleState from 'hooks/useToggleState';
import { getMentionText } from 'utils/mentionHelper';

function ChatMessage({ message }) {
  const {
    user: { _id: userId }
  } = useAuth();
  const isOwner = userId.toString() === message.sentBy?._id.toString();
  const [editing, enableEdit, disableEdit] = useToggleState(false);

  return (
    <Stack direction="row" flex={1} spacing={1}>
      <Avatar src={message.sentBy?.avatar} />
      <Stack flex={1} bgcolor="white" borderRadius={2} p={1}>
        <Stack
          direction="row"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
          flexBasis={34}
        >
          <Typography color={grey[500]} fontWeight={500}>
            {message.sentBy
              ? `${message.sentBy.firstName} ${message.sentBy.lastName}`.trim()
              : 'Unknown'}
          </Typography>
          {isOwner && !editing && (
            <ChatMessageActions message={message} onEdit={enableEdit} />
          )}
        </Stack>
        {editing ? (
          <ChatMessageEditBox message={message} onClose={disableEdit} />
        ) : (
          <Typography paragraph>{getMentionText(message.content)}</Typography>
        )}
        <Stack alignItems="flex-end">
          <Typography variant="caption" fontSize="x-small" color={grey[500]}>
            {moment(message.createdAt).fromNow()}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ChatMessage;
