import { AddToQueue, DeleteOutline } from '@mui/icons-material';
import { Divider, Tooltip, Button, Box } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FILE_TYPES, ROLES } from '../../../../../constants';
import {
  Info as InfoIcon,
  OpenWith as OpenWithIcon
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import ShareButton from '../ShareButton';
import { useAuth } from 'hooks/useAuth';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';

const EnhancedTableToolbar = ({ onClickDeleteIcon }) => {
  const {
    state,
    dispatch,
    isSelected,
    isSingleSelected,
    firstSelectedElement,
    isOwner
  } = useSlideboxContext();

  const {
    user: { role }
  } = useAuth();

  const navigate = useNavigate();

  const openWithViewer = useCallback(() => {
    navigate(`/slideViewer/${state.selectedRows[0]}`, { replace: true });
  }, [state.selectedRows, navigate]);

  const openDetailDrawer = () => {
    dispatch({ type: 'SET_STATE', key: 'openDetail', value: true });
  };

  const isSingleFileSelect = useMemo(
    () => isSingleSelected && firstSelectedElement.type === FILE_TYPES.FILE,
    [isSingleSelected, firstSelectedElement]
  );

  const isMultipleSelectedHasFile = useMemo(
    () => state.selectedRows.some((row) => row.type === FILE_TYPES.FILE),
    [state.selectedRows]
  );

  const ActionButtonComponent = useMemo(() => {
    if (!isOwner || isMultipleSelectedHasFile) return null;

    const handleMove = () => {
      dispatch({ type: 'SET_STATE', key: 'openMoveFolder', value: true });
    };

    if (isSingleFileSelect) {
      return (
        <Box style={{marginLeft:'4px'}}>
          <Tooltip title="Open with viewer">
            <Button variant="outlined" onClick={openWithViewer}>
              <AddToQueue />
            </Button>
          </Tooltip>
        </Box>
      );
    }
    return (
      <Box style={{marginLeft:'4px'}}>
        <Tooltip title="Move">
          <Button variant="outlined" onClick={handleMove}>
            <OpenWithIcon />
          </Button>
        </Tooltip>
      </Box>
    );
  }, [
    isOwner,
    isMultipleSelectedHasFile,
    isSingleFileSelect,
    openWithViewer,
    dispatch
  ]);
  const noFolderSelected  = () => {
    let folderExists = false;
    state.selectedRows.forEach((row)=>{
      if(row.type === 'folder'){
        folderExists = true;
      }
    })
     return !folderExists;
  }

  return (
    isSelected && (
      <>
        {((role === ROLES.ADMIN || role === ROLES.USER) && noFolderSelected()) && (
          <ShareButton entities={state.selectedRows} calledFrom="tableTop"/>
        )} 
        {(role === ROLES.SUPER_ADMIN && noFolderSelected() && state.selectedRows.length < 2) && (
          <ShareButton entities={state.selectedRows} calledFrom="tableTop"/>
        )}
        {isOwner && (
          <Box style={{marginLeft:'4px'}}>
            <Tooltip title="Delete">
              <Button variant="outlined" onClick={() => onClickDeleteIcon()}>
                <DeleteOutline />
              </Button>
            </Tooltip>
          </Box>
        )}

        {ActionButtonComponent}

        {isSingleSelected &&
          firstSelectedElement.type !== FILE_TYPES.FILE && (
            <>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <Box style={{marginLeft:'4px'}}>
                <Tooltip title="Info">
                  <Button variant="outlined" onClick={openDetailDrawer}>
                    <InfoIcon />
                  </Button>
                </Tooltip>
              </Box>
            </>
          )}
      </>
    )
  );
};

EnhancedTableToolbar.propTypes = {
  onClickShareIcon: PropTypes.func.isRequired,
  onClickDeleteIcon: PropTypes.func.isRequired
};
export default EnhancedTableToolbar;
