import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import React, { Fragment, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from 'hooks/useAuth';
import { getPublicImage } from '../../../../utils';

const tibyimage = getPublicImage('tiby.svg');

const CommentHeader = ({ onPushData }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const { user } = useAuth();

  const dummyData = {
    title: 'Lorem ipsum dolor',
    description:
      'Lorem ipsum dolor sit amet consesss adipisicing elit. Quisquam',
    comments: [
      {
        id: Math.floor(Math.random() * 1000000),
        content: 'Lorem ipsum dolor sit amet consesss adipisicing elit.',
        createdBy: user,
        createdAt: new Date()
      },
      {
        id: Math.floor(Math.random() * 1000000),
        content: 'Lorem ipsum dolor sit amet consesss adipisicing elit.',
        createdBy: {
          _id: '123',
          firstName: 'Tiby',
          lastName: undefined,
          avatar: 'https://i.pravatar.cc/300?img=1'
        },
        createdAt: new Date()
      }
    ]
  };
  const dummyDataList = Array.from({ length: 10 }, () => dummyData);

  const handleLoadData = () => {
    setIsLoading(true);
    setTimeout(() => {
      setData(dummyDataList);
      setIsLoading(false);
    }, 1000);
  };

  const handleToggle = () => {
    if (!open) {
      handleLoadData();
    }
    setOpen(!open);
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setTimeout(() => {
      const newData = data.filter((item, i) => i !== index);
      setData(newData);
      setDeleteIndex(null);
    }, 500);
  };

  const handleClick = (index) => {
    const newData = data.filter((item, i) => i === index)[0];
    onPushData(newData.comments);
    setOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        gap={0.5}
        p={1}
        sx={{ borderBottom: 1, borderColor: 'grey.500' }}
      >
        <IconButton
          onClick={handleToggle}
          color="primary"
          variant="contained"
          size="small"
        >
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <img src={tibyimage} alt='Close' style={{ width: '36px', height: '36px', marginRight: '8px', verticalAlign: 'middle' }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          display: open ? 'block' : 'none',
          height: 'calc(100% - 50px)',
          backgroundColor: 'white',
          overflowY: 'auto',
          overflowX: 'hidden',
          zIndex: 1,
          top: 50,
          left: 0
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <List sx={{ width: '100%' }}>
            {data.map((item, index) => (
              <Fragment
                key={index}
                sx={{
                  '& > :not(style) + :not(style)': {
                    marginTop: '0.5rem'
                  }
                }}
              >
                <Divider component="li" />
                <ListItem
                  secondaryAction={
                    <IconButton
                      onClick={() => handleDelete(index)}
                      edge="end"
                      aria-label="delete"
                    >
                      {deleteIndex === index ? (
                        <CircularProgress size={20} />
                      ) : (
                        <DeleteIcon />
                      )}
                    </IconButton>
                  }
                  alignItems="flex-start"
                  disablePadding
                >
                  <ListItemButton onClick={() => handleClick(index)}>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body1"
                          whiteSpace={'pre-wrap'}
                          color="text.primary"
                        >
                          {item.title}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          whiteSpace={'pre-wrap'}
                          variant="body2"
                          color="text.primary"
                        >
                          {item.description}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </Fragment>
            ))}
          </List>
        )}
      </Box>
    </>
  );
};

export default CommentHeader;
