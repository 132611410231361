import {
  getMessaging,
  getToken,
  onMessage,
  isSupported
} from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { firebaseConfig, firebasePublicKey } from '../constants/firebaseConfig';
export const initFCM = () => {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });

  async function getFCMToken() {
    let permission = Notification.permission;
    if (permission !== 'granted') {
      try {
        permission = await Notification.requestPermission();
      } catch (e) {
        console.log('FCM failed to request permission', e);
      }
    }
    if (permission !== 'granted') {
      throw new Error('Permission denied');
    }
    if (!(await isSupported())) {
      console.warn('Browser does not support FCM');
    }
    return getToken(messaging, { vapidKey: firebasePublicKey });
  }

  return {
    getFCMToken,
    onMessageListener,
  };
};
