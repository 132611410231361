import { Box, CircularProgress, Container } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserQuery } from 'services/user';
import CustomBreadcrumbs from 'v2/components/custom-breadcrumbs/custom-breadcrumbs';
import EmptyContent from 'v2/components/empty-content';
import UserNewEditForm from './UserNewEditForm';

const UserDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, refetch, isLoading } = useGetUserQuery(id, {
    refetchOnMountOrArgChange: false
  });

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (!data) return <EmptyContent filled title="No Data" sx={{ py: 10 }} />;

  return (
    <Container
      sx={{
        py: 2
      }}
      maxWidth={false}
    >
      <CustomBreadcrumbs
        heading="Edit"
        links={[
          {
            name: 'Dashboard',
            href: '/dashboard'
          },
          {
            name: 'User'
          },
          { name: `${data.firstName} ${data.lastName}` }
        ]}
        sx={{
          mb: { xs: 3, md: 5 }
        }}
      />
      <UserNewEditForm
        currentUser={data}
        onCallback={() => {
          refetch();
        }}
        onDeleted={() => {
          navigate('/dashboard');
        }}
      />
    </Container>
  );
};

export default UserDetail;
