import { api } from './api';

export const groupAPI = api.injectEndpoints({
  endpoints: (build) => ({
    listGroups: build.query({
      query: ({ limit, cursor, keyword }) => ({
        url: `/groups?limit=${limit}&cursor=${cursor ?? ''}&keyword=${
          keyword ?? ''
        }`
      }),
      transformResponse: (response) => response.data
    }),
    createGroup: build.mutation({
      query: (body) => ({
        url: '/groups/',
        method: 'POST',
        body
      })
    }),
    editGroup: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/groups/${id}`,
        method: 'PATCH',
        body
      })
    })
  })
});

export const {
  useListGroupsQuery,
  useCreateGroupMutation,
  useEditGroupMutation
} = groupAPI;

export const {
  endpoints: { listGroups }
} = groupAPI;
