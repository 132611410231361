import axios from 'axios'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_API}api/v1`
})
// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['Access-Control-Allow-Methods'] =
      'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    // Do something before request is sent
    const token = localStorage.getItem('token') || null
    if (token) {
      config.headers['Authorization'] = token
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

export default instance
