import ToggleButtonGroup from "../ToggleButtonGroup";

import SquareOutlined from "@mui/icons-material/SquareOutlined";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import PolylineIcon from '@mui/icons-material/Polyline';
import ToggleButton from "../ToggleButton/ToggleButton";

const DrawToolbar = ( { anno, context } ) => {
  const [tool, setTool] = context
  const handleToolChange = (event, newTool) => {
    anno.setDrawingTool(newTool);
    anno.setDrawingEnabled(true);
    setTool(newTool);
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={tool}
      exclusive
      onChange={handleToolChange}
    >
      <ToggleButton
        value="rect"
        ariaLabel="Rectangle"
        disabled={tool === 'rect'}
      >
        <SquareOutlined />
      </ToggleButton>
      <ToggleButton
        value="ellipse"
        ariaLabel="Ellipse"
        disabled={tool === 'ellipse'}
      >
        <CircleOutlined />
      </ToggleButton>
      <ToggleButton
        value="freehand"
        ariaLabel="Freehand"
        disabled={tool === 'freehand'}
      >
        <PolylineIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  )
};

export default DrawToolbar;
