import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  ListItem,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';

const CaseIdentifierItem = ({
  data,
  index,
  handleSelected,
  handleClose,
  handleDelete,
  handleUpdate,
  setCaseChanged,
  otherPatientIds
}) => {
  const { patientId, blockId, treatmentGroup, isSelected, recuts, _id } = data;

  const [errors, setErrors] = useState({
    patientId: '',
    blockId: '',
    treatmentGroup: '',
    recuts: ''
  });

  const [caseIdentifier, setCaseIdentifier] = useState({
    _id,
    patientId,
    blockId,
    treatmentGroup,
    recuts
  });

  useEffect(() => {
    setCaseIdentifier({
      _id,
      patientId,
      blockId,
      treatmentGroup,
      recuts
    });
  }, [patientId, blockId, treatmentGroup, recuts, _id]);

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    const newCaseIdentifier = { ...caseIdentifier, [name]: value };
    setCaseIdentifier(newCaseIdentifier);
    const validateData = validateCaseIdentifiers(newCaseIdentifier);
    setErrors(validateData);
  };

  const handleCancel = () => {
    if (caseIdentifier._id === 'new') {
      handleDelete(index, true);
    } else {
      handleClose(index);
    }
  };

  const isChanged = () => {
    return (
      patientId !== caseIdentifier.patientId ||
      blockId !== caseIdentifier.blockId ||
      treatmentGroup !== caseIdentifier.treatmentGroup ||
      recuts !== caseIdentifier.recuts
    );
  };

  const validateCaseIdentifiers = (item) => {
    const errors = {
      patientId: '',
      blockId: '',
      treatmentGroup: '',
      recuts: ''
    };

    if (!item.patientId || item.patientId.trim() === '') {
      errors.patientId = 'Patient Id is required';
    }

    if (otherPatientIds.includes(item.patientId)) {
      errors.patientId = 'Patient Id is duplicated';
    }

    return errors;
  };

  const isError = errors.patientId || errors.blockId || errors.treatmentGroup;

  useEffect(() => {
    setCaseChanged(isChanged() || caseIdentifier._id === 'new');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseIdentifier]);

  useEffect(() => {
    if (!isSelected) {
      setCaseChanged(false);
      setCaseIdentifier({
        _id,
        patientId,
        blockId,
        treatmentGroup,
        recuts
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isSelected)
    return (
      <Card variant="outlined">
        <React.Fragment>
          <CardContent
            sx={{
              padding: 1
            }}
          >
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '2px solid #CCCCCC',
                    borderRadius: '5px'
                  }
                }
              }}
              fullWidth
              autoFocus
              size="small"
              margin="dense"
              type="text"
              variant="outlined"
              id="patientId"
              name="patientId"
              placeholder="Enter Patient Id"
              value={caseIdentifier.patientId}
              onChange={handleChangeValue}
              onBlur={handleChangeValue}
              error={!!errors.patientId}
            />
            {errors.patientId && (
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'red',
                  textAlign: 'left',
                  marginLeft: '4px'
                }}
              >
                {errors.patientId}
              </Typography>
            )}
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '2px solid #CCCCCC',
                    borderRadius: '5px'
                  }
                }
              }}
              fullWidth
              size="small"
              margin="dense"
              type="text"
              variant="outlined"
              id="blockId"
              name="blockId"
              placeholder="Enter Block Id"
              value={caseIdentifier.blockId}
              onChange={handleChangeValue}
            />
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '2px solid #CCCCCC',
                    borderRadius: '5px'
                  }
                }
              }}
              fullWidth
              size="small"
              margin="dense"
              type="text"
              variant="outlined"
              id="treatmentGroup"
              name="treatmentGroup"
              placeholder="Enter Treatment Group"
              value={caseIdentifier.treatmentGroup}
              onChange={handleChangeValue}
            />
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '2px solid #CCCCCC',
                    borderRadius: '5px'
                  }
                }
              }}
              fullWidth
              size="small"
              margin="dense"
              type="text"
              variant="outlined"
              id="recuts"
              name="recuts"
              placeholder="Enter ReCuts"
              value={caseIdentifier.recuts}
              onChange={handleChangeValue}
            />
          </CardContent>
          <CardActions
            sx={{ display: 'flex', gap: '5px', justifyContent: 'flex-end' }}
          >
            <Button
              variant="contained"
              color="gray"
              component="label"
              disableElevation
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              component="label"
              disableElevation
              disabled={!isChanged() || !caseIdentifier.patientId || isError}
              onClick={() => handleUpdate(index, caseIdentifier)}
            >
              {caseIdentifier._id === 'new' ? 'Add' : 'Update'}
            </Button>
          </CardActions>
        </React.Fragment>
      </Card>
    );

  return (
    <ListItem
      sx={{
        padding: '0px 0px 0px 0px'
      }}
    >
      <Chip
        onClick={() => handleSelected(index)}
        color="primary"
        variant="outlined"
        sx={{
          ...(isError && { border: '2px solid red' })
        }}
        label={
          <Typography
            sx={{
              display: 'inline'
            }}
            variant="subtitle2"
            color="primary"
          >
            {`Patient Id: ${patientId}, Block Id: ${blockId}, Treatment Group: ${treatmentGroup}, ReCuts: ${recuts}`}
          </Typography>
        }
        onDelete={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleDelete(index);
        }}
      />
    </ListItem>
  );
};

export { CaseIdentifierItem };
