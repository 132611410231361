import _ from 'lodash';
import { useCallback } from 'react';

import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  EUserRole,
  IUserTableFilters,
  IUserTableFilterValue
} from 'types/user';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useBoolean } from 'v2/hooks/use-boolean';
import UserInviteForm from './UserInviteForm';
import { USER_ROLES_OPTIONS, USER_STATUS_OPTIONS } from 'v2/utils/user';
import { IOrganization } from 'types/organization';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAuth } from 'hooks/useAuth';

// ----------------------------------------------------------------------

type FilterKey = keyof IUserTableFilters;
type Props = {
  shows: FilterKey[];
  filters: IUserTableFilters;
  onFilters: (name: string, value: IUserTableFilterValue) => void;
  dateError?: boolean;
  //
  organizations: IOrganization[];
  onInviteSuccess?: VoidFunction;
};

export default function UserTableToolbar({
  shows,
  filters,
  onFilters,
  organizations,
  onInviteSuccess,
  dateError
}: Props) {
  const { user } = useAuth();

  const openInvite = useBoolean();

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterOrganization = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const values =
        typeof event.target.value === 'string'
          ? event.target.value.split(',')
          : event.target.value;
      onFilters(
        'organizations',
        values.map((value) => Number(value))
      );
    },
    [onFilters]
  );

  const handleFilterRole = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'role',
        typeof event.target.value === 'string'
          ? event.target.value.split(',')
          : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterStatus = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'status',
        typeof event.target.value === 'string'
          ? event.target.value.split(',')[0]
          : event.target.value[0]
      );
    },
    [onFilters]
  );

  const handleFilterDate = useCallback(
    (key: 'start_date' | 'end_date', newValue: Date | null) => {
      onFilters(key, newValue);
    },
    [onFilters]
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row'
        }}
      >
        {shows.includes('organizations') &&
          user.role === EUserRole.SUPER_ADMIN && (
            <FormControl
              sx={{
                flexShrink: 0,
                width: { xs: 1, md: 200 }
              }}
            >
              <InputLabel>Organizations</InputLabel>
              <Select
                multiple
                value={
                  filters.organizations?.map(
                    (item) => item
                  ) as unknown as string[]
                }
                onChange={handleFilterOrganization}
                input={<OutlinedInput label="Organization" />}
                renderValue={(selected) =>
                  selected
                    .map(
                      (value) =>
                        organizations.find((item) => item._id === Number(value))
                          ?.name
                    )
                    .join(', ')
                }
                MenuProps={{
                  PaperProps: {
                    sx: { maxHeight: 240 }
                  }
                }}
              >
                {organizations.map((option) => (
                  <MenuItem
                    key={`organization-${option._id}`}
                    value={option._id}
                  >
                    <Checkbox
                      disableRipple
                      size="small"
                      checked={filters.organizations?.includes(option._id)}
                    />
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        {shows.includes('role') && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 }
            }}
          >
            <InputLabel>Role</InputLabel>
            <Select
              multiple
              value={filters.role}
              onChange={handleFilterRole}
              input={<OutlinedInput label="Role" />}
              renderValue={(selected) =>
                selected.map((value) => _.capitalize(value)).join(', ')
              }
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 }
                }
              }}
            >
              {USER_ROLES_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.role?.includes(option.value)}
                  />
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {shows.includes('status') && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 }
            }}
          >
            <InputLabel>Status</InputLabel>
            <Select
              value={filters.status ? [filters.status] : []}
              onChange={handleFilterStatus}
              input={<OutlinedInput label="Status" />}
              renderValue={(selected) =>
                selected.map((value) => _.capitalize(value)).join(', ')
              }
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 }
                }
              }}
            >
              {USER_STATUS_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {shows.includes('start_date') && shows.includes('end_date') && (
          <>
            <DatePicker
              label="Start date"
              value={filters.start_date}
              onChange={(newValue: Date | null) =>
                handleFilterDate('start_date', newValue)
              }
              slotProps={{ textField: { fullWidth: true } }}
              sx={{
                maxWidth: { md: 180 }
              }}
            />
            <DatePicker
              label="End date"
              value={filters.end_date}
              onChange={(newValue: Date | null) =>
                handleFilterDate('end_date', newValue)
              }
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: dateError
                }
              }}
              sx={{
                maxWidth: { md: 180 }
              }}
            />
          </>
        )}

        {shows.includes('name') && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ width: 1 }}
          >
            <TextField
              fullWidth
              value={filters.name}
              onChange={handleFilterName}
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                )
              }}
            />
            {onInviteSuccess && (
              <Button
                variant="contained"
                sx={{
                  minWidth: 140,
                  height: 56
                }}
                color="primary"
                onClick={openInvite.onTrue}
              >
                <AddIcon /> Invite User
              </Button>
            )}
          </Stack>
        )}
      </Stack>
      {onInviteSuccess && (
        <UserInviteForm
          open={openInvite.value}
          onClose={() => openInvite.onFalse()}
          organizations={organizations || []}
          onCallback={() => {
            onInviteSuccess?.();
          }}
        />
      )}
    </>
  );
}
