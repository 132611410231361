import { Close } from '@mui/icons-material';
import {
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Box,
  Tabs,
  Tab,
  Grid,
  useTheme,
  List,
  Divider,
  Avatar
} from '@mui/material';
import { Fragment, useEffect, useMemo, useState } from 'react';
import RightAppDrawer from '../../../../components/Drawer/RightAppDrawer';
import TabPanel from '../../../../components/TabPanel/Tab';
import { FILE_ICON, FILE_TYPES } from '../../../../constants';
import { formatDate } from '../../../../utils/appHelpers';
import { getSize } from '../../../../utils/fileUtils';
import {
  useGetProjectActivityLogsMutation,
  useGetProjectDetailsMutation
} from '../../../../services/projects';
import { useGetSlideActivityLogsMutation } from '../../../../services/slides';
import ActivityLog from '../../../../components/ActivityLog';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';

const ExplorerDetails = ({ open }) => {
  let properties = [
    { title: 'Name', field: 'name' },
    { title: 'Description', field: 'description' },
    { title: 'Size', field: 'size' },
    { title: 'Modified On', field: 'updatedAt' },
    { title: 'Created By', field: 'createdBy' }
  ];

  const { state, dispatch } = useSlideboxContext();
  const [details, setDetails] = useState(null);
  const [getProjectActivityLogs, {}] = useGetProjectActivityLogsMutation();
  const [getSlideActivityLogs, {}] = useGetSlideActivityLogsMutation();
  const [getProjectDetails] = useGetProjectDetailsMutation();

  const [activities, setActivities] = useState([]);

  const theme = useTheme();

  const handleOpen = async (id, nodeType) => {
    const { data } = await getProjectDetails(id).unwrap();
    if (data) {
      setDetails({
        ...data,
        size: getSize(data.size),
        updatedAt: formatDate(data.updatedAt, 'MMM DD, YYYY LT')
      });
      // if (nodeType === FILE_TYPES.FILE) {
      //   getSlideActivityLogs(id)
      //     .unwrap()
      //     .then((res) => setActivities(res.data));
      // } else {
      //   getProjectActivityLogs(id)
      //     .unwrap()
      //     .then((res) => setActivities(res.data));
      // }
    }
  };

  useEffect(() => {
    if (open && state.selectedRows[0]) {
      handleOpen(state.selectedRows[0]._id, state.selectedRows[0].type);
    }
  }, [open]);

  const IconComponent = useMemo(() => {
    return (details && FILE_ICON[details.type]) || null;
  }, [details]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setDetails(null);
    dispatch({ type: 'SET_STATE', key: 'openDetail', value: false });
  };
  const Header = ({ headerText, IconComponent }) => {
    return (
      <ListItem sx={{ position: 'relative', py: 2 }}>
        {IconComponent && <IconComponent size={{ fontSize: '20px' }} />}
        <ListItemText sx={{ ml: 1 }}>
          <Typography variant="h5">{headerText}</Typography>
        </ListItemText>
        <IconButton onClick={handleClose}>
          <Close
            sx={{
              position: 'absolute',
              top: '50%',
              right: '4px',
              transform: 'translateY(-50%)'
            }}
          />
        </IconButton>
      </ListItem>
    );
  };

  const getFieldValue = (details, title, field) => {
    switch (title) {
      case 'Created By':
        return `${details.createdBy.firstName} ${details.createdBy.lastName}`;
      default:
        return details[field] || '';
    }
  };

  return (
    <RightAppDrawer
      PaperProps={{
        sx: {
          width: '40%',
          [theme.breakpoints.down('md')]: {
            width: '50%'
          },
          [theme.breakpoints.down('sm')]: {
            width: '100%'
          }
        }
      }}
      sx={{ zIndex: '1204' }}
      open={open}
      headerSlot={
        <Header
          headerText={details ? details.name : 'No data'}
          IconComponent={IconComponent}
        />
      }
    >
      <Box sx={{ position: 'relative', overflowY: 'auto', pb: 2 }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            zIndex: 1
          }}
        >
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab label="Details" id={`folder-details-tab`} />
            {/* <Tab label="Activities" id={`folder-activity-tab`} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box pb={3} sx={{ textAlign: 'center' }}>
            {IconComponent && <IconComponent sx={{ fontSize: '150px' }} />}
          </Box>
          <Grid container rowSpacing={1}>
            {details &&
              properties.map(({ title, field }) => (
                <Fragment key={field}>
                  <Grid item xs={6}>
                    <Box>{title}</Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 'bold'
                      }}
                    >
                      {getFieldValue(details, title, field)}
                    </Box>
                  </Grid>
                </Fragment>
              ))}
            {details && details.type === FILE_TYPES.CASE && (
              <>
                <Fragment>
                  <Grid item xs={6}>
                    <Box>Shared With</Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 'bold'
                      }}
                    >
                      {
                        details && details.sharedEntities &&
                        details.sharedEntities.filter((x) => !x.isCollaborated).length
                      }
                    </Box>
                  </Grid>
                  {details && details.sharedEntities &&
                    !!details.sharedEntities.filter((x) => !x.isCollaborated).length &&
                    details.sharedEntities
                      .filter((x) => !x.isCollaborated)
                      .map((s) => (
                        <Grid item xs={12} key={s._id} mt={1}>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              sx={{
                                marginRight: '6px',
                                textTransform: 'uppercase',
                                width: '30px',
                                height: '30px'
                              }}
                            >
                              {s.sharedWith.charAt(0)}
                            </Avatar>
                            {s.sharedWith}
                          </Box>
                        </Grid>
                      ))}
                </Fragment>
                <Fragment>
                  <Grid item xs={6}>
                    <Box>Collaborated With</Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 'bold'
                      }}
                    >
                      {
                        details && details.sharedEntities &&
                        details.sharedEntities.filter((x) => x.isCollaborated).length
                      }
                    </Box>
                  </Grid>
                  {details && details.sharedEntities &&
                    !!details.sharedEntities.filter((x) => x.isCollaborated).length &&
                    details.sharedEntities
                      .filter((x) => x.isCollaborated)
                      .map((s) => (
                        <Grid item xs={12} key={s._id} mt={1}>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              sx={{
                                marginRight: '6px',
                                textTransform: 'uppercase',
                                width: '30px',
                                height: '30px'
                              }}
                            >
                              {s.sharedWith.charAt(0)}
                            </Avatar>
                            {s.sharedWith}
                          </Box>
                        </Grid>
                      ))}
                </Fragment>
              </>
            )}
          </Grid>
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          <List sx={{ width: '100%' }} px={0}>
            {activities &&
              activities.map((log, index) => (
                <Fragment key={index}>
                  <ActivityLog log={log} />
                  <Divider />
                </Fragment>
              ))}
          </List>
        </TabPanel> */}
      </Box>
    </RightAppDrawer>
  );
};
export default ExplorerDetails;
