import { api } from './api';

export const analysisApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAnalysis: build.mutation({
      query: (body) => ({ url: '/analysis', method: 'POST', body }),
      transformResponse: (response) => response.data
    })
  })
});

export const { useGetAnalysisMutation } = analysisApi;
