import React, { useEffect, useState } from 'react';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { useSearchParams, Navigate } from 'react-router-dom';
import { FormContainer } from 'react-hook-form-mui';
import { useSnackbar } from 'notistack';
import { Stack, Typography, Link as MuiLink } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PasswordInput } from '../../components/Shared/passwordInput';
import AuthLayout from '../../components/Layout/AuthLayout';
import { useRegisterMutation } from '../../services/auth';
import { useGetDataFromRequestTokenMutation } from '../../services/userRequest';
import { CustomInput } from '../../components/Shared/Input';
import { validationSchema } from '../../components/Shared/validationSchema';
import { REQUEST_TYPES } from '../../constants';

const Register = () => {
  const defaultValue = {
    firstName: '',
    lastName: '',
    password: '',
    repeatPassword: '',
    role: ''
  };
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [tokenAvailable, setTokenAvailable] = useState(true);
  const [registerUser, { isLoading }] = useRegisterMutation();
  const [getDataFromRequestToken] = useGetDataFromRequestTokenMutation();
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldFocusError: true
  });
  let token = searchParams.get('token');

  useEffect(() => {
    const handleInvitationToken = async (token) => {
      try {
        const response = await getDataFromRequestToken({
          token,
          type: REQUEST_TYPES.Invite
        }).unwrap();
        const { data } = response;
        if (data) {
          reset({ ...defaultValue, ...data });
        } else {
          setTokenAvailable(false);
        }
      } catch (err) {
        console.log(err);
        setTokenAvailable(false);
      }
    };

    if (token) {
      handleInvitationToken(token);
    }
  }, []);

  if (!token || !tokenAvailable) {
    enqueueSnackbar('Access Error !', {
      variant: 'error'
    });
    return <Navigate to="/login" />;
  }

  const handleRegisterUser = async (form) => {
    try {
      Object.keys(form).map(
        (k) => (form[k] = typeof form[k] == 'string' ? form[k].trim() : form[k])
      );
      const payload = { ...form };
      if (token) payload.token = token;
      const response = await registerUser(payload).unwrap();
      const { data } = response;
      if (!data) throw new Error('Something went wrong');
      return navigate({
        pathname: '/login'
      });
    } catch (err) {
      enqueueSnackbar('There is an error', { variant: 'error' });
    }
  };

  return (
    <AuthLayout>
      <FormContainer
        onSuccess={handleSubmit(handleRegisterUser)}
        defaultValue={defaultValue}
      >
        <Stack direction="column" gap={2}>
          <CustomInput
            placeholder="First Name"
            required={true}
            variant="outlined"
            fullWidth={true}
            size="small"
            type="text"
            {...register('firstName', { required: 'This field is required' })}
            error={!!errors.firstName}
            error-message={errors.firstName?.message}
          />
          <CustomInput
            {...register('lastName')}
            placeholder="Last Name"
            required={true}
            variant="outlined"
            fullWidth={true}
            size="small"
            type="text"
            {...register('lastName', { required: 'This field is required' })}
            error={!!errors.lastName}
            error-message={errors.lastName?.message}
          />
          <CustomInput
            {...register('email')}
            placeholder="Email"
            required={true}
            variant="outlined"
            fullWidth={true}
            size="small"
            type="email"
            inputProps={{ readOnly: true }}
          />
          <PasswordInput
            {...register('password', {
              required: 'This field is required',
            })}
            placeholder="Password"
            required
            fullWidth={true}
            type="password"
            error={!!errors.password}
            error-message={errors.password?.message}
          />
          <PasswordInput
            {...register('repeatPassword', {
              required: 'This field is required',
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();
                  return password === value || 'Passwords should match!';
                }
              }
            })}
            type="password"
            placeholder="Confirm Password"
            required
            fullWidth={true}
            error={!!errors.repeatPassword}
            error-message={errors.repeatPassword?.message}
          />
          <Typography
            sx={{ color: '#A69999', fontSize: '16px', fontWeight: '600' }}
          >
            By clicking the “Sign up” button, you agree to the Targeted
            Bioscience <MuiLink underline="none">Terms of service</MuiLink> and
            the <MuiLink underline="none">Privacy Policy</MuiLink>.
          </Typography>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            value="submit"
            sx={{ height: '56px', borderRadius: '5px', boxShadow: 'none' }}
          >
            <span style={{ fontSize: '20px' }}>SIGN UP</span>
          </LoadingButton>
          <Typography
            sx={{ fontSize: '20px', fontWeight: '600', textAlign: 'center' }}
          >
            Already has an account?{' '}
            <Link to="/login" style={{ textDecoration: 'none' }}>
              Login
            </Link>
          </Typography>
        </Stack>
      </FormContainer>
    </AuthLayout>
  );
};

export default Register;
