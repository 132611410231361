import { configureStore } from "@reduxjs/toolkit";
import { api } from "../services/api";
import counterReducer from "./counter/counterSlice";
import authSlice from "./auth/authSlice";
import slideboxSlice from "./slidebox/slideboxSlice";
import appSlice from "./app/appSlice";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    app: appSlice,
    counter: counterReducer,
    auth: authSlice,
    slidebox: slideboxSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware)
});
