import React from "react";
import Paper from "@mui/material/Paper";

const DescSec = ({ children, sx }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        border: (theme) => `1px solid ${theme.palette.divider}`,
        flexWrap: "wrap",
        mb: 1,
        ...sx
      }}
    >
      {children}
    </Paper>
  );
};

export default DescSec;
