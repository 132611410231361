/**
 * @description the recogito input widget,
 * @param annotation - object
 * @param id - string
 * @param name - string
 * @param label - string
 * @param placeholder - string
 * @param autofocus - boolean
 * @param disabled - boolean
 * @param onEnterSubmit - boolean
 * @param required - boolean
 * @returns {HTMLDivElement}
 * @constructor
 */
export const AnnoInputWidget = ({
  annotation,
  id,
  name,
  label,
  placeholder,
  autofocus,
  disabled,
  onEnterSubmit,
  required
}) => {
  const divElement = document.createElement('div');
  divElement.classList.add('input-widget');
  divElement.classList.add('widget-wrapper');
  divElement.classList.add(`anno-${name}-widget`);

  const inputElement = document.createElement('input');
  inputElement.classList.add(`annotation-${name}-input`);
  inputElement.classList.add('widget-input');

  inputElement.setAttribute('type', 'text');
  inputElement.setAttribute('placeholder', placeholder || name);
  autofocus && inputElement.setAttribute('autofocus', '');
  required && inputElement.setAttribute('required', '');
  inputElement.value = localStorage.getItem(id) || annotation.underlying?.[name] || '';
  inputElement.disabled = disabled || false;

  inputElement.addEventListener('keyup', (event) => {
    localStorage.setItem(id, event.currentTarget.value);
  });

  const okButton = document.querySelector('.r6o-btn:last-child');
  if (okButton && onEnterSubmit) {
    const clickOkButton = () => {
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      });
      okButton.dispatchEvent(clickEvent);
    };
    inputElement.addEventListener('keydown', (event) => {
      if (event.key === 'Enter' && !okButton.disabled) {
        // Handle the "Enter" key press event here.
        clickOkButton();
      }
    });
  }

  if (label) {
    const labelElement = document.createElement('label');
    labelElement.setAttribute('for', `annotation-${name}-input`);
    labelElement.innerHTML = label;
    divElement.appendChild(labelElement);
  }

  divElement.appendChild(inputElement);

  return divElement;
};
