import EditableShape from '@recogito/annotorious/src/tools/EditableShape';
import {
  drawEmbeddedSVG,
  svgFragmentToShape,
  toSVGTarget
} from '@recogito/annotorious/src/selectors/EmbeddedSVG';
import { SVG_NAMESPACE } from '@recogito/annotorious/src/util/SVG';
import {
  format,
  setFormatterElSize
} from '@recogito/annotorious/src/util/Formatting';
import { getRectangleSize, setRectangleSize } from './Rectangle';
import RectangleMask from './RectangleMask';

/**
 * An editable rectangle shape.
 */
export default class EditableRectangle extends EditableShape {
  constructor(annotation, g, config, env) {
    super(annotation, g, config, env);

    this.svg.addEventListener('mousemove', this.onMouseMove);
    this.svg.addEventListener('mouseup', this.onMouseUp);

    // 'g' for the editable rectangle compound shape
    this.containerGroup = document.createElementNS(SVG_NAMESPACE, 'g');

    this.rectangle = drawEmbeddedSVG(annotation);
    this.rectangle.querySelector('.a9s-inner').style.stroke = annotation.underlying.stroke

    this.rectangle
      .querySelector('.a9s-inner')
      .addEventListener('mousedown', this.onGrab(this.rectangle));

    this.mask = new RectangleMask(env.image, this.rectangle);

    this.containerGroup.appendChild(this.mask.element);

    // The 'element' = rectangle + handles
    this.elementGroup = document.createElementNS(SVG_NAMESPACE, 'g');
    this.elementGroup.setAttribute('class', 'a9s-annotation editable selected');
    this.elementGroup.appendChild(this.rectangle);

    const { x, y, width, height } = getRectangleSize(this.rectangle);
    this.handles = [
      [x, y],
      [x + width, y],
      [x + width, y + height],
      [x, y + height]
    ].map((t) => {
      const [x, y] = t;
      const handle = this.drawHandle(x, y);

      handle.addEventListener('mousedown', this.onGrab(handle));
      this.elementGroup.appendChild(handle);

      return handle;
    });

    this.containerGroup.appendChild(this.elementGroup);
    g.appendChild(this.containerGroup);

    format(this.rectangle, annotation, config.formatter);

    // The grabbed element (handle or entire group), if any
    this.grabbedElem = null;

    // Mouse xy offset inside the shape, if mouse pressed
    this.grabbedAt = null;
  }

  setSize = (x, y, width, height) => {
    setRectangleSize(this.rectangle, x, y, width, height);
    this.mask.redraw();
    setFormatterElSize(
      this.elementGroup,
      x + width / 2,
      y + height / 2,
      width / 2,
      height / 2
    );

    const [topleft, topright, bottomright, bottomleft] = this.handles;
    this.setHandleXY(topleft, x, y);
    this.setHandleXY(topright, x + width, y);
    this.setHandleXY(bottomright, x + width, y + height);
    this.setHandleXY(bottomleft, x, y + height);
  };

  stretchCorners = (draggedHandleIdx, anchorHandle, leftHandle, mousePos) => {
    const anchor = this.getHandleXY(anchorHandle);
    // const anchorLeft = this.getHandleXY(leftHandle);

    const mouseX = mousePos.x;
    const mouseY = mousePos.y;

    const x = Math.min(anchor.x, mouseX);
    const y = Math.min(anchor.y, mouseY);
    const width = Math.abs(anchor.x - mouseX);
    const height = Math.abs(anchor.y - mouseY);

    this.setSize(x, y, width, height);
    this.mask.redraw();
  };

  onGrab = (grabbedElem) => (evt) => {
    this.grabbedElem = grabbedElem;

    const pos = this.getSVGPoint(evt);
    const { x, y, width, height } = getRectangleSize(this.rectangle);

    this.grabbedAt = { x: pos.x - x, y: pos.y - y };
  };

  onMouseMove = (evt) => {
    const constrain = (coord, max) =>
      coord < 0 ? 0 : coord > max ? max : coord;

    if (this.grabbedElem) {
      const pos = this.getSVGPoint(evt);

      if (this.grabbedElem === this.rectangle) {
        const { width, height } = getRectangleSize(this.rectangle);

        const { naturalWidth, naturalHeight } = this.env.image;

        const x = constrain(pos.x - this.grabbedAt.x, naturalWidth - width);
        const y = constrain(pos.y - this.grabbedAt.y, naturalHeight - height);

        this.setSize(x, y, width, height);
        this.emit('update', toSVGTarget(this.rectangle, this.env.image));
      } else {
        // Mouse position replaces one of the corner coords, depending
        // on which handle is the grabbed element
        const handleIdx = this.handles.indexOf(this.grabbedElem);
        const oppositeHandle =
          handleIdx < 2
            ? this.handles[handleIdx + 2]
            : this.handles[handleIdx - 2];
        const leftHandle = this.handles[(handleIdx + 3) % 4];

        this.stretchCorners(handleIdx, oppositeHandle, leftHandle, pos);
        this.emit('update', toSVGTarget(this.rectangle, this.env.image));
      }
    }
  };

  onMouseUp = () => {
    this.grabbedElem = null;
    this.grabbedAt = null;
  };

  onScaleChanged = (scale) => this.handles.map(this.scaleHandle);

  get element() {
    return this.elementGroup;
  }

  updateState = (annotation) => {
    const shape = svgFragmentToShape(annotation);

    const x = parseFloat(shape.getAttribute('x'));
    const y = parseFloat(shape.getAttribute('y'));
    const width = parseFloat(shape.getAttribute('width'));
    const height = parseFloat(shape.getAttribute('height'));

    this.setSize(x, y, width, height);
  };

  destroy() {
    this.containerGroup.parentNode.removeChild(this.containerGroup);
    super.destroy();
  }
}
