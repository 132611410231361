import { useCallback, useEffect } from 'react';
import { useAuth } from './useAuth';
import { useDispatch } from 'react-redux';
import { api } from '../services/api';

const slideCommentEvents = {
  slideCommentCreated: 'slide-comment-created',
  slideCommentUpdated: 'slide-comment-updated',
  slideCommentDeleted: 'slide-comment-deleted'
};

const useSlideCommentSocket = (slideId) => {
  const {
    user: { _id: uid }
  } = useAuth();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   socket.on(slideCommentEvents.slideCommentCreated, (comment) => {
  //     if (uid !== comment.createdBy?._id) {
  //       dispatch(
  //         api.util.updateQueryData('getSlideComments', slideId, (data = []) => {
  //           if (!data.some((item) => item._id === comment._id)) {
  //             data.push(comment);
  //           }
  //         })
  //       );
  //     }
  //   });

  //   socket.on(slideCommentEvents.slideCommentUpdated, (comment) => {
  //     if (uid !== comment.createdBy?._id) {
  //       dispatch(
  //         api.util.updateQueryData('getSlideComments', slideId, (data = []) => {
  //           return data.map((item) => {
  //             return item._id === comment._id ? comment : item;
  //           });
  //         })
  //       );
  //     }
  //   });

  //   socket.on(slideCommentEvents.slideCommentDeleted, (comment) => {
  //     if (uid !== comment.createdBy?._id) {
  //       dispatch(
  //         api.util.updateQueryData('getSlideComments', slideId, (data = []) => {
  //           return data.filter((item) => item._id !== comment._id);
  //         })
  //       );
  //     }
  //   });
  // }, []);

  const emitSlideCommentCreated = useCallback((comment) => {
    // socket.emit(slideCommentEvents.slideCommentCreated, comment);
  }, []);

  const emitSlideCommentUpdated = useCallback((comment) => {
    // socket.emit(slideCommentEvents.slideCommentUpdated, comment);
  }, []);

  const emitSlideCommentDeleted = useCallback((comment) => {
    // socket.emit(slideCommentEvents.slideCommentDeleted, comment);
  }, []);

  return {
    emitSlideCommentCreated,
    emitSlideCommentUpdated,
    emitSlideCommentDeleted
  };
};

export default useSlideCommentSocket;
