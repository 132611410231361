export type IOrganizationTableFilterValue =
  | string
  | string[]
  | number[]
  | Date
  | null;

export type IOrganizationTableFilters = {
  name: string;
  status?: EOrganizationStatus;
};

export type IOrganization = {
  _id: number;
  name: string;
  description: string;
  email: string;
  address: string;
  phone: string;
  isActive: boolean;
  collaboratorLimit: number;
};

export enum EOrganizationStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}
