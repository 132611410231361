import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Stack
} from '@mui/material';
import { useGetMembersQuery } from '../../../services/user';
import { AutocompleteElement, useForm } from 'react-hook-form-mui';
import { useEditGroupMutation } from '../../../services/groups';
import {
  socketGroupEvents,
  useGroupsContext
} from '../../../contexts/GroupsContext';
import { useSnackbar } from '../../../hooks/useSnackbar';
import union from 'lodash/union';

function InviteMemberDialog({ group, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, data } = useGetMembersQuery();
  const [editGroup, { isLoading: isSubmitting }] = useEditGroupMutation();
  const { setSelectedGroup } = useGroupsContext();
  const { control, handleSubmit, resetField } = useForm({
    defaultValues: {
      members: []
    }
  });

  if (!group) return null;

  function getMembers() {
    if (!data) return [];
    const members = [];
    for (const item of data) {
      if (!group.members.some((user) => user._id === item._id)) {
        members.push({
          id: item._id,
          label: `${item.firstName} ${item.lastName}`.trim()
        });
      }
    }
    return members;
  }

  async function handleInviteMembers(formData) {
    if (isSubmitting) return;
    try {
      resetField('members', { defaultValue: [] });
      const response = await editGroup({
        id: group._id,
        members: union(group.members, formData.members)
      }).unwrap();
      onClose?.();
      enqueueSnackbar('Invite members successfully', { variant: 'success' });
      // TODO WS emit event update group
      // emitEvent(socketGroupEvents.roomUpdated, response.data);
      setSelectedGroup?.(response.data);
    } catch (e) {
      enqueueSnackbar(e.message || 'Something went wrong!', {
        variant: 'error'
      });
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h5">Invite Members</DialogTitle>
      <DialogContent>
        <form
          noValidate
          id="group-invite-form"
          onSubmit={handleSubmit(handleInviteMembers)}
          autoComplete="off"
        >
          <Stack spacing={1} minWidth={500}>
            <InputLabel required htmlFor="group-members">
              Members ({group.members?.length ?? 0})
            </InputLabel>
            <AutocompleteElement
              control={control}
              loading={isLoading}
              multiple
              matchId
              id="group-members"
              name="members"
              options={getMembers()}
              required
              autocompleteProps={{
                readOnly: isSubmitting
              }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="gray"
          disableElevation
        >
          Back
        </Button>
        <Button
          type="submit"
          form="group-invite-form"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InviteMemberDialog;
