import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { MAIN_DRAWER_WIDTH } from '../../theme';
import { useDrawerContext } from '../../contexts/DrawerContext';
import Divider from '@mui/material/Divider';

const subDrawerWidth = MAIN_DRAWER_WIDTH;

const openedMixin = (theme) => ({
  width: subDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open, left }) => ({
  width: subDrawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      left,
      transition: 'all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      left,
      transition: 'all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
    }
  })
}));

const mainDrawerCloseWidth = 110;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

export default function SubDrawer({ children, open }) {
  const { open: mainDrawerOpen, drawerWidth: mainDrawerWidth } =
    useDrawerContext();

  return (
    <Box>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        left={mainDrawerOpen ? mainDrawerWidth : mainDrawerCloseWidth}
      >
        <DrawerHeader />
        <Divider />
        {children}
      </Drawer>
    </Box>
  );
}
