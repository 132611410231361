import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const ErrorFileProcessDialog = ({open, handleOpen}) => {
    const  navigate = useNavigate()
    const handleClose = () => {
        handleOpen(false);
        navigate('/slidebox')
    }
    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" variant="error">
          FILE ERROR !
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            File cannot be processed. Please go back to Slidebox !
          </DialogContentText>
        </DialogContent>
        <DialogContent sx={{ textAlign: 'center' }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleClose}
          >
            Back to Slidebox
          </Button>
        </DialogContent>
      </Dialog>
    )
}

export default ErrorFileProcessDialog;