/* eslint-disable react-hooks/exhaustive-deps */
import OpenSeadragon from 'openseadragon';
import { useEffect, useRef, useCallback } from 'react';
import { copyAnnotationsToNewPosition, initSelectedAnno, moveAnnotationsToNewPosition } from 'utils/shape.helper';
import { throttle } from "lodash";

export const useCanvasEvent = (
    viewer,
    selectAnnotation,
    anno,
    mulSelectMode,
    handleSwitchMulSelect,
    setSelectAnnotation,
    isSelectedAnnotation,
    setIsSelectedAnnotation,
    currentAnnotation,
    setCurrentAnnotation,
    handleUpdateAnnotation,
    handleCreateAnnotation,
    // showAnnoDeleteAlert
) => {
    
    const mousePosition = useRef( { x: 0, y: 0 } );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleCanvasMove = useCallback( throttle( ( event ) => {
        mousePosition.current = { x: event.offsetX, y: event.offsetY };
    }, 500 ), [] );

    useEffect(() => {
        let mouseX = 0
        let mouseY = 0
        // const handleCanvasMove = (event) => {
        //     mouseX = event.offsetX
        //     mouseY = event.offsetY
        // }

        // const handleCanvasMove = ( event ) => {
        //     mousePosition.current = { x: event.offsetX, y: event.offsetY };
        // };
        const handleCanvasDrag = ({direction, position}) => {
            if (direction !== 0 && isSelectedAnnotation && !mulSelectMode && selectAnnotation.length) {
                const viewportPoint = viewer.viewport.pointFromPixel(position);
                const imagePoint = viewer.viewport.viewportToImageCoordinates(viewportPoint.x, viewportPoint.y);
                const allAnnotations = anno.getAnnotations();
                const currentSelectedAnno = selectAnnotation.find( v => v._id === currentAnnotation._id )
                if ( !currentSelectedAnno ) return;

                const movedAnnotations = moveAnnotationsToNewPosition(currentSelectedAnno, selectAnnotation, imagePoint.x, imagePoint.y, true)
                const res = Array.from(new Set(allAnnotations.concat(movedAnnotations).map(item => item._id)))
                    .map(_id => {
                        const foundA = allAnnotations.find(item => item._id === _id);
                        const foundB = movedAnnotations.find(item => item._id === _id);
                        return foundB ? { ...foundB, id: foundB._id } : { ...foundA, id: foundA._id };
                    });
                anno.setAnnotations([...res]);
                setSelectAnnotation(movedAnnotations)
                initSelectedAnno(movedAnnotations)
            }

        }
        const handleCanvasPan = (event) => {
            if (isSelectedAnnotation) {
                viewer.panHorizontal = false;
                viewer.panVertical = false;
            }
        }
        const handleCanvasDragEnd = ( event ) => {
            if ( isSelectedAnnotation ) {
                viewer.panHorizontal = true;
                viewer.panVertical = true;
                const updatePositionAnnotations = anno.getAnnotations().filter( anno => anno?.update && !anno?.parentCopy );
                if ( selectAnnotation.length && updatePositionAnnotations.length ) {
                    handleUpdateAnnotation( updatePositionAnnotations );
                    anno.setAnnotations( anno.getAnnotations().map( v => ( { ...v, update: false } ) ) );
                }
                setIsSelectedAnnotation( false );
            }
        }
        const handleEventKeyDown = (event) => {
            //event delete action
            // if (event.key === "Backspace" || event.key === "Delete") { //! This instruction is blocked when typing in the title input field
            //     selectAnnotation && showAnnoDeleteAlert([selectAnnotation])
            // }
            if ( event.ctrlKey || event.metaKey ) {
                handleSwitchMulSelect( true );
                anno.selectAnnotation();
                if ( event.key === 'a' ) {
                    const allAnnotations = anno.getAnnotations();
                    setSelectAnnotation( allAnnotations );
                }
                // event ctrl + c || meta +c (macos)
                if ( event.key === 'c' ) {
                    setSelectAnnotation( selectAnnotation.map( v => ( { ...v, select: true } ) ) );
                }
                // event ctrl + v || meta +v (macos)
                if ( event.key === 'v' ) {
                    const allAnnotations = anno.getAnnotations();
                    const annoSelected = selectAnnotation.filter( v => v.select );
                    if ( !annoSelected.length ) return;
                    const pointer = new OpenSeadragon.Point( mouseX, mouseY );
                    const viewportPoint = viewer.viewport.pointFromPixel( pointer );
                    const imagePoint = viewer.viewport.viewportToImageCoordinates( viewportPoint.x, viewportPoint.y );
                    const movedAnnotations = copyAnnotationsToNewPosition( annoSelected, imagePoint.x, imagePoint.y );
                    anno.setAnnotations( [ ...movedAnnotations, ...allAnnotations, ] );
                    setSelectAnnotation( movedAnnotations );
                    movedAnnotations.length && setCurrentAnnotation( movedAnnotations[ 0 ] );
                    initSelectedAnno( movedAnnotations );
                    //case create annotation copy
                    const createAnnotationsCopy = movedAnnotations.map( anno => {
                        delete anno._id;
                        return anno;
                    } );
                    createAnnotationsCopy.length && handleCreateAnnotation( createAnnotationsCopy, movedAnnotations );
                }
            }
        }

        const handleEventKeyUp = (event) => {
            if (event.key === 'Control' || event.key === "Meta") {
                handleSwitchMulSelect(false)
            }
        }
        if (viewer) {
            const canvas = viewer.canvas;
            if(!canvas) return;
            canvas && canvas.addEventListener('mousemove', handleCanvasMove);
            viewer.addHandler('canvas-drag', handleCanvasDrag);
            viewer.addHandler('canvas-release', handleCanvasDragEnd);
            viewer.addHandler('pan', handleCanvasPan);

            document.addEventListener('keydown', handleEventKeyDown)
            document.addEventListener('keyup', handleEventKeyUp);
            return () => {
                viewer.removeHandler("canvas-drag", handleCanvasDrag)
                viewer.removeHandler("canvas-release", handleCanvasDragEnd)
                viewer.removeHandler("pan", handleCanvasPan)
                document.removeEventListener('keydown', handleEventKeyDown)
                document.removeEventListener('keyup', handleEventKeyUp)
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewer, selectAnnotation, anno, isSelectedAnnotation, mulSelectMode, currentAnnotation]);
};
