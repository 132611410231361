import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { useMemo } from 'react';

const LinearProgressWithLabel = (props) => {
  const percentage = useMemo(() => `${Math.round(props.value)}%`, [props.value])
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">
            {percentage === '100%' ? 'Completed' : percentage}
          </Typography>
        </Box>
      </Box>
    )
  }
export default LinearProgressWithLabel;  