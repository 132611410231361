export const firebaseConfig = {
  apiKey: 'AIzaSyCpM5sI7iuLfPIvyii_jdXdv5lSwjPiTlI',
  authDomain: 'sota-communication.firebaseapp.com',
  projectId: 'sota-communication',
  storageBucket: 'sota-communication.appspot.com',
  messagingSenderId: '332970263812',
  appId: '1:332970263812:web:9ca16e453c85bfb85a7c51'
};

export const firebasePublicKey =
  'BFcqJde15i_ODBSgSVy7XxswYJ-uRo3uJtoW0Ay-PLB8Sn7PhrkKMMN_K38qFnol6QvXbZnA_yRnqOPbeykmmOw';
